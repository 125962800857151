.join {
  position: relative;
  @media (min-width: 1600px) and (max-width: 1650px) and (max-height: 1085px) {
    margin-top: 235px;
    margin-left: -100px;
  }
  @media (max-width: 768px) {
    position: relative;
    background: url(../../../../assets/img/join.svg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 348.93px;
  }

  @media (max-width: 420px) {
    background: url(../../../../assets/img/join-mobile.png);
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 720px;
    height: auto;
    z-index: 1;
    margin-top: -30px;
    position: relative;
    padding-top: 80px;
    z-index: 111;
  }

  @media (max-width: 375px) {
    min-height: 638px;
  }

  .join__back {
    position: relative;
    height: 828px;
    width: 100%;
    z-index: 11;
    display: inline-block;
    z-index: 1;
    overflow: hidden;
    margin-top: -50px;

    @media (max-width: 1440px) {
      width: 1440px;
      background-size: cover;
      background-repeat: no-repeat;
      height: 692px;
    }

    @media (max-width: 768px) {
      display: none;
    }

    .join__back_photo {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 1000px;
      background: url(../../../../assets/img/join-back3.webp);
      background-size: cover;
      background-repeat: no-repeat;
      display: inline-block;
      z-index: -1;
      border-bottom-right-radius: 85px;
      border-bottom-left-radius: 85px;

      @media (max-width: 1440px) {
        height: 900px;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .join__back-k {
    position: absolute;
    left: 0;
    top: 0;
    background: url(../../../../assets/img/join-border-1920-1.svg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 878px;
    width: 100%;
    z-index: 111;
    display: inline-block;
    z-index: 1;
    overflow: hidden;
    margin-top: -50px;

    @media (max-width: 1440px) {
      background: url(../../../../assets/img/join-border-1440-1.svg);
      background-size: cover;
      background-repeat: no-repeat;
      height: 742px;
    }
  }

  .joind {
    height: 500px;

    .parallax-content {
      position: relative;
      width: 100%;
      height: calc(100vh - var(--hero-gap));
    }

    .hero {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 110%;
        object-fit: cover;
        z-index: 1;
        transform: translateZ(1px);
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 100%;
        transform-origin: 0 100%;
        transform: translateZ(8px);
        pointer-events: none;
        background-image: linear-gradient(
          to bottom,
          hsla(0, 0%, 0%, 0) 0%,
          hsla(0, 0%, 0%, 0.013) 8%,
          hsla(0, 0%, 0%, 0.049) 14.8%,
          hsla(0, 0%, 0%, 0.104) 20.8%,
          hsla(0, 0%, 0%, 0.175) 26%,
          hsla(0, 0%, 0%, 0.259) 30.8%,
          hsla(0, 0%, 0%, 0.352) 35.3%,
          hsla(0, 0%, 0%, 0.45) 39.8%,
          hsla(0, 0%, 0%, 0.55) 44.5%,
          hsla(0, 0%, 0%, 0.648) 49.5%,
          hsla(0, 0%, 0%, 0.741) 55.2%,
          hsla(0, 0%, 0%, 0.825) 61.7%,
          hsla(0, 0%, 0%, 0.896) 69.2%,
          hsla(0, 0%, 0%, 0.951) 77.9%,
          hsla(0, 0%, 0%, 0.987) 88.1%,
          hsl(0, 0%, 0%) 100%
        );
        z-index: 3;
      }
    }
  }

  .join__union {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 1470px;
    margin: 0 auto;
    height: 800px;
    z-index: 111;
    margin-left: 200px;

    @media (max-width: 1480px) {
      height: auto;
      justify-content: center;
      margin-left: 0;
      width: 1440px;
      height: 695px;
    }

    @media (max-width: 768px) {
      width: 100%;
      height: 348.93px;
    }

    @media (max-width: 420px) {
      flex-direction: column;
    }

    .join__block_1 {
      position: absolute;
      bottom: 21px;
      left: 50%;
      transform: translateX(-50%);
      width: 18.5%;
      height: 51px;
      background: #10101b;

      @media (max-width: 1440px) {
        width: 24.5%;
        bottom: 51px;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    .join__block_1::before {
      position: absolute;
      content: '';
      left: -49px;
      top: 6px;
      width: 50px;
      height: 80px;
      background: #10101b;
      transform: rotate(42deg);

      @media (max-width: 1440px) {
        left: -36px;
        top: 7px;
        width: 50px;
        height: 80px;
        background: #10101b;
        transform: rotate(44deg);
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    .join__block_1::after {
      position: absolute;
      content: '';
      right: -101px;
      top: 7px;
      width: 50px;
      height: 80px;
      background: #10101b;
      transform: rotate(316deg);

      @media (max-width: 1440px) {
        right: -36px;
        top: 7px;
        width: 50px;
        height: 80px;
        background: #10101b;
        transform: rotate(316deg);
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    .join__block_1_line {
      position: absolute;
      content: '';
      right: -67px;
      top: 0;
      width: 90px;
      height: 51px;
      background: #10101b;

      @media (max-width: 1440px) {
        right: -2px;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    .join__block_2 {
      position: absolute;
      content: '';
      left: -12px;
      top: 2px;
      width: 90px;
      height: 51px;
      background: #10101b;

      @media (max-width: 1440px) {
        left: -7px;
      }
    }

    .join__block_3 {
      position: absolute;
      content: '';
      left: -46px;
      bottom: 42px;
      width: 90px;
      height: 51px;
      background: #10101b;
      transform: rotate(45deg);

      @media (max-width: 768px) {
        display: none;
      }
    }

    .join__block_4 {
      position: absolute;
      content: '';
      right: -46px;
      bottom: 42px;
      width: 90px;
      height: 51px;
      background: #10101b;
      transform: rotate(-45deg);

      @media (max-width: 768px) {
        display: none;
      }
    }

    .join__photo_box {
      position: relative;
      width: 401px;
      height: 462px;
      margin-top: 94px;
      margin-right: 108px;
      margin-left: 30px;
      opacity: 0;

      @media (min-width: 1600px) and (max-width: 1650px) and (max-height: 1085px) {
        margin-top: 80px;
      }

      @media (max-width: 1440px) {
        height: 460px;
        margin-right: 165px;
        margin-left: -50px;
        margin-top: 0;
      }

      @media (max-width: 768px) {
        width: 232.54px;
        height: 238.47px;
        margin-right: 30px;
        margin-top: 0px;
        margin-left: 0px;
      }

      @media (max-width: 420px) {
        width: 170.54px;
        height: 212.47px;
        margin-right: 30px;
        margin-top: -10px;
        margin-left: -75px;
      }

      @media (max-width: 420px) {
        order: 2;
      }

      .join__photo {
        overflow: hidden;
        position: relative;
        z-index: 1;
        width: 405px;
        height: 465px;

        .join__photo_mask {
          position: relative;
          -webkit-mask-image: url(../../../../assets/img/join-mask-photo.svg);
          mask-image: url(../../../../assets/img/join-mask-photo.svg);
          -webkit-mask-repeat: round;
          mask-repeat: round;
          height: 455px;
          width: 385px;

          @media (max-width: 768px) {
            width: 253.54px;
            height: 215.47px;
          }

          @media (max-width: 420px) {
            width: 189.63px;
            height: 225.26px;
          }

          &::after {
            position: absolute;
            content: '';
            left: 0px;
            top: 1px;
            height: 454px;
            width: 385px;
            background: url(../../../../assets/img/join-mask-border.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: top;
            z-index: 11111;

            @media (max-width: 768px) {
              width: 252.54px;
              height: 214.47px;
            }

            @media (max-width: 420px) {
              width: 189.63px;
              height: 230.26px;
              left: 0px;
              top: 1px;
            }
          }

          img {
            width: 100%;
            height: 100%;
            transform: scale(1.3);

            @media (max-width: 420px) {
              object-fit: contain;
              transform: scale(1);
            }
          }

          .join__glare {
            position: absolute;
            content: '';
            left: -203px;
            bottom: -598px;
            width: 762px;
            height: 643px;
            background: url(../../../../assets/img/joinGlare.png);
            background-repeat: no-repeat;
            background-size: contain;
            z-index: 111111;
            animation: joinGlare 2.3s infinite;
            opacity: 0.3;
            -webkit-animation-delay: 4s;
            -moz-animation-delay: 4s;
            -o-animation-delay: 4s;
            animation-delay: 4s;
          }

          @keyframes joinGlare {
            0% {
              bottom: -598px;
            }

            100% {
              bottom: 735px;
            }
          }
        }

        @media (max-width: 1440px) {
          width: 385.39px;
          height: 454px;
          margin-right: 150px;
          margin-top: -44px;
          border-top-right-radius: 31px;
        }

        @media (max-width: 768px) {
          width: 236.54px;
          height: 222.47px;
          margin-right: 0px;
          margin-top: -10px;
          margin-left: 0px;
        }

        @media (max-width: 420px) {
          margin-top: 25px;
          margin-left: -15px;
          width: 200.54px;
          height: 295.47px;
        }

        .join__photo_animate {
          position: absolute;
          left: 51px;
          top: -21px;
          width: 379px;
          height: 446px;
          background: url(../../../../assets/img/join-photo-animate.svg);
          background-size: contain;
          background-repeat: no-repeat;
          overflow: hidden;
          z-index: -1;
          display: none;
        }

        .join__photo_animate1:before {
          content: '';
          width: 100%;
          height: 200%;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0.5;
          filter: blur;
          background: linear-gradient(
            to left,
            transparent 0%,
            #de52da78 20%,
            #a1047d54 30%,
            transparent 100%
          );
          transform: translate(-100%, -25%) rotate(10deg);
          animation: blick 5s infinite;
          display: none;
        }

        @keyframes blick {
          0% {
            transform: translate(-100%, -25%) rotate(10deg);
          }

          20% {
            transform: translate(100%, -25%) rotate(10deg);
          }

          100% {
            transform: translate(100%, -25%) rotate(10deg);
          }
        }
      }
    }

    .join__block {
      width: 455px;
      margin-left: 0px;
      margin-top: 40px;

      @media (max-width: 1440px) {
        margin-left: 0px;
        margin-top: -50px;
      }

      @media (max-width: 768px) {
        margin-top: 0;
      }

      @media (max-width: 420px) {
        margin-top: -50px;
        order: 1;
        width: 100%;
      }

      .interText {
        display: block;
        width: 414px;
      }

      .join__title {
        background: linear-gradient(98.71deg, #8875fb -0.58%, #fc64fc 44.41%, #fe9040 91.3%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        text-shadow: 0px 0px 8.15px rgba(136, 117, 251, 0.34);
        height: 174px;

        @media (max-width: 1440px) {
          margin-top: -60px;
          margin-left: -82px;
          height: 155px;
        }

        @media (max-width: 768px) {
          width: 280px;
          margin-top: -30px;
          margin-left: 0;
          height: 97px;
        }

        @media (max-width: 420px) {
          text-align: center;
          width: 343px;
          margin: 0 auto;
          margin-top: 325px;
          height: 115px;
        }
      }

      .join__description_box {
        margin-top: 20px;
        width: 326px;
        opacity: 0;
        transform: translate3d(0px, -20px, 0px);
        will-change: transform;

        @media (max-width: 1440px) {
          margin-left: -82px;
          height: 112px;
        }

        @media (max-width: 768px) {
          margin-left: 0;
          margin-top: 10px;
          width: 285px;
          height: auto;
        }

        @media (max-width: 420px) {
          margin: 0 auto;
          text-align: center;
          margin-top: 21px;
          margin-bottom: 10px;
        }
      }

      .join__block_box {
        .join__photo_mask {
          position: absolute;
          right: -80px;
          bottom: -65px;
          width: 238.12px;
          height: 288.82px;
          opacity: 0;
          -webkit-mask-image: url(../../../../assets/img/join-mask-small-photo.svg);
          mask-image: url(../../../../assets/img/join-mask-small-photo.svg);
          -webkit-mask-repeat: round;
          mask-repeat: round;

          @media (min-width: 1600px) and (max-width: 1650px) and (max-height: 1085px) {
            right: -5px;
            bottom: -50px;
          }

          &::after {
            position: absolute;
            content: '';
            left: 1px;
            top: 4px;
            width: 238.12px;
            height: 280.82px;
            background: url(../../../../assets/img/join-mask-small-border.svg);
            background-repeat: no-repeat;
            background-size: contain;

            @media (max-width: 1440px) {
              left: 1px;
              top: 2px;
              width: 181.12px;
              height: 218.82px;
            }

            @media (max-width: 768px) {
              left: 1px;
              top: 0px;
              width: 115.12px;
              height: 115.82px;
              background-size: cover;
            }

            @media (max-width: 420px) {
              width: 96.77px;
              height: 116.12px;
              left: 1px;
              top: 0px;
            }
          }

          img {
            width: 100%;
            height: 100%;
            transform: scale(1.3);

            @media (max-width: 420px) {
              object-fit: contain;
              transform: scale(1);
            }
          }

          @media (max-width: 1440px) {
            width: 182px;
            height: 219px;
            right: 90px;
            bottom: -35px;
          }

          @media (max-width: 768px) {
            width: 72.2px;
            width: 135.77px;
            height: 115.12px;
            right: 12px;
            bottom: -38px;
          }

          @media (max-width: 420px) {
            width: 98.77px;
            height: 116.12px;
            bottom: -290px;
            right: 35px;
          }

          .join__glare {
            position: absolute;
            content: '';
            position: absolute;
            content: '';
            left: -98px;
            bottom: -368px;
            width: 462px;
            height: 443px;
            background: url(../../../../assets/img/joinGlare.png);
            background-repeat: no-repeat;
            background-size: contain;
            z-index: 111111;
            animation: joinGlareSmall 2.3s infinite;
            opacity: 0.3;
            -webkit-animation-delay: 7s;
            -moz-animation-delay: 7s;
            -o-animation-delay: 7s;
            animation-delay: 7s;

            @media (max-width: 1440px) {
              left: -163px;
            }
          }

          @keyframes joinGlareSmall {
            0% {
              bottom: -368px;
            }

            100% {
              bottom: 335px;
            }
          }
        }
      }
    }
  }
}
