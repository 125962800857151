.benefits {
    position: relative;
    padding-top: 80px;

    @media (max-width: 1440px) {
        padding-top: 40px;
    }

    @media (max-width: 768px) {
        padding-top: 40px;
    }

    @media (max-width: 420px) {
        padding-top: 0;
    }

    .benefits__block {
        position: relative;
        background-color: #10101d;

        .benefits__title {
            position: relative;
            text-align: center;
            margin-top: 40px;
            margin-bottom: -120px;

            @media (max-width: 1440px) {
                margin-bottom: -160px;
            }

            @media (max-width: 768px) {
                margin-bottom: -135px;
            }

            @media (max-width: 420px) {
                margin-top: 37px;
                margin-bottom: -120px;
            }
        }

        .benefits__title_about {
            @media (max-width: 420px) {
                margin-top: 53px;
                margin-bottom: -130px;
            }
        }

        .benefits__video {
            position: relative;
            overflow: hidden;
            padding-top: 30px;
            mix-blend-mode: color-dodge;
            height: 550px;

            @media (max-width: 1440px) {
                height: 460px;
                    padding-top: 80px;
                    display: flex;
                    justify-content: center;
                    margin-bottom: 32px;
            }

            @media (max-width: 768px) {
                height: 295px;
                padding-top: 128px;
            }

            video{
                width: 100%;
                
                @media (max-width: 420px) {
                        margin-top: -155px;
                }
            }

            img {
                object-fit: cover;
                width: 100%;
                height: auto;

                @media (max-width: 420px) {
                    height: 210px;
                }
            }

            .video-background {
                position: absolute;
                top: 50%;
                left: 50%;
                min-width: 100%;
                min-height: 60%;
                height: 160px;
                transform: translate(-50%, -50%);
                mix-blend-mode: color-dodge;
            }

            #container {
                height: 500vh;
            }
        }

        .benefits__photo {
            margin: 0 auto;
            display: flex;
            justify-content: center;
            mix-blend-mode: lighten;
            margin-bottom: -22px;


            @media (max-width: 1440px) {
                width: 1340px;
                height: 754px;
                background-size: cover;
                object-fit: contain;
            }

            @media (max-width: 768px) {
                width: 100%;
                height: 515px;
            }

            @media (max-width: 420px) {
                display: none;
            }
        }

        .benefits__photo_mobile {
            margin-top: 80px;
            width: 375px;
            height: 286px;

            @media (min-width: 421px) {
                display: none;
            }
        }
    }

    .benefits__union_box {
        position: relative;
        background-repeat: no-repeat;
        background-size: contain;
        width: 1228px;
        height: 406px;
        margin: 0 auto;
        overflow: hidden;
        margin-top: -70px;

        @media (max-width: 1440px) {
            margin-top: -10px;
        }

        @media (max-width: 768px) {
            width: 100%;
            height: 304px;
        }

        @media (max-width: 420px) {
            flex-direction: column;
            height: auto;
            margin: 0;
            overflow: inherit;
        }

        .benefits__union {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 auto;
            padding: 0 100px;
            height: 400px;
            z-index: 1;
            margin-left: 42px;

            @media (max-width: 768px) {
                justify-content: space-around;
                padding: 0;
                margin-left: 0;
                margin: 0;
                width: 770px;
                height: 295px;
            }

            @media (max-width: 420px) {
                justify-content: space-between;
                flex-direction: column;
                width: 100%;
                height: auto;
                margin-top: -130px;
            }

            .benefits__photo_mask {
                position: absolute;
                left: 0;
                top: 0;
                -webkit-mask-image: url(../../../../assets/img/benefits-mask-photo-1440.svg);
                mask-image: url(../../../../assets/img/benefits-mask-photo-1440.svg);
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
                height: 395px;
                width: 1167px;

                @media (max-width: 768px) {
                    -webkit-mask-image: url(../../../../assets/img/benefits-mask-photo-768.svg);
                    mask-image: url(../../../../assets/img/benefits-mask-photo-768.svg);
                    -webkit-mask-repeat: no-repeat;
                    mask-repeat: no-repeat;
                    height: 300px;
                    width: 770px;
                }

                @media (max-width: 420px) {
                    display: none;
                }

                img {
                    width: 100%;
                    height: 100%;

                    @media (max-width: 420px) {
                        display: none;
                    }
                }

                video {
                    width: 100%;
                    height: 100%;
                }
            }

            .benefits__box {
                position: relative;
                margin-top: -50px;

                @media (max-width: 1440px) {
                    margin-top: -65px;
                }

                @media (max-width: 768px) {
                    width: 180px;
                    margin-top: -3px;
                }

                @media (max-width: 420px) {
                    height: 325px;
                    width: 275px;
                    margin-top: 21px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 0px 24px;
                }

                .benefits__box_mask {
                    display: none;

                    @media (max-width: 420px) {
                        position: absolute;
                        left: 0;
                        top: 5px;
                        -webkit-mask-image: url(../../../../assets/img/benefits-mask-photo-mobile.svg);
                        mask-image: url(../../../../assets/img/benefits-mask-photo-mobile.svg);
                        -webkit-mask-repeat: no-repeat;
                        mask-repeat: no-repeat;
                        height: 327px;
                        width: 327px;
                        display: block;
                    }

                    img {
                        @media (max-width: 420px) {
                            width: 800px;
                        }
                    }
                }

                .benefits__ico {
                    width: 28px;
                    height: 28px;
                    mix-blend-mode: multiply;
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                    opacity: 0;

                    @media (max-width: 420px) {
                        margin-top: -10px;
                    }
                }

                .benefits__name {
                    width: 166px;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    font-family: 'Space Grotesk';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 116%;
                    background: linear-gradient(0deg, #000000, #000000), linear-gradient(253.71deg, #FFFFFF 16.39%, #F79238 59.3%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                    opacity: 0;

                    @media (max-width: 768px) {
                        font-size: 22px !important;
                        line-height: 100% !important;
                        margin-bottom: 0;
                    }

                    @media (max-width: 420px) {
                        height: 61px;
                        width: 135px;
                        overflow-y: scroll;
                        line-height: 112% !important;
                        font-size: 27px !important;

                        &::-webkit-scrollbar {
                            width: 0px;
                            background: transparent;
                            height: 0px;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: #EEEFF5;
                        }
                    }
                }

                .benefits__description {
                    width: 288px;
                    font-family: 'Space Grotesk';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 168%;
                    color: #0D0E12;
                    opacity: 0;

                    @media (max-width: 768px) {
                        font-size: 14px !important;
                        width: 205px;
                    }

                    @media (max-width: 420px) {
                        width: 288px;
                        margin-top: 12px;
                        height: 95px;
                        overflow-y: scroll;

                        &::-webkit-scrollbar {
                            width: 0px;
                            background: transparent;
                            height: 0px;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: #EEEFF5;
                        }
                    }
                }
            }
        }
    }

    .benefits__exit {
        position: absolute;
        left: 0;
        bottom: 0;
    }
}