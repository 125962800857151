.notFound {
    height: 1080px;
    background: #10101B;
    margin-bottom: -160px;

    @media (max-width: 1440px) {
        height: 800px;
        margin-bottom: -210px;
    }

    @media (max-width: 768px) {
        margin-bottom: -150px;
    }

    @media (max-width: 420px) {
        height: 708px;
        margin-bottom: 0;
    }

    &::after {
        position: absolute;
        content: '';
        left: 0px;
        top: 0px;
        width: 100%;
        height: 1080px;
        background: url(../../assets/img/notFound-background.png);
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 1;

        @media (max-width: 1440px) {
            height: 800px;
        }
    }

    .notFound__block {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 1080px;
        text-align: center;
        z-index: 11;

        @media (max-width: 1440px) {
            height: 800px;
            margin-top: 50px;
        }

        &::after {
            position: absolute;
            content: "";
            left: 50%;
            transform: translate(-50%, -50%);
            top: 54%;
            width: 1421px;
            height: 785px;
            background: url(../../assets//img/404.svg);
            background-repeat: no-repeat;
            background-size: contain;
            z-index: -1;

            @media (max-width: 1440px) {
                top: 47%;
                width: 1121px;
                height: 585px;
            }

            @media (max-width: 420px) {
                top: 40%;
                width: 650px;
                height: 331px;
            }
        }

        .block {

            @media (max-width: 420px) {
                margin-top: -140px;
            }

            .block__name {
                @media (max-width: 768px) {
                    padding-left: 30px;
                }
            }

            .block__title {
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 450;
                font-size: 45px;
                line-height: 116%;
                text-transform: uppercase;
                margin-bottom: 30px;
                color: #FC64FC;
                animation: glitch 1s linear infinite;

                @media (max-width: 768px) {
                    font-weight: 450;
                    font-size: 40px;
                    line-height: 116%;
                }

                @media (max-width: 420px) {
                    font-weight: 450;
                    font-size: 27px;
                    line-height: 116%;
                }

                @keyframes glitch {

                    2%,
                    64% {
                        transform: translate(2px, 0) skew(0deg);
                    }

                    4%,
                    60% {
                        transform: translate(-2px, 0) skew(0deg);
                    }

                    62% {
                        transform: translate(0, 0) skew(5deg);
                    }
                }

                &:before,
                &:after {
                    content: attr(title);
                    position: absolute;
                    left: 0;
                }

                &:before {
                    animation: glitchTop 1s linear infinite;
                    clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
                    -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
                }

                @keyframes glitchTop {

                    2%,
                    64% {
                        transform: translate(2px, -2px);
                    }

                    4%,
                    60% {
                        transform: translate(-2px, 2px);
                    }

                    62% {
                        transform: translate(13px, -1px) skew(-13deg);
                    }
                }

                &:after {
                    animation: glitchBotom 1.5s linear infinite;
                    clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
                    -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
                }

                @keyframes glitchBotom {

                    2%,
                    64% {
                        transform: translate(-2px, 0);
                    }

                    4%,
                    60% {
                        transform: translate(-2px, 0);
                    }

                    62% {
                        transform: translate(-22px, 5px) skew(21deg);
                    }
                }
            }

            .notFound__button_mobile {
                position: absolute;
                left: 51%;
                bottom: 10%;
                transform: translateX(-50%);

                @media (min-width: 421px) {
                    display: none;
                }
            }

            .notFound__button {
                @media (max-width: 420px) {
                    display: none;
                }
            }
        }
    }
}

.pageNotFound {
    .footer__tuch {
        display: none;
    }
}