.buttonDefaultHeader {
    position: relative;
    transition: 0.5s;
    cursor: pointer;
    opacity: 0.9;
    display: block;
    filter: drop-shadow(0px 0px 14px #B1354F);

    .buttonDefaultHeader__mask {
        position: relative;
        -webkit-mask-image: url(../../../../assets/img/buttonDefaultHeader-mask.svg);
        mask-image: url(../../../../assets/img/buttonDefaultHeader-mask.svg);
        -webkit-mask-repeat: round;
        mask-repeat: round;
        width: 110px;
        height: 36px;

        @media (max-width: 420px) {
            -webkit-mask-image: url(../../../../assets/img/buttonDefaultHeader-mask-mobile.svg);
            mask-image: url(../../../../assets/img/buttonDefaultHeader-mask-mobile.svg);
            -webkit-mask-repeat: round;
            mask-repeat: round;
            width: 67.5px;
            height: 30px;
        }

        .color {
            background: url(../../../../assets/img/buttonDefaultHeader-mask.svg);
            width: 100%;
            height: 100%;

            @media (max-width: 420px) {
                background: url(../../../../assets/img/buttonDefaultHeader-mask-mobile.svg);
            }

            .buttonDefaultHeader__text {
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                display: inline-flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                width: 110px;
                height: 36px;
                color: #0D0E12;
                justify-content: center;

                @media (max-width: 420px) {
                    width: 67.5px;
                    height: 31px;
                    font-weight: 500;
                    font-size: 11px;
                    line-height: 14px;
                }
            }
        }
    }

    &:hover {
        opacity: 1;
    }
}