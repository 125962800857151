.buttonMainMobile {
    position: relative;
    font-family: "Space Grotesk";
    font-weight: 500;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    width: 368px;
    height: 90px;
    background: url(../../../assets/img/buttonMainMobile.svg);
    background-repeat: no-repeat;
    background-position: 0px 1px;
    transition: 0.5s;
    cursor: pointer;
    margin-left: -17px;
    color: #0D0E12;

    &::before {
        position: absolute;
        content: "";
        left: 0px;
        top: 0px;
        width: 368px;
        height: 90px;
        background: url(../../../assets/img/buttonMainMobile-gradient.svg);
        background-repeat: no-repeat;
        background-size: contain;
        mix-blend-mode: color-dodge;
        opacity: 0;
        transition: 0.5s;
    }

    span {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    &:hover {
        &::before {
            transition: 0.5s;
            opacity: 0.8;
        }
    }
}