.buttonMain {
    position: relative;
    cursor: pointer;
    opacity: 0.9;
    display: block;
    transition: 0.5s;
    filter: drop-shadow(0px 0px 14px rgba(137, 30, 188, 0.64));

    .buttonMain__mask {
        position: relative;
        -webkit-mask-image: url(../../../assets/img/buttonMain-mask-button.svg);
        mask-image: url(../../../assets/img/buttonMain-mask-button.svg);
        -webkit-mask-repeat: round;
        mask-repeat: round;
        width: 132px;
        height: 40px;

        .color {
            background: url(../../../assets/img/buttonMain-mask-button.svg);
            width: 100%;
            height: 100%;

            .buttonMain__text {
                font-family: "Space Grotesk";
                font-weight: 500;
                font-size: 12px;
                display: inline-flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                width: 132px;
                height: 40px;
                color: #0D0E12;
                justify-content: center;
            }
        }
    }

    &:hover {
        opacity: 1;
    }
}