.subscribe-button {
    overflow: hidden;
    position: relative;
}

.buttonMainSubscribe {
    background: transparent;
    font-family: "Space Grotesk", sans-serif;
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
    height: 40px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 122px;
}

.subscribe-button::before {
    background: rgb(76, 57, 191);
}

.buttonMainSubscribe::before {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.buttonMainSubscribe::before,
.buttonMainSubscribe::after {
    content: "";
    position: absolute;
    clip-path: polygon(10px 0px, calc(100% - 5px) 0px, 100% 10%, 100% 70%, calc(100% - 10px) 100%, 4% 100%, 0px calc(100% - 5px), 0px 15%);
}

.buttonMainSubscribe__block {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    clip-path: polygon(10px 0px, calc(100% - 5px) 0px, 100% 10%, 100% 70%, calc(100% - 10px) 100%, 4% 100%, 0px calc(100% - 5px), 0px 15%);
    left: 0px;
    z-index: 20;
}

.buttonMainSubscribe__block_line-2 {
    background: rgb(255, 255, 255);
    content: "";
    height: 155px;
    left: -75px;
    opacity: 0.6;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    width: 30px;
    z-index: 3;
    filter: blur(1px);
}

.buttonMainSubscribe__text {
    font-weight: 500;
    font-size: 13px;
    line-height: 100%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    z-index: 3;
    transition: all 0.3s ease 0s;
}

.subscribe-button::after {
    background: rgb(136, 117, 251);
}

.buttonMainSubscribe::after {
    top: 1px;
    left: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    z-index: 2;
}

.buttonMainSubscribe::before,
.buttonMainSubscribe::after {
    content: "";
    position: absolute;
    clip-path: polygon(10px 0px, calc(100% - 5px) 0px, 100% 10%, 100% 70%, calc(100% - 10px) 100%, 4% 100%, 0px calc(100% - 5px), 0px 15%);
}

.subscribe-button:hover .buttonMainSubscribe__block_line-1 {
    left: 120%;
}