.form {
    .form__title {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 450;
        font-size: 54px;
        line-height: 116%;
        text-transform: uppercase;
        margin-bottom: 35px;

        @media (max-width: 768px) {
            font-weight: 450;
            font-size: 54px;
            line-height: 116%;
        }

        @media (max-width: 420px) {
            font-weight: 450;
            font-size: 32px;
            line-height: 116%;
            margin-bottom: 25px;
        }
    }

    .form__box {
        form {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 580px;

            @media (max-width: 768px) {
                width: 100%;
            }


            .form_select_box {
                position: relative;
                width: 100%;

                .select__value {
                    position: relative;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 138%;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    width: 100%;
                    border-top: 1px solid #54545F;
                    border-bottom: 1px solid #54545F;
                    padding: 22px 0px;
                    margin-bottom: 5px;
                    cursor: pointer;
                    padding-left: 3px;

                    &::after {
                        position: absolute;
                        content: "";
                        right: 5px;
                        top: 27px;
                        width: 18px;
                        height: 18px;
                        background: url(../../../../assets/img/select-value-ico.svg);
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }

                .select__value_active {
                    &::after {
                        top: 22px;
                        transform: rotate(180deg);
                    }
                }

                .select__input {
                    display: none;
                }

                .select__items {
                    position: absolute;
                    left: 0;
                    top: 42px;
                    background: #272732;
                    padding: 16px 35px;
                    padding-left: 25px;
                    padding-bottom: 5px;
                    z-index: 11;
                    width: 525px;

                    @media (max-width: 420px) {
                        width: 84%;
                        padding-left: 20px;
                    }

                    .select_item {
                        font-family: "Space Grotesk";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 138%;
                        letter-spacing: 0.1em;
                        text-transform: uppercase;
                        color: #FFFFFF;
                        margin-bottom: 17px;
                        list-style: none;
                        padding-left: 0;
                        cursor: pointer;
                    }
                }
            }


            label {
                position: relative;

                @media (max-width: 768px) {
                    width: 100%;
                }

                @media (max-width: 420px) {
                    overflow: hidden;
                }

                .form__name {
                    position: absolute;
                    left: 0;
                    top: 4px;
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 138%;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    transition: 0.5s;
                }

                .form__star {
                    position: absolute;
                    right: -10px;
                    top: -5px;
                    color: #FC64FC;
                    font-weight: 400;
                    font-size: 11px;
                }

                .form__input {
                    border: 0px;
                    border-bottom: 1px solid #54545F;
                    width: 580px;
                    background: transparent;
                    height: 58px;
                    margin: 7px 0px;
                    outline: none;
                    font-family: "Space Grotesk";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 0;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    padding-bottom: 0px;

                    @media (max-width: 768px) {
                        width: 100%;
                    }

                    &:focus-visible {
                        outline: none;
                    }
                }

                .form__text {
                    width: 100%;
                    border: 0px;
                    border-bottom: 1px solid #54545F;
                    width: 580px;
                    height: 150px;
                    background: transparent;
                    margin: 8px 0px;
                    outline: none;
                    font-family: 'Space Grotesk';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 138%;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    margin-top: 20px;

                    @media (max-width: 768px) {
                        width: 100%;
                    }

                    &:focus-visible {
                        outline: none;
                    }

                    &::-webkit-scrollbar {
                        width: 3px;
                        background-color: rgba(157, 122, 255, 0.0823529412);
                        height: 0px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #9d7aff;
                    }
                }
            }


            .agreement {
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 300;
                font-size: 12px;
                line-height: 168%;
                letter-spacing: 0.03em;
                color: #FFFFFF;
                margin-top: 12px;
                margin-bottom: 10px;
                display: flex;
                align-items: center;

                @media (max-width: 768px) {
                    min-width: 280px;
                }

                a {
                    letter-spacing: 0.03em;
                    text-decoration-line: underline;
                    color: #8875FB;

                    @media (max-width: 768px) {
                        width: 100%;
                    }
                }
            }

            .buttonMainSend {
                margin-left: auto;
                margin-right: -15px;
                margin-top: -5px;

                @media (max-width: 420px) {
                    margin-top: 10px;
                    margin-left: -5px;
                }
            }

            .checkbox {
                cursor: pointer;
                display: flex;
                align-items: center;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                margin-bottom: 0;
                margin-right: 5px;
            }

            .checkbox__default {
                display: none;
            }

            .checkbox__new {
                width: 24px;
                height: 24px;
                background: #212124;
                border-radius: 7px;
                margin-right: 10px;
                position: relative;
                display: block;
                flex-shrink: 0;
            }

            .checkbox__new::after {
                content: "";
                background-image: url(../../../../assets/img/arrow-checkbox.svg);
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                display: block;
                width: 0;
                height: 0;
                left: 50%;
                top: 50%;
                position: absolute;
                transform: translate(-50%, -50%);
                transition: 0.11s ease-in;
            }

            .checkbox__default:checked+.checkbox__new::after {
                height: 24px;
                width: 15px;
                border-radius: 50%;
            }
        }
    }
}

.css-b62m3t-container {
    width: 100%;
    border-top: 1px solid #54545F;
    border-bottom: 1px solid #54545F;
    padding: 8px 0px;
    margin-bottom: 5px;
    cursor: pointer;
}

.css-qc6sy-singleValue {
    color: hsl(0, 0%, 20%);
    grid-area: 1/1/2/3;
    margin-left: 2px;
    margin-right: 2px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 16px;
    line-height: 138%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
}

.css-tlfecz-indicatorContainer {
    svg {
        path {
            fill: #FC64FC;
        }
    }
}

.css-1gtu0rj-indicatorContainer {
    transition: 0.5s;

    svg {
        transform: rotate(180deg);

        path {
            fill: #FC64FC;
        }
    }
}


.css-tj5bde-Svg {
    width: 30px;
    height: 25px;
}

.css-1s2u09g-control {
    background-color: transparent !important;
    border-color: transparent !important;
}

.css-qc6sy-singleValue {
    color: hsl(0, 0%, 20%);
    grid-area: 1/1/2/3;
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 16px;
    line-height: 138%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
}

.css-319lph-ValueContainer {
    padding-left: 1px;
}

#react-select-3-listbox {
    background: #272732;
    background-color: #272732;
    border-color: transparent !important;
}

.css-1g8ily9-Control {
    display: flex;
    align-items: center;
}

.css-14el2xx-placeholder {
    color: hsl(0, 0%, 50%);
    grid-area: 1/1/2/3;
    margin-left: 0;
    margin-right: 2px;
    box-sizing: border-box;
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 138%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #878787;
}

.react-select__option {
    font-size: 1px !important;
}


#react-select-3-option-0 {
    display: none !important;
}

#react-select-3-option-1 {
    background: transparent !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 138% !important;
    letter-spacing: 0.1em !important;
    text-transform: uppercase !important;
    color: #FFFFFF !important;
    cursor: pointer;

    &:hover {
        color: #b3b3b3 !important;
    }
}

#react-select-3-option-2 {
    background: transparent !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 138% !important;
    letter-spacing: 0.1em !important;
    text-transform: uppercase !important;
    color: #FFFFFF !important;
    cursor: pointer;


    &:hover {
        color: #b3b3b3 !important;
    }
}

#react-select-3-option-3 {
    background: transparent !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 138% !important;
    letter-spacing: 0.1em !important;
    text-transform: uppercase !important;
    color: #FFFFFF !important;
    cursor: pointer;


    &:hover {
        color: #b3b3b3 !important;
    }
}


.css-6j8wv5-Input {
    margin: 2px;
    padding-bottom: 2px;
    padding-top: 2px;
    visibility: visible;
    color: hsl(0, 0%, 20%);
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: inline-grid;
    grid-area: 1/1/2/3;
    grid-template-columns: 0 min-content;
    box-sizing: border-box;
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 138% !important;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #878787 !important;
}

.css-1jwi8f4-singleValue {
    color: hsl(0, 0%, 20%);
    grid-area: 1/1/2/3;
    margin-left: 0;
    margin-right: 2px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    opacity: 1;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 138% !important;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #878787 !important;
}

.css-319lph-ValueContainer {
    padding: 2px 1px;
}

.css-1okebmr-indicatorSeparator {
    width: 0px !important;
}

.css-qc6sy-singleValue {
    color: hsl(0, 0%, 20%);
    grid-area: 1/1/2/3;
    margin-left: 0;
    margin-right: 2px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 138% !important;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff !important;
}

.css-319lph-ValueContainer {
    padding-left: 2px !important;
}