.productItem {
    position: relative;
    display: none;
    margin: 0 21px;
    transition: 0.5s;

    &:nth-child(1) {
        display: block;
    }

    &:nth-child(2) {
        display: block;
    }

    &:nth-child(3) {
        display: block;
    }

    &:nth-child(4) {
        display: block;
    }

    &:nth-child(5) {
        display: block;
    }

    &:nth-child(6) {
        display: block;
    }

    &:nth-child(7) {
        display: block;
    }

    &:nth-child(8) {
        display: block;
    }

    &:nth-child(9) {
        display: block;
    }

    &:nth-child(10) {
        display: block;
    }

    &:nth-child(11) {
        display: block;
    }

    &:nth-child(12) {
        display: block;
    }

    @media (max-width: 1440px) {
        &:nth-child(11) {
            display: none;
        }

        &:nth-child(12) {
            display: none;
        }
    }

    @media (max-width: 990px) {
        margin: 0 10px;

        &:nth-child(9) {
            display: none;
        }

        &:nth-child(10) {
            display: none;
        }
    }

    @media (max-width: 768px) {
        margin: 0px 0px;
    }

    .product {
        position: relative;
        width: 190px;
        height: 277.13px;
        margin: 0px 20px;
        margin-bottom: 20px;
        z-index: -1;
        transition: 1.5s;

        @media (max-width: 768px) {
            width: 152px;
            height: 222px;
        }

        &:first-child {
            margin-left: 0;
        }

        &:nth-child(6) {
            margin-right: 0;
        }

        &:nth-child(7) {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        .product__photo_mask {
            position: absolute;
            left: -1px;
            top: -2px;
            -webkit-mask-image: url(../../../assets/img/product-card-mask.svg);
            mask-image: url(../../../assets/img/product-card-mask.svg);
            -webkit-mask-repeat: round;
            mask-repeat: round;
            width: 190px;
            height: 277.13px;

            @media (max-width: 768px) {
                left: 8px;
                -webkit-mask-image: url(../../../assets/img/product-card-mask-768.svg);
                mask-image: url(../../../assets/img/product-card-mask-768.svg);
                -webkit-mask-repeat: round;
                mask-repeat: round;
                width: 152px;
                height: 222px;
            }

            .back {
                width: 100%;
                height: 100%;
                background: #2b2748;
            }

            &::before {
                content: "";
                width: 100%;
                height: 100%;
                transition: 0.5s;
                background-image: linear-gradient(var(--rotate), #5ddcff, #3c67e3 43%, #4e00c2);
                position: absolute;
                z-index: 1;
                top: 0%;
                left: 0%;
                animation: spin 2.5s linear infinite;
            }

            &::after {
                position: absolute;
                content: "";
                top: calc(var(--card-height) / 6);
                left: 0;
                right: 0;
                z-index: 1;
                height: 100%;
                width: 100%;
                margin: 0 auto;
                transform: scale(0.8);
                filter: blur(calc(var(--card-height) / 6));
                background-image: linear-gradient(var(--rotate), #5ddcff, #3c67e3 43%, #4e00c2);
                opacity: 1;
                transition: opacity 1s;
                animation: spin 2.5s linear infinite;
            }

            .product__photo {
                &::before {
                    background: transparent;
                }
            }

            &:before {
                animation: none;
                opacity: 0;
            }

            &::after {
                animation: none;
                opacity: 0;
            }
        }

        .product__photo {
            position: relative;
            width: 172.42px;
            height: 200.95px;
            display: flex;
            justify-content: center;
            margin: 0 auto;
            cursor: pointer;
            transition: 0.5s;
            overflow: hidden;

            @media (max-width: 768px) {
                width: 138px;
                height: 151.95px;
                margin-left: 17px;
            }

            img {
                width: 100%;
                height: 100%;
                overflow: hidden;
                object-fit: cover;
                margin-top: 8px;
                z-index: 11;
            }
        }

        .product__block {
            position: relative;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            padding: 0px 12px;
            margin-top: 1px;
            z-index: 11;

            @media (max-width: 768px) {
                margin-left: 5px;
            }

            @media (max-width: 420px) {
                margin-left: 6px;
                margin-top: 0;
            }

            .product__title {
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 126%;
                text-align: center;
                color: #FFFFFF;
                text-shadow: 0px 0px 11px rgba(136, 117, 251, 0.53);
                margin-top: 9px;
                transition: 0.5s;

                @media (max-width: 768px) {
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 126%;
                    width: 124px;
                    overflow-y: scroll;
                    white-space: nowrap;

                    &::-webkit-scrollbar {
                        width: 0px;
                        background: transparent;
                        height: 0px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #EEEFF5;
                    }
                }
                @media (max-width: 420px) {
                    text-align: left;
                }

            }

            .product__price {
                position: relative;
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 138%;
                text-align: center;
                color: #FFFFFF;
                margin-top: 5px;
                padding-left: 25px;

                @media (max-width: 768px) {
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 126%;
                    margin-top: 3px;
                    padding-left: 18px;
                }

                @media (max-width: 420px) {
                    margin-top: 5px;
                }

                &::before {
                    position: absolute;
                    content: '';
                    left: 2px;
                    top: 1px;
                    background: url(../../../assets/img/coin.png);
                    width: 17.22px;
                    height: 18px;
                    background-repeat: no-repeat;
                    background-size: cover;

                    @media (max-width: 768px) {
                        position: absolute;
                        content: "";
                        left: 1px;
                        top: 1px;
                        width: 14.22px;
                        height: 15px;
                        background-repeat: no-repeat;
                        background-size: cover;
                    }
                }
            }
        }
    }

    &:hover {
        color: rgb(189 122 251);
        cursor: pointer;
        transition: 0.5s;

        .product__photo_mask {
            transition: 1s;

            &:before {
                transition: 2.5s;
                animation: spin 2.5s linear infinite;
                opacity: 1;

                @media (max-width: 768px) {
                    display: none;
                }
            }

            &:after {
                transition: 2.5s;
                animation: spin 2.5s linear infinite;
                opacity: 1;

                @media (max-width: 768px) {
                    display: none;
                }
            }

            .backSaf {
                background: linear-gradient(rgb(189 122 251), rgb(235 118 251) 43%, rgb(252 109 230));
                transition: 1.5s;

                @media (max-width: 768px) {
                    display: none;
                }
            }
        }
    }

    :root {
        --card-height: 65vh;
        --card-width: calc(var(--card-height) / 1.5);
    }

    @keyframes spin {
        0% {
            --rotate: 0deg;
        }

        100% {
            --rotate: 360deg;
        }
    }
}

@property --rotate {
    syntax: "<angle>";
    initial-value: 132deg;
    inherits: false;
    transition: 0.5s;
}