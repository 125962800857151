.blogSection {
    width: 1340px;
    margin: 0 auto;
    margin-top: 100px;

    @media (max-width: 1440px) {
        width: 1240px;
    }

    @media (max-width: 768px) {
        width: 100%;
        margin-top: 80px;
    }

    @media (max-width: 420px) {
        margin-top: 90px;
    }

    .BlogSection__title {
        margin-bottom: 35px;
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 450;
        font-size: 50px;
        line-height: 116%;
        background: linear-gradient(177deg, #8875FB -0.58%, #FC64FC 44.41%, #FE9040 91.3%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        text-shadow: 0px 0px 11px rgb(136 117 251 / 54%);

        @media (max-width: 1440px) {
            font-weight: 450 !important;
            font-size: 45px !important;
            line-height: 116% !important;
        }

        @media (max-width: 768px) {
            font-weight: 450 !important;
            font-size: 28px !important;
            line-height: 116% !important;
        }

        @media (max-width: 420px) {
            font-weight: 450 !important;
            font-size: 32px !important;
            line-height: 120% !important;
        }

        @media (max-width: 768px) {
            text-align: center;
        }
    }

    .BlogSection__union {
        display: flex;
        justify-content: space-between;

        @media (max-width: 1440px) {
            justify-content: space-around;
            margin-left: -34px;
        }

        @media (max-width: 768px) {
            width: 650px;
            justify-content: center;
            margin: 0 auto;
            margin-left: auto;
        }

        @media (max-width: 420px) {
            width: 87%;
            flex-direction: column;
            align-content: center;
        }

        .BlogSection__mainpost {
            transition: 0.5s;
            opacity: 0;

            @media (max-width: 768px) {
                display: none;
            }

            @media (max-width: 420px) {
                display: block;
            }

            .mainpost__photo {
                width: 737px;
                height: 406px;
                background: url(../../../../assets//img/mainpost-photo-border.svg);
                object-position: center;
                margin-left: -14px;

                @media (max-width: 1440px) {
                    width: 557px;
                    height: 308px;
                    background: url(../../../../assets//img/mainpost-photo-border-1440.svg);
                    object-position: center;
                }

                @media (max-width: 420px) {
                    width: 340px;
                    height: 190px;
                    background: url(../../../../assets//img/mainpost-photo-border-420.svg);
                    object-position: center;
                }

                .mainpost__img {
                    position: relative;
                    z-index: -1;
                    overflow: hidden;
                    width: 723px;
                    height: 390px;
                    object-fit: cover;

                    @media (max-width: 1440px) {
                        width: 545px;
                        height: 297px;
                        object-fit: cover;
                    }

                    @media (max-width: 420px) {
                        width: 335px;
                        height: 183px;
                        object-fit: cover;
                    }
                }
            }

            .mainpost__title {
                width: 535px;
                margin-top: 15px;
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 700;
                font-size: 26px;
                line-height: 116%;
                color: #ffffff;
                transition: 0.5s;

                @media (max-width: 420px) {
                    font-weight: 700;
                    font-size: 21px;
                    line-height: 116%;
                }

                &:hover {
                    color: #d95eda;
                }
            }

            .mainpost__description {
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 168%;
                color: #FFFFFF;
                margin: 12px 0px;
                width: 535px;

                @media (max-width: 420px) {
                    width: 100%;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 168%;
                }
            }

            .mainpost__link {
                position: relative;
                width: 38px;
                background: linear-gradient(177deg, #8875FB -0.58%, #FC64FC 44.41%, #FE9040 91.3%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                text-shadow: 0px 0px 11px rgb(136 117 251 / 54%);
                display: inline-block;
                transition: 0.5s;

                @media (max-width: 420px) {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 15px;
                    text-align: center;
                    display: flex;
                    justify-self: center;
                    width: 42px;
                    margin: 0px;
                    margin-top: 20px;
                    color: rgb(214, 111, 252) !important;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: currentcolor;
                    background-clip: text;
                }

                &::after {
                    position: absolute;
                    content: "";
                    right: -14px;
                    top: 2.3px;
                    width: 10px;
                    height: 10px;
                    background: url(../../../../assets/img/read-ico.svg);
                    background-repeat: no-repeat;
                    background-size: cover;

                    @media (max-width: 420px) {
                        right: -14px;
                        top: 2.2px;
                    }
                }

                &:hover {
                    transition: 0.5s;

                    &::after {
                        transition: 0.5s;
                        right: -24px;
                    }
                }
            }
        }

        .BlogSection__block {

            @media (max-width: 768px) {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: center;
            }

            @media (max-width: 420px) {
                position: relative;
                margin-top: 14px;
                padding-top: 40px;
                display: none;

                &::after {
                    position: absolute;
                    content: '';
                    left: 50%;
                    transform: translateX(-50%);
                    top: 0px;
                    width: 95%;
                    height: 1px;
                    background: url(../../../../assets/img/color-border.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }

            .block__name-section {
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 450;
                font-size: 12px;
                line-height: 126%;
                letter-spacing: 0.3em;
                text-transform: uppercase;
                color: #FC64FC;
                margin-bottom: 20px;
                margin-left: 17px;
                opacity: 0;

                @media (max-width: 768px) {
                    display: none;
                }

                @media (max-width: 420px) {
                    display: block;
                    font-weight: 450;
                    font-size: 12px;
                    line-height: 126%;
                    margin-left: 1px;
                }
            }

            .block__union {
                position: relative;
                display: flex;
                padding-bottom: 20px;
                margin-bottom: 20px;
                opacity: 0;

                @media (max-width: 768px) {
                    flex-direction: column;
                    width: 311px;
                    margin-bottom: 50px;
                    display: none;

                    &:nth-child(2) {
                        display: block;
                    }

                    &:nth-child(3) {
                        display: block;
                    }
                }

                @media (max-width: 420px) {
                    width: 100%;
                    flex-direction: row;
                    margin-bottom: 10px;
                    display: block;
                }

                &::before {
                    position: absolute;
                    content: "";
                    left: 18px;
                    bottom: 0px;
                    width: 100%;
                    height: 1px;
                    background: url(../../../../assets//img/line-bottom-article.png);
                    background-size: cover;
                    background-repeat: no-repeat;

                    @media (max-width: 768px) {
                        display: none;
                    }
                }

                &:last-child {
                    &::before {
                        display: none;
                    }
                }

                .block__box_photo {
                    width: 112px;
                    height: 126px;
                    background: url(../../../../assets/img/block-box-photo-border.svg);
                    overflow: hidden;

                    @media (max-width: 1440px) {
                        width: 82px;
                        height: 95px;
                        background: url(../../../../assets/img/block-box-photo-border-1440.svg);
                        overflow: hidden;
                    }

                    @media (max-width: 768px) {
                        width: 330px;
                        height: 184px;
                        background: url(../../../../assets/img/block-box-photo-border-tablet.svg);
                        overflow: hidden;
                    }

                    @media (max-width: 420px) {
                        width: 290px;
                        height: 96px;
                        background: url(../../../../assets/img/block-box-photo-border-420.svg);
                        overflow: hidden;
                    }

                    .block__photo {
                        position: relative;
                        display: block;
                        object-fit: cover;
                        z-index: -1;
                        width: 119px;
                        height: 121px;

                        @media (max-width: 1440px) {
                            width: 76px;
                            height: 91px;
                        }

                        @media (max-width: 768px) {
                            width: 323px;
                            height: 175px;
                            object-fit: cover;
                        }

                        @media (max-width: 420px) {
                            width: 82px;
                            height: 94px;
                        }
                    }
                }

                .block__box {
                    cursor: pointer;
                    margin-left: 10px;

                    @media (max-width: 420px) {
                        margin-left: 20px;
                    }

                    .block__title {
                        font-family: 'Space Grotesk';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 116%;
                        color: rgba(255, 255, 255, 1);
                        width: 402px;
                        margin-top: 12px;

                        @media (max-width: 1440px) {
                            width: 500px;
                        }

                        @media (max-width: 768px) {
                            width: 311px;
                        }

                        @media (max-width: 420px) {
                            width: 100%;
                            margin-top: 0;
                        }
                    }

                    .block__description {
                        font-family: 'Space Grotesk';
                        font-style: normal;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 158%;
                        color: #FFFFFF;
                        margin-top: 6px;
                        width: 402px;

                        @media (max-width: 1440px) {
                            width: 500px;
                        }

                        @media (max-width: 768px) {
                            width: 311px;
                        }

                        @media (max-width: 420px) {
                            width: 100%;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            webkit-box-orient: vertical;
                            overflow-y: hidden;
                        }
                    }
                }

                &:hover {
                    .block__box {
                        .block__title {
                            color: #FC64FC;
                            transition: 0.5s;
                        }

                        .block__description {
                            color: #FC64FC;
                            transition: 0.5s;
                        }
                    }
                }
            }
        }
    }

    .BlogSection__button {
        display: flex;
        justify-content: center;
        justify-content: center;
        margin: 0 auto;
        margin-top: 20px;
        opacity: 0;

        @media (max-width: 768px) {
            margin-top: -10px;
        }

        @media (max-width: 420px) {
            margin-top: 38px;
        }
    }
}