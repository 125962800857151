.articlePage {

    .article {

        .article__block {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 781px;

            @media (max-width: 1440px) {
                height: 585.5px;
            }

            @media (max-width: 768px) {
                height: 312.27px;
            }

            @media (max-width: 420px) {
                height: 540px;
            }

            .article__block_box_photo {
                position: relative;
                width: 100%;
                height: 789px;
                overflow: hidden;

                &::after {
                    position: absolute;
                    content: '';
                    left: 0px;
                    top: 0px;
                    width: 100%;
                    height: 789px;
                    background: url(../../../assets//img/article-photo-border.svg);
                    background-size: cover;
                    z-index: 11;

                    @media (max-width: 1440px) {
                        height: 592px;
                    }

                    @media (max-width: 768px) {
                        height: 315px;
                    }

                    @media (max-width: 420px) {
                        height: 550px;
                        background-position: center;
                    }
                }

                .article__block_background {
                    position: relative;
                    display: block;
                    overflow: hidden;
                    height: 780px;
                    width: 100%;
                    object-fit: cover;
                    z-index: -1;

                    @media (max-width: 1440px) {
                        height: 585.5px;
                    }

                    @media (max-width: 768px) {
                        height: 312.27px;
                    }

                    @media (max-width: 420px) {
                        height: 540px;
                    }

                    @media (max-width: 1440px) {
                        height: 585.5px;
                    }

                    @media (max-width: 768px) {
                        height: 312.27px;
                    }

                    @media (max-width: 420px) {
                        height: 540px;
                    }
                }
            }

            .article__block_box {
                position: absolute;
                left: 50%;
                top: 40%;
                transform: translate(-50%, -50%);
                z-index: 111;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                @media (max-width: 768px) {
                    top: 45%;
                }

                @media (max-width: 420px) {
                    width: 100%;
                }

                .article__title {
                    font-family: 'Space Grotesk';
                    font-style: normal;
                    font-weight: 450;
                    font-size: 50px;
                    line-height: 116%;
                    color: #FFFFFF;
                    margin-bottom: 15px;

                    @media (max-width: 1440px) {
                        font-weight: 450 !important;
                        font-size: 45px !important;
                        line-height: 136% !important;
                    }

                    @media (max-width: 768px) {
                        text-align: center;
                        font-size: 28px !important;
                    }

                    @media (max-width: 375px) {
                        margin-top: 18px;
                    }
                }

                .article__data {
                    font-family: 'Space Grotesk';
                    font-style: normal;
                    font-weight: 450;
                    font-size: 12px;
                    line-height: 126%;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    color: #FC64FC;

                    span {
                        position: relative;
                        font-family: 'Space Grotesk';
                        font-style: normal;
                        font-weight: 450;
                        font-size: 12px;
                        line-height: 126%;
                        color: #FFFFFF;
                        margin-left: 18px;

                        &::before {
                            position: absolute;
                            content: "";
                            left: -10px;
                            top: 3px;
                            width: 1px;
                            height: 10.5px;
                            background: rgba(255, 255, 255, 0.23);
                        }
                    }
                }
            }
        }

        .article__box {
            position: relative;
            width: 720px;
            margin: 0 auto;
            margin-top: 87px;

            @media (max-width: 768px) {
                margin-top: 40px;
            }

            @media (max-width: 420px) {
                width: 90%;
                margin-top: 0;
            }

            img {
                width: 710px;
                object-fit: cover;

                @media (max-width: 420px) {
                    width: 100%;
                }
            }

            .article__link {
                position: absolute;
                left: -45%;
                top: 10px;
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 300;
                font-size: 12px;
                line-height: 15px;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                background: linear-gradient(93.09deg, #8875FB -15.25%, #FC64FC 62.67%, #FE9040 125.88%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                opacity: 0.5;
                transition: 0.5s;

                &:hover {
                    opacity: 1;
                }
            }

            p {
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 216%;
                color: #FFFFFF;
                margin-bottom: 25px;

                @media (max-width: 768px) {
                    font-weight: 300;
                    font-size: 15px;
                    line-height: 216%;
                }
            }

            h2 {
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 450;
                font-size: 28px;
                line-height: 116%;
                color: #FFFFFF;
                margin-top: 30px;

                @media (max-width: 1440px) {
                    font-weight: 450 !important;
                    font-size: 28px !important;
                    line-height: 116% !important;
                }

                @media (max-width: 768px) {
                    font-size: 24px !important;
                }
            }

            h3 {
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 450;
                font-size: 25px;
                line-height: 116%;
                color: #FFFFFF;
                margin-top: 30px;
            }

            h4 {
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 450;
                font-size: 22px;
                line-height: 116%;
                color: #FFFFFF;
                margin-top: 30px;
            }

            h5 {
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 450;
                font-size: 20px;
                line-height: 116%;
                color: #FFFFFF;
                margin-top: 30px;
            }

            h6 {
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 450;
                font-size: 18px;
                line-height: 116%;
                color: #FFFFFF;
                margin-top: 30px;
            }

            a {
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 216%;
                text-decoration-line: underline;
                color: #8875FB;

                @media (max-width: 768px) {
                    font-weight: 300;
                    font-size: 15px;
                    line-height: 216%;
                }

                &:hover {
                    text-decoration: none;
                }
            }

            ul {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding-left: 23px;
                margin-bottom: 25px;

                li {
                    position: relative;
                    font-family: 'Space Grotesk';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 176%;
                    color: #FFFFFF;
                    list-style: none;
                    margin: 10px 0px;
                    display: inline-block;
                    margin-left: 12px;
                    padding-bottom: 12px;

                    @media (max-width: 768px) {
                        font-weight: 300;
                        font-size: 15px;
                        line-height: 177%;
                    }

                    &::before {
                        position: absolute;
                        content: "";
                        left: -33px;
                        top: 5px;
                        width: 21px;
                        height: 21px;
                        background: url(../../../assets//img/item-article.svg);
                        background-repeat: no-repeat;
                        background-size: cover;
                    }

                    &::after {
                        position: absolute;
                        content: '';
                        left: 0px;
                        bottom: 0px;
                        width: 100%;
                        height: 1px;
                        background: linear-gradient(90.03deg, rgba(136, 117, 251, 0.5) -30.09%, rgba(136, 117, 251, 0) 133.19%);
                    }
                }
            }

            ol {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding-left: 23px;
                margin-bottom: 25px;
                counter-reset: item;

                li {
                    position: relative;
                    font-family: 'Space Grotesk';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 176%;
                    color: #FFFFFF;
                    list-style: none;
                    margin: 10px 0px;
                    display: inline-block;
                    margin-left: 12px;
                    padding-bottom: 12px;

                    @media (max-width: 768px) {
                        font-weight: 300;
                        font-size: 15px;
                        line-height: 177%;
                    }

                    &::before {
                        position: absolute;
                        left: -32px;
                        top: 5px;
                        font-size: 16px;
                        content: '0' counter(item)'.';
                        counter-increment: item;
                        font-family: 'Space Grotesk';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 176%;
                        background: linear-gradient(90deg, #FC64FC 35%, #FE9040 110%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;
                        text-shadow: 0px 0px 8px #FC64FC;
                    }

                    &::after {
                        position: absolute;
                        content: '';
                        left: 0px;
                        bottom: 0px;
                        width: 100%;
                        height: 1px;
                        background: linear-gradient(90.03deg, rgba(136, 117, 251, 0.5) -30.09%, rgba(136, 117, 251, 0) 133.19%);
                    }
                }
            }

            img {
                width: 720px;
                height: 387px;

                @media (max-width: 420px) {
                    width: 100%;
                    height: auto;
                }
            }

            blockquote {
                position: relative;
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 500;
                font-size: 17px;
                line-height: 166%;
                background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(97.95deg, #8875FB -2.11%, #FC64FC 55.98%, #FE9040 108.32%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                textFillColor: transparent;
                padding-left: 41px;
                padding-top: 30px;
                padding-bottom: 30px;
                margin-left: 0;
                padding-right: 0;
                margin-right: 0;
                margin-top: 35px;
                margin-bottom: 35px;

                @media (max-width: 768px) {
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 216%;
                }

                svg {
                    position: absolute;
                    content: "";
                    left: -1px;
                    top: 30px;
                }

                &::after {
                    position: absolute;
                    content: '';
                    left: 0px;
                    top: 0px;
                    width: 100%;
                    height: 1px;
                    background: url(../../../assets/img/blockquote-line.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                }

                &::before {
                    position: absolute;
                    content: '';
                    left: 0px;
                    bottom: 0px;
                    width: 100%;
                    height: 1px;
                    background: url(../../../assets/img/blockquote-line.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }

            .article__social {
                margin-top: 55px;

                .social {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;

                    .social__name {
                        margin-bottom: 26px;

                        @media (max-width: 768px) {
                            padding-left: 30px;
                        }
                    }

                    .socials {
                        margin-left: 0;

                        @media (max-width: 420px) {
                            width: 300px;
                        }
                    }
                }
            }
        }

        .article__similars {

            .similars {
                width: 1650px;
                margin: 0 auto;
                margin-top: 155px;

                @media (max-width: 1440px) {
                    width: 1170px;
                }

                @media (max-width: 990px) {
                    width: 92%;
                }

                @media (max-width: 420px) {
                    width: 90%;
                    margin-top: 110px;
                }

                .similars__block {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .similars__title {
                        font-family: 'Space Grotesk';
                        font-style: normal;
                        font-weight: 450;
                        font-size: 40px;
                        line-height: 116%;

                        @media (max-width: 420px) {
                            font-size: 24px !important;
                        }
                    }

                    .similars__link {
                        position: relative;
                        font-family: 'Space Grotesk';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 13px;
                        min-width: 109.52px;
                        height: 33px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        letter-spacing: 0.12em;
                        text-transform: uppercase;
                        width: 129px;
                        height: 55px;
                        background: url(../../../assets/img/main-button-article.svg);
                        background-repeat: no-repeat;
                        background-position: 0px 0px;
                        transition: 0.5s;
                        cursor: pointer;
                        color: #FFFFFF;

                        @media (max-width: 768px) {
                            width: 137px;
                            height: 62px;
                            background: url(../../../assets/img/main-button-article.svg);
                            background-repeat: no-repeat;
                            background-position: 2px 3px;
                        }

                        @media (max-width: 420px) {
                            display: none;
                        }

                        &::before {
                            position: absolute;
                            content: "";
                            left: 0px;
                            top: 0px;
                            width: 129px;
                            height: 55px;
                            transition: 0.5s;
                            background: url(../../../assets/img/main-button-article-gradient.svg);
                            background-repeat: no-repeat;
                            background-size: contain;
                            mix-blend-mode: color-dodge;
                            opacity: 0;
                            transition: 0.5s;

                            @media (max-width: 768px) {
                                top: 3px;
                                left: 2px;
                                width: 129px;
                                height: 62px;
                                background: url(../../../assets/img/main-button-article-gradient.svg);
                                background-repeat: no-repeat;
                                background-size: contain;
                            }
                        }

                        span {
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                        }

                        &:hover {
                            color: rgba(136, 117, 251, 1);
                            transition: 0.5s;

                            &::before {
                                transition: 0.5s;
                                opacity: 0.8;
                            }
                        }
                    }
                }

                .similars__items {
                    margin-top: 35px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    @media (max-width: 420px) {
                        flex-direction: column;
                    }

                    .similars__item {
                        position: relative;
                        display: block;
                        width: 363px;

                        @media (max-width: 768px) {
                            width: 215px;
                        }

                        @media (max-width: 420px) {
                            width: 100%;
                            margin-bottom: 40px;
                        }

                        .similars__photo_box {
                            z-index: 11;
                            height: 312px;
                            width: 378px;
                            background: url(../../../assets//img/similars-photo-border.svg);
                            overflow: hidden;
                            margin-left: -15px;

                            @media (max-width: 1440px) {
                                background: transparent;
                            }

                            @media (max-width: 768px) {
                                height: 191px;
                            }

                            @media (max-width: 420px) {
                                margin-left: -8px;
                            }

                            &::after {
                                position: absolute;
                                z-index: 111;
                                content: '';
                                left: -15px;
                                top: 0px;
                                height: 302.66px;
                                width: 381px;
                                object-fit: cover;
                                background: url(../../../assets/img/similars-photo-border.svg);
                                overflow: hidden;
                                z-index: 111;

                                @media (max-width: 768px) {
                                    height: 203px;
                                    width: 240px;
                                    background: url(../../../assets/img/blog-article-photo-border-768.svg);
                                }

                                @media (max-width: 420px) {
                                    height: 193px;
                                    width: 344px;
                                    background: url(../../../assets/img/blog-border-main-photo.svg);
                                    left: -8px;
                                }
                            }

                            .similars__photo {
                                position: relative;
                                display: block;
                                width: 385px;
                                height: 301px;
                                object-fit: cover;
                                overflow: hidden;
                                z-index: -1;

                                @media (max-width: 768px) {
                                    height: 192px;
                                    width: 237px;
                                }

                                @media (max-width: 420px) {
                                    height: 183px;
                                    width: 336px;
                                }
                            }
                        }

                        .similars__title {
                            font-family: 'Space Grotesk';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 26px;
                            line-height: 116%;
                            color: #ffffff;
                            margin-top: 20px;

                            @media (max-width: 768px) {
                                font-weight: 700;
                                font-size: 18px;
                                line-height: 116%;
                            }

                            @media (max-width: 420px) {
                                margin-top: 10px;
                            }
                        }

                        .similars__description {
                            font-family: 'Space Grotesk';
                            font-style: normal;
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 168%;
                            color: #FFFFFF;
                            margin-top: 10px;
                            width: 363px;

                            @media (max-width: 768px) {
                                width: 100%;
                                font-weight: 300;
                                font-size: 14px;
                                line-height: 168%;
                            }
                        }

                        .similars__button {
                            display: inline-flex;
                            align-items: center;
                            margin-top: 14px;
                            transition: 0.5s;
                            cursor: pointer;
                            justify-content: space-between;

                            svg {
                                margin-left: 5px;
                                transition: 0.5s;
                                margin-top: 0px;
                            }

                            &:hover {
                                svg {
                                    transition: 0.5s;
                                    margin-left: 10px;
                                }
                            }
                        }

                        &:hover {
                            cursor: pointer;
                            transition: 0.5s;

                            .similars__photo {
                                transition: 0.5s;

                                &::after {
                                    transition: 0.5s;
                                    position: absolute;
                                    content: '';
                                    left: 0px;
                                    top: 0px;
                                    height: 285.66px;
                                    width: 363px;
                                    background: linear-gradient(219.23deg, rgba(136, 117, 251, 0.1) -30.76%, rgba(252, 100, 252, 0.15) 95.85%, rgba(254, 144, 64, 0.3) 124.33%);
                                    border-bottom-right-radius: 30px;
                                }
                            }

                            .similars__title {
                                color: #FC64FC;
                                transition: 0.5s;
                            }

                            .similars__description {
                                color: #FC64FC;
                                transition: 0.5s;
                            }
                        }

                        @media (max-width: 1440px) {
                            &:last-child {
                                display: none;
                            }
                        }
                    }
                }

                .similars__all_button {
                    @media (min-width: 421px) {
                        display: none;
                    }
                }
            }
        }

        .article__progress {
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100%;
            z-index: 1;

            .progress {
                background: rgba(25, 22, 49, 0.745);
                backdrop-filter: blur(12px);
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 40px;
                width: 100%;
                padding-bottom: 10px;

                .progress__container {
                    width: 852px;
                    margin: 0 auto;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    @media (max-width: 768px) {
                        width: 100%;
                    }

                    .progress__line {

                        progress {
                            position: absolute;
                            width: 809px;
                            background: #000000;
                            height: 2px;

                            @media (max-width: 780px) {
                                width: 81%;
                                margin-left: 4%;
                            }

                            @media (max-width: 420px) {
                                width: 92%;
                            }
                        }

                        progress::-webkit-progress-bar {
                            background: transparent;
                        }

                        progress::-webkit-progress-value {
                            height: 2px;
                            background: linear-gradient(269.81deg, #8875FB 36.39%, #FC64FC 119.33%);
                            box-shadow: 0px 0px 6px #F283E0;
                            background-attachment: fixed;
                        }

                        progress::-moz-progress-bar {
                            height: 2px;
                            background: linear-gradient(269.81deg, #8875FB 36.39%, #FC64FC 119.33%);
                            box-shadow: 0px 0px 6px #F283E0;
                            background-attachment: fixed;
                        }
                    }

                    .progress__number {
                        margin-left: 15px;
                        font-family: 'Space Grotesk';
                        font-style: normal;
                        font-weight: 450;
                        font-size: 16px;
                        line-height: 116%;
                        text-align: center;
                        color: #8875FB;

                        @media (max-width: 768px) {
                            margin-right: 25px;
                        }

                        @media (max-width: 420px) {
                            margin-right: 14px;
                        }
                    }
                }
            }
        }
    }
}