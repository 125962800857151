.blog {

    .blog__block {
        margin-top: 170px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;

        @media (max-width: 768px) {
            margin-top: 140px;
        }

        .blog__name {
            margin-bottom: 10px;

            @media (max-width: 768px) {
                padding-left: 30px;
            }
        }

        .blog__title {
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 450;
            font-size: 50px;
            line-height: 116%;
            text-transform: uppercase;
            text-align: center;

            @media (max-width: 1440px) {
                font-weight: 450;
                font-size: 45px;
                line-height: 116%;
            }

            @media (max-width: 768px) {
                font-weight: 450;
                font-size: 32px;
                line-height: 116%;
            }
        }

        .blog__union {
            margin-top: 100px;
            display: flex;
            align-items: center;
            width: 1645px;

            @media (max-width: 1440px) {
                width: 1207px;
                margin-top: 60px;
            }

            @media (max-width: 768px) {
                width: 745px;
            }

            @media (max-width: 420px) {
                width: 100%;
                margin-top: 40px;
                flex-direction: column;
            }

            .blog__photo {
                position: relative;
                height: 410px;
                width: 750px;
                overflow: hidden;

                @media (max-width: 768px) {
                    height: 194px;
                    width: 340px;
                }

                @media (max-width: 420px) {
                    height: 188px;
                    width: 344px;
                    object-fit: cover;
                    margin-left: -15px;
                }

                @media (max-width: 375px) {
                    margin-left: 0;
                }

                @media (max-width: 420px) and (min-width: 414px) {
                    margin-left: -30px;
                }

                &::after {
                    position: absolute;
                    z-index: 111;
                    content: '';
                    left: -1px;
                    top: 0px;
                    height: 410px;
                    width: 750px;
                    background: url(../../assets/img/blog-border-main-photo.svg);
                    background-size: cover;

                    @media (max-width: 768px) {
                        height: 193px;
                        width: 344px;
                    }
                }

                img {
                    width: 724px;
                    height: 403px;
                    z-index: -1;
                    object-fit: cover;

                    @media (max-width: 768px) {
                        height: 188px;
                        width: 355px;
                    }

                    @media (max-width: 420px) {
                        width: 341px;
                        object-fit: cover;
                    }
                }
            }

            .blog__block_box {
                width: 584px;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                margin-left: 42px;
                cursor: pointer;

                @media (max-width: 1440px) {
                    width: 384px;
                }

                @media (max-width: 768px) {
                    margin-left: 32px;
                    width: 354px;
                }

                @media (max-width: 420px) {
                    width: 88%;
                    margin-left: 45px;
                    padding-right: 45px;
                    margin-top: 20px;
                }

                .blog__data {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: 'Space Grotesk';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 9px;
                    line-height: 126%;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    color: #FC64FC;

                    @media (max-width: 420px) {
                        display: none;
                    }

                    .blog__data_decor {
                        position: relative;
                        font-family: 'Space Grotesk';
                        font-style: normal;
                        font-weight: 300;
                        font-size: 9px;
                        line-height: 126%;
                        color: #FFFFFF;
                        margin-left: 15px;

                        &::after {
                            position: absolute;
                            content: "";
                            left: -9px;
                            top: 1px;
                            width: 1px;
                            height: 7px;
                            background: #676767;
                        }
                    }
                }

                .blog__title {
                    font-family: 'Space Grotesk';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 116%;
                    text-transform: none;
                    color: #ffffff;
                    margin-top: 18px;
                    transition: 0.5s;

                    @media (max-width: 1440px) {
                        font-weight: 700 !important;
                        font-size: 26px !important;
                        line-height: 116% !important;
                    }

                    @media (max-width: 420px) {
                        font-size: 19px !important;
                        margin-top: 3px;
                    }

                    @media (max-width: 420px) and (min-width: 414px) {
                        width: 330px;
                        text-align: left;
                    }
                }

                .blog__description {
                    margin-top: 11px;
                    text-align: left;
                    margin-bottom: 20px;

                    @media (max-width: 1440px) {
                        width: 363px;
                    }

                    @media (max-width: 768px) {
                        width: 277px;
                    }

                    @media (max-width: 420px) {
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 168%;
                        width: 100%;
                        margin-bottom: 7px;
                    }

                    @media (max-width: 420px) and (min-width: 414px) {
                        width: 330px;
                    }
                }

                .blog__button {
                    cursor: pointer;
                    transition: 0.5s;

                    @media (max-width: 420px) {
                        color: rgb(214, 111, 252) !important;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: currentcolor;
                        background-clip: text;
                    }

                    svg {
                        margin-left: 3px;
                    }

                    &:hover {
                        transition: 0.5s;

                        svg {
                            transition: 0.5s;
                            margin-left: 11px;
                        }
                    }
                }


                &:hover {
                    .blog__title {
                        color: #FC64FC;

                    }
                }
            }
        }
    }

    .blog__name_section {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 450;
        font-size: 20px;
        line-height: 126%;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        color: #FC64FC;
        width: 1645px;
        margin: 0 auto;
        margin-top: 90px;
        border-bottom: 1px solid rgba(136, 117, 251, 1);
        margin-bottom: 40px;
        padding-bottom: 15px;

        @media (max-width: 1440px) {
            width: 1170px;
        }

        @media (max-width: 768px) {
            width: 721px;
        }

        @media (max-width: 420px) {
            display: none;
        }
    }

    .blog__articles {
        display: flex;
        width: 1645px;
        margin: 0 auto;
        flex-wrap: wrap;

        @media (max-width: 1440px) {
            width: 1170px;
            flex-wrap: wrap;
        }

        @media (max-width: 768px) {
            width: 730px;
        }

        @media (max-width: 420px) {
            width: 88%;
            flex-direction: column;
            margin-top: 35px;
        }

        .blog__article {
            width: 363px;
            margin-bottom: 60px;
            margin-right: 64px;

            @media (max-width: 1440px) {
                margin-right: 39px;
            }

            &:nth-child(4n) {
                margin-right: 0;
            }

            @media (max-width: 1440px) {
                &:nth-child(3n) {
                    margin-right: 0;
                }

                &:nth-child(4n) {
                    margin-right: 39px;
                }
            }

            @media (max-width: 768px) {
                width: 229px;
                margin-right: 7px;
                margin-left: 6px;

                &:nth-child(3n) {
                    margin-right: 7px;
                }

                &:nth-child(4n) {
                    margin-right: 7px;
                }
            }

            @media (max-width: 420px) {
                margin-right: 0;
                margin-left: 0;
                width: 100%;
                margin-bottom: 35px;

                &:nth-child(3n) {
                    margin-right: 0;
                }

                &:nth-child(4n) {
                    margin-right: 0;
                }
            }
        }
    }
}