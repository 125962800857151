.buttonOffer {
    position: relative;
    transition: 0.5s;
    cursor: pointer;
    filter: drop-shadow(0px 0px 14px #B1354F);
    opacity: 0.9;
    margin-left: 4px;
    display: block;
    margin-top: 30px;

    @media (max-width: 768px) {
        margin-left: -1px;
        display: block;
        margin-top: 15px;
    }

    @media (max-width: 420px) {
        margin-left: 0;
        z-index: 1;
    }

    .buttonOffer__mask {
        position: relative;
        -webkit-mask-image: url(../../../assets/img/button-mask-offer1.svg);
        mask-image: url(../../../assets/img/button-mask-offer1.svg);
        -webkit-mask-repeat: round;
        mask-repeat: round;
        width: 205px;
        height: 60px;

        @media (max-width: 768px) {
            width: 148.49px;
            height: 40px;
        }

        @media (max-width: 420px) {
            -webkit-mask-image: url(../../../assets/img/button-mask-offer-mobile.svg);
            mask-image: url(../../../assets/img/button-mask-offer-mobile.svg);
            -webkit-mask-repeat: round;
            mask-repeat: round;
            width: 301px;
            height: 60px;
        }

        .color {
            background: url(../../../assets/img/button-mask-offer1.svg);
            width: 100%;
            height: 100%;

            @media (max-width: 420px) {
                background: url(../../../assets/img/button-mask-offer-mobile.svg);
            }

            .buttonOffer__text {
                font-family: "Space Grotesk";
                font-weight: 500;
                font-size: 15px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: #0D0E12;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 196px;
                height: 57px;
                padding-left: 18px;

                @media (max-width: 768px) {
                    font-size: 12px;
                    line-height: 15px;
                    width: 148.49px;
                    height: 40px;
                    padding-left: 6px;
                }

                @media (max-width: 420px) {
                    background: url(../../../assets/img/button-mask-offer-mobile.svg);
                    width: 301px;
                    height: 59px;
                    padding-left: 0;
                    font-size: 15px;
                    line-height: 19px;
                }
            }
        }

        .buttonOffer__line {
            position: absolute;
            left: 15%;
            transform: translate(-50%, 50%);
            top: 44%;
            background: #000000;
            width: 24px;
            height: 1px;
            z-index: 1;
            transition: 0.5s;

            @media (max-width: 768px) {
                top: 47%;
                width: 18px;
            }

            @media (max-width: 420px) {
                display: none;
            }
        }
    }

    &:hover {
        opacity: 1;
    }
}