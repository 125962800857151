.network {

    .network__name {
        margin-bottom: 10px;

        @media (max-width: 768px) {
            padding-left: 30px;
        }
    }

    .network__mail {
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
        line-height: 126%;
        text-transform: lowercase;
        color: #FFFFFF;
        margin-bottom: 60px;
        display: block;

        @media (max-width: 420px) {
            font-weight: 300;
            font-size: 24px;
            line-height: 126%;
        }
    }

    .socials {
        margin-top: 21px;
    }
}