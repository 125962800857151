.buttonDecor {
    position: relative;
    transition: 0.5s;
    cursor: pointer;
    margin-left: -8px;
    color: #0D0E12;
    filter: drop-shadow(0px 0px 14px #B1354F);
    opacity: 0.9;

    .buttonDecor__mask {
        position: relative;
        -webkit-mask-image: url(../../../assets/img/button-buttonDecor-mask.svg);
        mask-image: url(../../../assets/img/button-buttonDecor-mask.svg);
        -webkit-mask-repeat: round;
        mask-repeat: round;
        width: 204px;
        height: 59px;

        @media (max-width: 768px) {
            width: 137.89px;
            height: 40px;
        }

        @media (max-width: 420px) {
            -webkit-mask-image: url(../../../assets/img/button-buttonDecor-mask-mobile.svg);
            mask-image: url(../../../assets/img/button-buttonDecor-mask-mobile.svg);
            -webkit-mask-repeat: round;
            mask-repeat: round;
            width: 166px;
            height: 48px;
        }

        .color {
            background: url(../../../assets/img/button-buttonDecor-mask.svg);
            width: 100%;
            height: 100%;

            @media (max-width: 420px) {
                background: url(../../../assets/img/button-buttonDecor-mask-mobile.svg);
            }

            .buttonDecor__text {
                font-family: "Space Grotesk";
                font-weight: 500;
                font-size: 14px;
                line-height: 15px;
                display: inline-flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                justify-content: center;
                color: #0D0E12;
                width: 204px;
                height: 59px;

                @media (max-width: 768px) {
                    width: 137.89px;
                    height: 40px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                }

                @media (max-width: 420px) {
                    width: 166px;
                    height: 48px;
                    font-size: 14px;
                }
            }
        }
    }

    &:hover {
        opacity: 1;
    }
}

.buttonDecorLine {
    padding-left: 80px;
    padding-right: 10px;
}