.accordion__item {
    position: relative;
    cursor: pointer;
    width: 100%;
    margin-bottom: 47px;
    left: 0px;
    top: 0px;
    height: 100%;
    opacity: 0;

    @media (max-width: 768px) {
        margin: 0 auto;
        margin-bottom: 5px;
        width: 100%;
        background: transparent;
    }

    @media (max-width: 420px) {
        margin-bottom: 12px;
        width: 90%;
        height: 92%;
    }

    @media (max-width: 420px) and (min-width: 400px) {
        width: 90%;
    }

    .bottom {
        position: absolute;
        content: "";
        left: -6px;
        bottom: -10px;
        width: 1004px;
        height: 10px;
        background: url(../../assets/img/accordion-back.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        z-index: -1;
        transition: 0.5s;

        @media (max-width: 768px) {
            left: 0px;
            width: 656px;
            display: none;
        }

        @media (max-width: 420px) {
            left: 0px;
            width: 371px;
            bottom: -19px;
        }
    }

    .bottomActive {
        left: 0;
        width: 991px;

        @media (max-width: 768px) {
            left: 5px;
            width: 729px;
        }

        @media (max-width: 420px) {
            left: -1px;
            width: 371px;
            bottom: -10px;
        }
    }

    .accordion__title {
        position: relative;
        height: 54px;
        display: flex;
        align-items: center;
        padding: 0px 35px;
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        color: #FFFFFF;
        text-shadow: 0px 0px 11px rgba(136, 117, 251, 0.47);

        @media (max-width: 768px) {
            font-weight: 400;
            font-size: 17px;
            line-height: 139%;
            height: 70px;
            padding-right: 55px;
        }

        @media (max-width: 420px) {
            padding-left: 0;
            width: 312px;
            height: 80px;
        }

        .accordion__title_back {
            position: absolute;
            width: 950px;
            height: 75px;
            background: url(../../assets/img/accordion-back-title.svg);
            background-repeat: no-repeat;
            background-size: cover;

            @media (max-width: 768px) {
                width: 695px;
            }

            @media (max-width: 420px) {
                position: absolute;
                overflow-y: scroll;
                background: transparent;
                -webkit-mask-image: url(../../assets/img/accordion-back-title.svg);
                mask-image: url(../../assets/img/accordion-back-title.svg);
                -webkit-mask-repeat: round;
                mask-repeat: round;
                width: 373px;
                height: 100px;

                &::-webkit-scrollbar {
                    width: 0px;
                    background: transparent;
                    height: 0px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #EEEFF5;
                }
            }

            @media (max-width: 390px) {
                height: 90px;
                width: 350px;
            }

            @media (max-width: 375px) {
                width: 336px;
            }

            &::after {
                position: absolute;
                content: "";
                left: -5px;
                bottom: -20px;
                width: 985px;
                height: 20px;
                background: url(../../assets/img/accordion-back-title.svg);
                background-repeat: no-repeat;
                background-size: cover;
                background-position: bottom;

                @media (max-width: 768px) {
                    width: 710px;
                    left: 0px;
                }

                @media (max-width: 420px) {
                    display: none;
                }
            }

            .accordion__title_back-color {
                display: none;
                background: #221e39;
                width: 100%;
                height: 100%;

                @media (max-width: 420px) {
                    display: block;
                }
            }
        }

        .accordion__title_text {
            position: absolute;
            top: 15px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: flex;
            align-items: center;
            height: 45px;
            width: 835px;
            margin-left: 30px;
            overflow-x: scroll;
            white-space: nowrap;
            transition: 0.5s;

            &::-webkit-scrollbar {
                width: 0px;
                background: transparent;
                height: 0px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #EEEFF5;
            }

            @media (max-width: 768px) {
                margin-top: 4px;
                width: 602px;
            }

            @media (max-width: 420px) {
                position: absolute;
                top: 22px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                height: 45px;
                margin-top: -7px;
                margin-left: 25px;
                width: 287px;
                white-space: normal;
            }

            @media (max-width: 390px) {
                margin-left: 26px;
                width: 281px;
            }

            @media (max-width: 375px) {
                margin-left: 23px;
            }
        }

        .accordion__title_text_active {
            @media (max-width: 420px) {
                -webkit-line-clamp: inherit;
                height: auto;
                z-index: 111;
                transition: 0.5s;
            }
        }
    }

    .accordion__check {
        position: absolute;
        right: 0;
        top: 0;
    }

    .accordion__check-plus {
        position: absolute;
        right: 34px;
        top: 28px;
        width: 2px;
        height: 20px;
        background: linear-gradient(4.92deg, #8875FB 14.39%, #FC64FC 80.53%, #F79238 158.7%);

        @media (max-width: 768px) {
            right: 68px;
            top: 32px;
        }

        @media (max-width: 420px) {
            top: 27px;
            right: 25px;
        }

        @media (max-width: 390px) {
            right: 41px;
        }

        @media (max-width: 375px) {
            right: 53px;
        }

        span {
            position: absolute;
            right: 0px;
            top: -1px;
            width: 2px;
            height: 20px;
            background: linear-gradient(4.92deg, #8875FB 14.39%, #FC64FC 80.53%, #F79238 158.7%);
            transform: rotate(90deg);


            @media (max-width: 768px) {
                top: -1px;
            }

            @media (max-width: 420px) {
                top: 0;
            }
        }
    }

    .accordion__check-minus {
        position: absolute;
        right: 34px;
        top: 27px;
        width: 2px;
        height: 20px;
        background: linear-gradient(4.92deg, #8875FB 14.39%, #FC64FC 80.53%, #F79238 158.7%);
        transform: rotate(90deg);

        @media (max-width: 768px) {
            right: 68px;
            top: 31px;
        }

        @media (max-width: 420px) {
            right: 25px;
            top: 27px;
        }

        @media (max-width: 390px) {
            right: 41px;
        }

        @media (max-width: 375px) {
            right: 53px;
        }
    }

    .accordion__content {
        background: #221e39;
        position: relative;
        font-family: "Space Grotesk";
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 178%;
        color: transparent;
        height: 0;
        font-size: 15px;
        transition: all 0.5s;
        width: 950px;
        margin-left: -6px;
        margin-left: 35px;

        @media (max-width: 768px) {
            padding-bottom: 0;
            margin-top: -9px;
            margin-left: 8px;
            margin-left: 35px;
            width: 671px;
            margin-bottom: 37px;
        }

        @media (max-width: 420px) {
            background: #1a192e;
            width: 336px;
            padding-top: 0px;
            padding-bottom: 30px;
            margin-top: -9px;
            margin-left: 3px;
            padding-bottom: 0;
            padding-left: 23px;
        }

        @media (max-width: 390px) {
            margin-left: 11px;
            padding-left: 20px;
            width: 294px;
        }

        @media (max-width: 375px) {
            padding-left: 20px;
            margin-left: 4px;
            width: 294px;
        }
    }

    .accordion__content {
        .accordion__content_text {
            padding: 31px;
            padding-bottom: 5px;
            padding-right: 48px;
            will-change: transform;
            padding-top: 12px;

            @media (max-width: 768px) {
                padding: 28px;
                padding-top: 0px;
                padding-bottom: 10px;
                padding-right: 48px;
            }

            @media (max-width: 420px) {
                padding-bottom: 0px;
                padding-top: 10px;
                padding-left: 0;
                padding-right: 18px;
            }
        }
    }

    .accordion__content_box {
        height: auto;
        overflow-y: scroll;
        margin-top: 0px;

        &::-webkit-scrollbar {
            width: 0px;
            background: transparent;
            height: 0px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #EEEFF5;
        }
    }

    .accordion__content_all {
        position: relative;
        height: auto;
        color: rgba(255, 255, 255, 0.7);
        padding-bottom: 5px;
        margin-top: 0px;
        margin-bottom: 22px;

        @media (max-width: 768px) {
            height: auto;
            margin-bottom: 31px;
        }

        @media (max-width: 420px) {
            margin-bottom: 43px;
        }

        .accordion__content_text {

            @media (max-width: 768px) {
                height: auto;
                overflow-y: scroll;

                &::-webkit-scrollbar {
                    width: 0px;
                    background: transparent;
                    height: 0px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #EEEFF5;
                }
            }
        }

        @media (max-width: 768px) {
            &::after {
                height: 25px;
            }
        }

        .accordion__bottom-decor {
            position: absolute;
            content: "";
            left: 0px;
            bottom: -25px;
            width: 971px;
            height: 25px;
            background: url(../../assets/img/accordion-back-title.svg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: bottom;

            @media (max-width: 768px) {
                bottom: -25px;
                width: 678px;
            }


            @media (max-width: 420px) {
                position: absolute;
                content: "";
                left: 0px;
                bottom: -25px;
                background: transparent;
                -webkit-mask-image: url(../../assets/img/accordion-back-title.svg);
                mask-image: url(../../assets/img/accordion-back-title.svg);
                -webkit-mask-repeat: round;
                mask-repeat: round;
                width: 359px;
                height: 105px;
                z-index: -1;
            }

            @media (max-width: 390px) {
                width: 314px;
                height: 88px;
            }

            @media (max-width: 375px) {
                width: 314px;
                height: 88px;
            }

            .accordion__bottom-decor-color {
                display: none;

                @media (max-width: 420px) {
                    display: block;
                    background: #221e39;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.accordion__item_active {
    @media (max-width: 768px) {
        background-size: contain;
    }
}