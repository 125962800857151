.teamSection {
    margin-top: 185px;

    @media (max-width: 768px) {
        margin-bottom: 150px;
    }

    @media (max-width: 420px) {
        margin-top: 140px;
        margin-bottom: 100px;
    }

    .team {
        .team__header {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            flex-direction: column;
            margin-bottom: 90px;

            @media (max-width: 420px) {
                margin-bottom: 50px;
            }

            .team__name {
                text-align: center;
                opacity: 0;

                @media (max-width: 1440px) {
                    font-weight: 450;
                    font-size: 12px;
                    line-height: 126%;
                }

                @media (max-width: 768px) {
                    font-weight: 450;
                    font-size: 10px;
                    line-height: 126%;
                    padding-left: 30px;
                }

                @media (max-width: 420px) {
                    font-weight: 450;
                    font-size: 10px;
                    line-height: 126%;
                }
            }

            .team__title {
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 450;
                font-size: 50px;
                line-height: 116%;
                text-transform: uppercase;
                text-align: center;
                background: linear-gradient(246deg, #8875FB -0.58%, #FC64FC 44.41%, #FE9040 91.3%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                text-shadow: 0px 0px 8.15px rgb(136 117 251 / 34%);
                margin-top: 10px;
                height: 32px;

                @media (max-width: 1440px) {
                    font-weight: 450;
                    font-size: 45px;
                    line-height: 116%;
                }

                @media (max-width: 768px) {
                    font-weight: 450;
                    font-size: 28px;
                    line-height: 116%;
                }

                @media (max-width: 420px) {
                    font-weight: 450;
                    font-size: 32px;
                    line-height: 116%;
                }
            }

            .team__description {
                text-align: center;
                width: 623px;
                margin: 0 auto;
                margin-top: 38px;
                opacity: 0;

                @media (max-width: 1440px) {
                    font-weight: 300;
                    font-size: 15px;
                    line-height: 168%;
                }

                @media (max-width: 768px) {
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 168%;
                    margin-top: 28px;
                }

                @media (max-width: 420px) {
                    font-weight: 300;
                    font-size: 15px;
                    line-height: 168%;
                    width: 90%;
                }
            }
        }

        .team__box {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            width: 930px;
            margin: 0 auto;

            @media (max-width: 768px) {
                width: 600px;
            }

            @media (max-width: 420px) {
                width: 330px;
            }

            .team__block_dot {
                position: absolute;
                content: "";
                left: 455px;
                top: 105px;
                width: 27px;
                height: 20px;
                border-radius: 50%;
                background: url(../../assets/img/point-start.svg);
                z-index: 111;
                opacity: 0;

                @media (max-width: 768px) {
                    left: 270px;
                }

                @media (max-width: 420px) {
                    display: none;
                }
            }

            .team__block_line_left-first {
                position: absolute;
                content: "";
                left: 318px;
                top: 112px;
                width: 145px;
                height: 1px;
                background: linear-gradient(90.03deg, rgba(136, 117, 251, 0.5) -9.21%, rgba(136, 117, 251, 0) 116.27%);
                transition: 0.5s;
                z-index: -1;
                transform: rotate(180deg);
                animation: lineWidthFirstLeft 3s;
                display: none;

                @media (max-width: 768px) {
                    left: 203px;
                    top: 112px;
                    width: 80px;
                    display: none !important;
                }
            }


            @keyframes lineWidthFirstLeft {
                0% {
                    width: 0px;
                    left: 471px;
                }

                100% {
                    width: 145px;
                    left: 321px;
                }
            }


            .team__block_line_right-first {
                position: absolute;
                content: "";
                left: 473px;
                top: 112px;
                width: 485px;
                height: 1px;
                background: linear-gradient(90.03deg, rgba(136, 117, 251, 0.5) -9.21%, rgba(136, 117, 251, 0.5) 116.27%);
                transition: 0.5s;
                z-index: -1;
                transform: rotate(180deg);
                animation: lineWidthFirstRight 3s;
                display: none;

                @media (max-width: 768px) {
                    left: 282px;
                    width: 365px;
                    display: none !important;
                }
            }


            @keyframes lineWidthFirstRight {
                0% {
                    width: 0px;
                }

                100% {
                    width: 485px;
                }
            }

            .team__box_line {
                position: absolute;
                content: "";
                left: 468px;
                top: 118px;
                width: 1px;
                height: 94.4%;
                background: linear-gradient(90.03deg, rgba(136, 117, 251, 0.5) -9.21%, rgba(136, 117, 251, 0) 116.27%);
                opacity: 0;

                @media (max-width: 768px) {
                    left: 283px;
                }

                @media (max-width: 420px) {
                    display: none;
                }


                @keyframes lineHeight {
                    0% {
                        height: 0%;
                    }

                    100% {
                        height: 94.4%;
                    }
                }
            }

            .team__block {
                position: relative;
                width: 308px;
                height: 449.25px;
                margin-right: auto;
                opacity: 0;

                @media (max-width: 768px) {
                    width: 193.1px;
                    height: 281.66px;
                }

                @media (max-width: 420px) {
                    width: 163px;
                    height: 238px;
                    margin-bottom: 45px;
                }

                &:nth-of-type(1) {
                    .team__block_line {
                        display: none;

                        @media (max-width: 768px) {
                            display: block;
                            top: 112px;
                        }

                        @media (max-width: 420px) {
                            display: none;
                        }
                    }
                }

                .team__block_dot {
                    display: none;
                }

                .team__block_line {
                    position: absolute;
                    content: '';
                    right: -160px;
                    top: 110px;
                    position: absolute;
                    content: "";
                    right: -159px;
                    top: 110px;
                    width: 145px;
                    height: 1px;
                    background: linear-gradient(90.03deg, rgba(136, 117, 251, 0.5) -9.21%, rgba(136, 117, 251, 0) 116.27%);
                    z-index: -1;
                    transform: rotate(180deg);
                    animation: lineWidth 3s;
                }


                @keyframes lineWidth {
                    0% {
                        width: 0px;
                    }

                    100% {
                        width: 145px;
                    }
                }

                @media (max-width: 768px) {
                    .team__block_line {
                        right: -90px;
                    }
                }

                @media (max-width: 420px) {
                    .team__block_line {
                        right: -160px;
                    }
                }

                @media (max-width: 420px) {
                    &:nth-of-type(1) {

                        .team__block_line2 {
                            position: absolute;
                            content: '';
                            right: -165px;
                            top: 110px;
                            width: 161px;
                            height: 1px;
                            background: url(../../assets/img/line-2-tem-block.svg);
                            background-repeat: no-repeat;
                            background-size: cover;
                            z-index: -1;
                        }
                    }
                }

                &:nth-of-type(2n) {
                    margin-left: auto;
                    margin-right: 0;

                    .team__block_line2 {
                        position: absolute;
                        content: '';
                        left: -154px;
                        top: 110px;
                        width: 161px;
                        height: 1px;
                        background: url(../../assets/img/line-2-tem-block.svg);
                        background-repeat: no-repeat;
                        background-size: cover;
                        z-index: -1;

                        @media (max-width: 768px) {
                            left: -122px;
                        }
                    }

                    .team__block_line {
                        display: none;
                    }
                }

                .team__photo {
                    width: 333px;
                    height: 475px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 111;
                    background: url(../../assets/img/team-block-1920.svg);

                    @media (max-width: 768px) {
                        width: 207px;
                        height: 299px;
                        background: url(../../assets/img/team-block-tablet.svg);
                    }

                    @media (max-width: 420px) {
                        width: 175px;
                        height: 254px;
                        background: url(../../assets/img/team-block-mobile.svg);
                    }

                    &::before {
                        position: absolute;
                        content: '';
                        left: 0px;
                        top: 0px;
                        width: 333px;
                        height: 475px;
                        background: url(../../assets/img/team-shadow-1920.svg);
                        background-repeat: no-repeat;
                        background-size: cover;
                        mix-blend-mode: color-dodge;

                        @media (max-width: 768px) {
                            width: 207px;
                            height: 299px;
                            background: url(../../assets/img/team-shadow-tablet.svg);
                            background-repeat: no-repeat;
                            background-size: cover;
                        }

                        @media (max-width: 420px) {
                            width: 176px;
                            height: 254px;
                            background: url(../../assets/img/team-shadow-mobile.svg);
                            background-repeat: no-repeat;
                            background-size: cover;
                        }
                    }


                    img {
                        width: 374.4px;
                        height: 360px;
                        margin-top: -73px;
                        z-index: -1;
                        object-fit: cover;

                        @media (max-width: 768px) {
                            width: 179.31px;
                            height: 224.31px;
                            margin-top: -47px;
                        }

                        @media (max-width: 420px) {
                            width: 149px;
                            height: 233px;
                            margin-top: 4px;
                            object-position: 0px -11px;
                        }
                    }
                }

                .team__name {
                    position: absolute;
                    bottom: 26px;
                    left: 15px;
                    font-family: 'Space Grotesk';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 26px;
                    line-height: 126%;
                    text-align: center;
                    letter-spacing: -0.01em;
                    color: #FFFFFF;
                    text-shadow: 0px 0px 11px rgba(136, 117, 251, 0.27);
                    text-align: left;
                    padding-left: 14px;
                    margin-top: 5px;
                    width: 270px;
                    overflow-y: scroll;
                    white-space: nowrap;

                    @media (max-width: 768px) {
                        bottom: 19px;
                        left: 4px;
                    }

                    @media (max-width: 420px) {
                        font-size: 15px;
                        left: 8px;
                        bottom: 14px;
                    }

                    &::-webkit-scrollbar {
                        width: 0px;
                        background: transparent;
                        height: 0px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #EEEFF5;
                    }

                    @media (max-width: 768px) {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 126%;
                        margin-top: 3px;
                        width: 160px;
                        overflow-y: scroll;
                        white-space: nowrap;

                        &::-webkit-scrollbar {
                            width: 0px;
                            background: transparent;
                            height: 0px;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: #EEEFF5;
                        }
                    }

                    @media (max-width: 420px) {
                        padding-left: 11px;
                        width: 141px;
                        overflow-y: scroll;
                        white-space: nowrap;

                        &::-webkit-scrollbar {
                            width: 0px;
                            background: transparent;
                            height: 0px;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: #EEEFF5;
                        }
                    }
                }

                .team__position {
                    position: absolute;
                    bottom: 4px;
                    left: 15px;
                    font-family: 'Space Grotesk';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 126%;
                    letter-spacing: 0.2em;
                    text-transform: uppercase;
                    color: rgba(255, 255, 255, 0.6);
                    text-align: left;
                    padding-left: 14px;
                    margin-top: 10px;
                    width: 270px;
                    overflow-y: scroll;
                    white-space: nowrap;

                    @media (max-width: 768px) {
                        bottom: 4px;
                        left: 4px;
                    }

                    @media (max-width: 420px) {
                        bottom: -1px;
                    }

                    &::-webkit-scrollbar {
                        width: 0px;
                        background: transparent;
                        height: 0px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #EEEFF5;
                    }

                    @media (max-width: 768px) {
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 126%;
                        margin-top: 3px;
                        width: 160px;
                        overflow-y: scroll;
                        white-space: nowrap;

                        &::-webkit-scrollbar {
                            width: 0px;
                            background: transparent;
                            height: 0px;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: #EEEFF5;
                        }
                    }

                    @media (max-width: 420px) {
                        width: 141px;
                        overflow-y: scroll;
                        white-space: nowrap;

                        &::-webkit-scrollbar {
                            width: 0px;
                            background: transparent;
                            height: 0px;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: #EEEFF5;
                        }
                    }
                }
            }
        }
    }
}