.buttonMainHeader {
    position: relative;
    transition: 0.5s;
    cursor: pointer;
    opacity: 0.9;
    filter: drop-shadow(0px 0px 14px rgba(137, 30, 188, 0.64));
    display: block;

    .buttonMainHeader__mask {
        position: relative;
        -webkit-mask-image: url(../../../../assets/img/buttonMainHeader-mask.svg);
        mask-image: url(../../../../assets/img/buttonMainHeader-mask.svg);
        -webkit-mask-repeat: round;
        mask-repeat: round;
        width: 110px;
        height: 36px;

        .color {
            background: url(../../../../assets/img/buttonMainHeader-mask.svg);
            width: 100%;
            height: 100%;

            .buttonMainHeader__text {
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                display: inline-flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                justify-content: center;
                color: #0D0E12;
                width: 110px;
                height: 36px;
            }
        }
    }

    &:hover {
        opacity: 1;
    }
}