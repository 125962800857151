.aboutSection {
    position: relative;
    width: 100%;
    height: 800.33px;
    margin-top: -65px;
    padding-top: 20px;

    &::after {
        position: absolute;
        content: '';
        left: 0px;
        top: 0px;
        width: 100%;
        height: 910.33px;
        background: url(../../../../assets//img/bg_3.webp);
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -1;
    }

    @media (max-width: 768px) {
        height: 610.33px;
        background-position: center;
    }

    @media (max-width: 420px) {
        background: url(../../../../assets//img/about-section-mobile.svg);
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: -27px;
        height: auto;
        padding-top: 40px;
    }

    .aboutSection__union {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1640px;
        height: 763.33px;
        margin: 0 auto;
        z-index: 111;

        @media (max-width: 768px) {
            width: 650px;
            height: 610.33px;
        }

        @media (max-width: 420px) {
            flex-direction: column;
            width: 100%;
            height: auto;
            padding-top: 45px;
            padding-bottom: 30px;
        }

        .aboutSection__slider {
            width: 640px;
            height: 570px;
            margin-left: auto;
            margin-right: 10px;
            margin-top: 40px;

            @media (max-width: 1440px) {
                margin-right: 10px;
            }

            @media (max-width: 768px) {
                width: 340px;
                height: 370px;
                margin-left: auto;
                margin-right: 70px;
                margin-top: -68px;
            }

            @media (max-width: 420px) {
                margin-right: 0;
                margin-top: -55px;
                margin-left: 30px;
                order: 2;
                width: 340px;
            }

            .slider__photos {
                position: relative;

                .slider__photo {
                    height: 425px;
                    width: 367px;

                    &::after {
                        position: absolute;
                        content: '';
                        left: 50%;
                        transform: translateX(-50%);
                        top: 0px;
                        background: url(../../../../assets/img/about-border-main.svg);
                        background-size: cover;
                        background-repeat: no-repeat;
                        height: 425px;
                        width: 367px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 1;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .slider__photo_left {
                    position: absolute;
                    top: 164px;
                    left: 121px;
                    opacity: 0.2;
                    width: 245.65px;
                    height: 287.74px;

                    @media (max-width: 768px) {
                        top: 57px;
                        left: 121px;
                        width: 135.65px;
                    }

                    &::after {
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        top: 0px;
                        background: url(../../../../assets/img/about-border-main.svg);
                        background-size: cover;
                        background-repeat: no-repeat;
                        width: 245.65px;
                        height: 287.74px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 1;

                        @media (max-width: 768px) {
                            width: 120.88px;
                            height: 141.59px;
                            top: 74px;
                        }
                    }

                    img {
                        position: absolute;
                        left: 51%;
                        transform: translate(-50%, -50%);
                        top: 50%;
                        width: 229px;
                        height: 270px;
                        z-index: 1;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 25px;
                        border-bottom-right-radius: 32px;
                        border-bottom-left-radius: 10px;

                        @media (max-width: 768px) {
                            width: 113.88px;
                            height: 132.59px;
                            border-top-left-radius: 4px;
                            border-top-right-radius: 13px;
                            border-bottom-right-radius: 12px;
                            border-bottom-left-radius: 5px;
                        }
                    }
                }

                .slider__photo_left_active {
                    transition: 1s;
                    transform: translate3d(80.6482px, 17.0755px, 0px) rotate(22deg) skew(-0.000286479deg, 0deg);
                    left: 190px;

                    @media (max-width: 768px) {
                        left: 98px;
                    }
                }

                .slider__photo_main {
                    position: absolute;
                    top: 70px;
                    left: 70px;
                    width: 337.5px;
                    height: 395.57px;
                    animation-duration: 3s;

                    @media (max-width: 768px) {
                        width: 185.5px;
                        top: 0;
                    }

                    &::after {
                        position: absolute;
                        content: '';
                        left: 50%;
                        transform: translateX(-50%);
                        top: 0px;
                        background: url(../../../../assets/img/about-border-main.svg);
                        background-size: cover;
                        background-repeat: no-repeat;
                        width: 337.5px;
                        height: 395.57px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 1;

                        @media (max-width: 768px) {
                            width: 165.46px;
                            height: 193.91px;
                            top: 101px;
                        }

                        @media (max-width: 420px) {
                            width: 165.46px;
                            height: 193.91px;
                            top: 92px;
                        }
                    }

                    .slider__photo_main_mask {
                        position: relative;
                        -webkit-mask-image: url(../../../../assets/img/slider-photo-main-mask.svg);
                        mask-image: url(../../../../assets/img/slider-photo-main-mask.svg);
                        -webkit-mask-repeat: round;
                        mask-repeat: round;
                        width: 315.5px;
                        height: 397.57px;
                        margin-left: 14px;
                        z-index: 1;

                        @media (max-width: 768px) {
                            width: 153.46px;
                            height: 193.91px;
                            margin-top: 102px;
                            margin-left: 17px;
                        }

                        @media (max-width: 420px) {
                            height: 178.91px;
                            margin-top: 100px;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            z-index: 1;

                            @media (max-width: 420px) {
                                z-index: 1;
                                object-fit: cover;
                            }
                        }
                    }
                }

                .slider__photo_right {
                    position: absolute;
                    top: 153px;
                    right: 117px;
                    opacity: 0.17;
                    width: 255.28px;
                    height: 299.01px;
                    animation-duration: 3s;

                    @media (max-width: 768px) {
                        top: 70px;
                        right: 91px;
                        opacity: 0.17;
                        width: 145.28px;
                    }

                    &::after {
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        top: 0px;
                        background: url(../../../../assets/img/about-border-main.svg);
                        background-size: cover;
                        background-repeat: no-repeat;
                        width: 255.28px;
                        height: 299.01px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 1;

                        @media (max-width: 768px) {
                            width: 125.15px;
                            height: 146.67px;
                            top: 78px;
                        }
                    }

                    img {
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        top: 50%;
                        width: 240px;
                        height: 277px;
                        z-index: 1;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 25px;
                        border-bottom-right-radius: 26px;
                        border-bottom-left-radius: 10px;

                        @media (max-width: 768px) {
                            width: 117.15px;
                            height: 137.67px;
                            border-top-left-radius: 10px;
                            border-top-right-radius: 25px;
                            border-bottom-right-radius: 11px;
                            border-bottom-left-radius: 10px;
                        }
                    }
                }

                .slider__photo_right_active {
                    transition: 1s;
                    transform: translate3d(80.6482px, 17.0755px, 0px) rotate(22deg) skew(-0.000286479deg, 0deg);
                    right: 25px;
                    top: 153px;

                    @media (max-width: 768px) {
                        top: 45px;
                        right: 75px;
                    }
                }

                .slider__photo_right_box {
                    transform: scaleX(-1);
                }
            }

            .slider-aboutSection {
                opacity: 0;
            }
        }

        .aboutSection__block {
            width: 50%;

            @media (max-width: 420px) {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
            }

            .block {
                .block__name {
                    opacity: 0;

                    @media (max-width: 768px) {
                        padding-left: 30px;
                    }
                }

                .block__title {
                    font-family: 'Space Grotesk';
                    font-style: normal;
                    font-weight: 450;
                    font-size: 45px;
                    line-height: 116%;
                    width: 444px;
                    margin-top: 18px;
                    height: 104px;

                    @media (max-width: 1440px) {
                        font-weight: 450 !important;
                        font-size: 45px !important;
                        line-height: 116% !important;
                    }

                    @media (max-width: 768px) {
                        font-weight: 450 !important;
                        font-size: 28px !important;
                        line-height: 116% !important;
                        width: 263px;
                        height: 65px;
                    }

                    @media (max-width: 420px) {
                        font-weight: 450 !important;
                        font-size: 32px !important;
                        line-height: 120% !important;
                        width: 301px;
                        text-align: center;
                        height: 78px;
                    }

                    #interText__information {
                        @media (max-width: 768px) {
                            color: rgb(214, 111, 252) !important;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: currentcolor;
                            background-clip: text;
                        }
                    }
                }

                .block__description {
                    margin-top: 15px;
                    width: 487px;
                    opacity: 0;

                    @media (max-width: 768px) {
                        width: 263px;
                    }

                    @media (max-width: 420px) {
                        width: 301px;
                        text-align: center;
                    }
                }

                .block__button {
                    opacity: 0;
                    margin-top: 5px;

                    @media (max-width: 420px) {
                        margin-top: 27px;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}