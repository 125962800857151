.buttonProductMint {
    position: relative;
    font-family: "Space Grotesk";
    font-weight: 500;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    width: 440px;
    height: 86px;
    background: url(../../../assets/img/buttonProductMint.svg);
    background-repeat: no-repeat;
    background-position: 0px 0px;
    transition: 0.5s;
    cursor: pointer;
    color: #0D0E12;
    margin-left: -14px;

    @media (max-width: 768px) {
        width: 390px;
        height: 86px;
        background: url(../../../assets/img/buttonProductMint-tablet.svg);
        background-repeat: no-repeat;
        background-position: 0px 0px;
    }

    @media (max-width: 420px) {
        width: 346px;
        height: 86px;
        background: url(../../../assets/img/buttonProductMint-mobile.svg);
        background-repeat: no-repeat;
        background-position: 0px 0px;
    }

    &::before {
        position: absolute;
        content: "";
        left: 0px;
        top: 0px;
        width: 440px;
        height: 86px;
        background: url(../../../assets/img/buttonProductMint-gradient.svg);
        background-repeat: no-repeat;
        background-size: contain;
        mix-blend-mode: color-dodge;
        opacity: 0;
        transition: 0.5s;

        @media (max-width: 768px) {
            width: 390px;
            height: 86px;
            background: url(../../../assets/img/buttonProductMint-gradient-tablet.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }

        @media (max-width: 420px) {
            width: 346px;
            height: 86px;
            background: url(../../../assets/img/buttonProductMint-gradient-mobile.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    span {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    &:hover {
        &::before {
            transition: 0.5s;
            opacity: 0.8;
        }
    }
}