.video-section {
    background: url(../../../../assets/img/video-back.svg);
    background-position: center;
    background-size: cover;
    height: 795px;
    padding-top: 100px;

    @media (max-width: 1440px) {
        height: 695px;
    }

    @media (max-width: 768px) {
        height: 374px;
        padding-top: 58px;
    }

    @media (max-width: 420px) {}

    .video__title {
        margin-bottom: 40px;
        text-align: center;

        @media (max-width: 768px) {
            margin-bottom: 31px;
        }
    }

    .video__block {
        display: flex;
        justify-content: center;
        width: 1248.77px;
        height: 622px;
        margin: 0 auto;
        opacity: 0;

        @media (max-width: 1440px) {
            width: 1004.81px;
            height: 499.48px;
        }

        @media (max-width: 768px) {
            width: 535.9px;
            height: 266.39px;
        }

        @media (max-width: 420px) {
            width: 343px;
            height: 181.02px;
        }

        .video {
            .video__preloader {
                position: relative;
                width: 1248.77px;
                height: 622px;
                background: url(../../../../assets/img/preloader-video-new.png);
                background-position: center;
                background-size: cover;

                @media (max-width: 1440px) {
                    width: 1004.81px;
                    height: 499.48px;
                }

                @media (max-width: 768px) {
                    width: 535.9px;
                    height: 266.39px;
                }

                @media (max-width: 420px) {
                    width: 343px;
                    height: 181.02px;
                    background-size: contain;
                    background: url(../../../../assets/img/video-mobile.png);
                    background-position: center;
                    background-size: cover;
                }

                .video__play {
                    background: url(../../../../assets/img/play-button.svg);
                    background-position: center;
                    background-size: cover;
                    width: 93px;
                    height: 93px;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    top: 35%;
                    opacity: 0.8;
                    transition: 0.5s;
                    cursor: pointer;
                    filter: drop-shadow(0px 0px 14px #B1354F);

                    @media (max-width: 768px) {
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        top: 33%;
                    }

                    @media (max-width: 420px) {
                        top: 27%;
                    }

                    &:hover {
                        opacity: 1;
                    }
                }
            }

            .video__box {
                width: 1248.77px;
                height: 622px;
                border: 1px solid rgb(175, 49, 49);
                background: #181629;

                @media (max-width: 1440px) {
                    width: 1004.81px;
                    height: 499.48px;
                }

                @media (max-width: 768px) {
                    width: 535.9px;
                    height: 266.39px;
                }

                @media (max-width: 768px) {
                    width: 343px;
                    height: 191.02px;
                }

                video {
                    width: 1248.77px;
                    height: 622px;

                    @media (max-width: 1440px) {
                        width: 1004.81px;
                        height: 499.48px;
                    }


                    @media (max-width: 768px) {
                        width: 535.9px;
                        height: 266.39px;
                    }

                    @media (max-width: 768px) {
                        width: 343px;
                        height: 191.02px;
                    }
                }

                frame {
                    width: 1248.77px;
                    height: 622px;

                    @media (max-width: 1440px) {
                        width: 1004.81px;
                        height: 499.48px;
                    }

                    @media (max-width: 768px) {
                        width: 535.9px;
                        height: 266.39px;
                    }

                    @media (max-width: 768px) {
                        width: 343px;
                        height: 191.02px;
                    }
                }
            }
        }
    }
}