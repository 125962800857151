.productPage {
    width: 860px;
    margin: 0 auto;
    margin-top: 210px;

    @media (max-width: 768px) {
        width: 100%;
    }

    @media (max-width: 420px) {
        margin-top: 140px;
        margin-bottom: 150px;
    }

    .product {
        .product__title {
            text-align: center;
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 450;
            font-size: 50px;
            line-height: 116%;
            text-transform: uppercase;

            @media (max-width: 1440px) {
                font-weight: 450;
                font-size: 45px;
                line-height: 116%;
            }

            @media (max-width: 768px) {
                font-weight: 450;
                font-size: 32px;
                line-height: 116%;
            }

            @media (max-width: 420px) {
                font-weight: 450;
                font-size: 30px;
                line-height: 116%;
            }
        }

        .product__union {
            display: flex;
            margin-top: 70px;
            align-items: center;

            @media (max-width: 768px) {
                justify-content: center;
            }

            @media (max-width: 420px) {
                flex-direction: column;
                align-items: center;
                margin-top: 30px;
            }

            .product__photo {
                position: relative;
                width: 447px;
                height: 619px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: -30px;

                @media (max-width: 1440px) {
                    margin-top: -20px;
                    width: 372px;
                    height: 515px;
                }

                @media (max-width: 768px) {
                    width: 310px;
                    height: 428px;
                }

                @media (max-width: 420px) {
                    width: 218px;
                    height: 300px;
                }

                &::before {
                    position: absolute;
                    content: "";
                    left: -22px;
                    top: 10px;
                    width: 447px;
                    height: 619px;
                    background: url(../../../assets/img/product-back-shape.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                    z-index: 1;

                    @media (max-width: 1440px) {
                        left: -8px;
                        top: 10px;
                        width: 372px;
                        height: 515px;
                        background: url(../../../assets/img/product-back-shape-1440.svg);
                        background-repeat: no-repeat;
                        background-size: cover;
                    }

                    @media (max-width: 768px) {
                        left: -2px;
                        top: 9px;
                        width: 310px;
                        height: 428px;
                        background: url(../../../assets/img/product-back-shape-768.svg);
                        background-repeat: no-repeat;
                        background-size: cover;
                    }

                    @media (max-width: 420px) {
                        top: 5px;
                        width: 218px;
                        height: 300px;
                        background: url(../../../assets/img/product-back-shape-mobile.svg);
                        background-repeat: no-repeat;
                        background-size: cover;
                    }
                }

                img {
                    z-index: -1;
                    width: 400px;
                    height: 545px;
                    overflow: hidden;
                    object-fit: cover;
                    object-position: center;

                    @media (max-width: 1440px) {
                        width: 325px;
                        height: 460px;
                    }

                    @media (max-width: 768px) {
                        width: 265px;
                        height: 380px;
                    }

                    @media (max-width: 420px) {
                        width: 185px;
                        height: 274px;
                    }
                }
            }

            .product__block {
                margin-left: 70px;

                @media (max-width: 768px) {
                    margin-left: 35px;
                }

                @media (max-width: 420px) {
                    margin-left: 0;
                    margin-top: 40px;
                    width: 90%;
                }

                .product__name {
                    font-family: 'Space Grotesk';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 40px;
                    line-height: 116%;
                    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(253.71deg, #FFFFFF 16.39%, #F79238 59.3%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;

                    @media (max-width: 768px) {
                        font-weight: 500;
                        font-size: 30px;
                        line-height: 116%;
                    }

                    @media (max-width: 420px) {
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 116%;
                    }
                }

                .product__row {
                    position: relative;
                    width: 396px;
                    background: #292934;
                    height: 1px;
                    margin-top: 40px;

                    @media (max-width: 420px) {
                        width: 100%;
                        margin-top: 30px;
                    }

                    .product__row_line-active {
                        position: relative;
                        width: 30%;
                        height: 2px;
                        background: #EE6AEE;
                    }

                    .product__row_name {
                        position: absolute;
                        right: 2px;
                        top: -24px;
                        font-family: "Space Grotesk";
                        display: flex;
                        align-items: center;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 138%;
                        text-align: center;
                        color: #FFFFFF;

                        @media (max-width: 768px) {
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 138%;
                        }

                        .product__row_name_line {
                            margin: 0 4px;
                        }
                    }
                }

                .product__count {
                    display: flex;
                    align-items: center;
                    margin-top: 50px;
                    margin-bottom: 27px;

                    @media (max-width: 420px) {
                        margin-top: 40px;
                        justify-content: center;
                        margin-bottom: 30px;
                    }

                    .product__count_block {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        background: rgba(136, 117, 251, 0.04);
                        border-radius: 50px;
                        width: 156px;

                        .product__count_mine {
                            position: relative;
                            background: #2B2748;
                            width: 36px;
                            height: 36px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            color: #FFFFFF;
                            cursor: pointer;
                            opacity: 0.8;
                            transition: 0.5s;

                            &::after {
                                position: absolute;
                                content: "";
                                left: 12px;
                                top: 18px;
                                width: 11px;
                                height: 1px;
                                background: url(../../../assets//img/minus.svg);
                                background-repeat: no-repeat;
                                background-size: cover;
                            }

                            &:hover {
                                opacity: 1;
                            }
                        }

                        .product__count_number {
                            font-family: 'Space Grotesk';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 138%;
                            text-align: center;
                            color: #FFFFFF;

                            @media (max-width: 768px) {
                                font-weight: 700;
                                font-size: 18px;
                                line-height: 138%;
                            }

                            @media (max-width: 420px) {
                                font-weight: 500;
                                font-size: 18px;
                                line-height: 138%;
                            }
                        }

                        .product__count_plus {
                            position: relative;
                            background: #2B2748;
                            width: 36px;
                            height: 36px;
                            display: flex;
                            color: #FFFFFF;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            cursor: pointer;
                            opacity: 0.8;
                            transition: 0.5s;

                            &::after {
                                position: absolute;
                                content: "";
                                left: 13px;
                                top: 13px;
                                width: 11px;
                                height: 11px;
                                background: url(../../../assets//img/plus.svg);
                                background-repeat: no-repeat;
                                background-size: cover;
                            }

                            &:hover {
                                opacity: 1;
                            }
                        }
                    }

                    .product__count_eth {
                        position: relative;
                        font-family: 'Space Grotesk';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 138%;
                        text-align: center;
                        color: #FFFFFF;
                        margin-left: 65px;

                        @media (max-width: 420px) {
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 138%;
                        }

                        &::after {
                            position: absolute;
                            content: "";
                            left: -34px;
                            top: -2px;
                            width: 27px;
                            height: 27px;
                            background: url(../../../assets//img/eth.svg);
                            background-repeat: no-repeat;
                            background-size: cover;

                            @media (max-width: 420px) {
                                left: -25px;
                                top: -1px;
                                width: 19px;
                                height: 19px;
                            }
                        }
                    }
                }

                .product__mint {
                    @media (max-width: 420px) {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                .product__connect {
                    margin-top: -30px;

                    @media (max-width: 420px) {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: -20px;
                    }
                }
            }
        }
    }

    .interText {
        .interText__line {
            left: 0;
        }
    }
}