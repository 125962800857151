.offer {
  position: relative;
  z-index: 111;
  height: 725px;
  transform: scale(1.051111111111);

  @media (max-width: 768px) {
    height: auto;
  }

  .offer__back {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 868px;
    background: url(../../../../assets/img/hero.jpg);
    background-repeat: no-repeat;
    background-position: 0px -145px;
    background-size: cover;
    z-index: -1;

    @media (min-width: 1600px) and (max-width: 1650px) and (max-height: 1085px) {
      height: 100vh;
      background-position: -55px -10px;
    }

    @media (max-width: 1440px) {
      background-repeat: no-repeat;
      background-position: 0px -95px;
      height: 724.5px;
    }

    @media (max-width: 768px) {
      background-repeat: no-repeat;
      background-position: center;
      height: 386.13px;
    }

    @media (max-width: 420px) {
      background: #0b0715 url(../../../../assets/img/offer-mobile-new.jpg);
      background-repeat: no-repeat;
      background-position: top;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      background-size: cover;
      background-position: 0px 38px;
    }

    // iPhone 13 Pro Max, iPhone 12 Pro Max
    @media (max-width: 428px) and (max-height: 926px) {
      height: 926px;
    }

    // iPhone 11 Pro Max, iPhone XS Max iPhone 11, iPhone XR
    @media (max-width: 414px) and (max-height: 896px) {
      height: 896px;
    }

    // iPhone 13 Pro, iPhone 13, iPhone 12 Pro,  iPhone 12
    @media (max-width: 390px) and (max-height: 844px) {
      height: 844px;
    }

    // iPhone 11 Pro,  iPhone XS,  iPhone X iPhone 13 mini, iPhone 12 mini
    @media (max-width: 375px) and (max-height: 812px) {
      height: 812px;
    }

    // iPhone 8 Plus, iPhone 7 Plus, iPhone 6S Plus
    @media (max-width: 414px) and (max-height: 736px) {
      height: 736px;
    }

    // iPhone SE (2-го поколения), iPhone 8,  iPhone 7,  iPhone 6S
    @media (max-width: 375px) and (max-height: 667px) {
      height: 667px;
    }

    // iPhone SE (1-го поколения),  iPod Touch (7-го поколения)
    @media (max-width: 320px) and (max-height: 568px) {
      height: 568px;
    }
  }

  .offer__back-color {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 11;
    width: 100%;
    background: #10101c;
    opacity: 0;
    display: none;
    height: 868px;

    @media (min-width: 1600px) and (max-width: 1650px) and (max-height: 1085px) {
      height: 100vh;
    }

    @media (max-width: 1440px) {
      height: 725px;
    }

    @media (max-width: 768px) {
      display: none !important;
    }
  }

  .offer__exit {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .offer__union {
    position: relative;
    display: flex;
    width: 100%;
    height: 865px;
    align-items: center;

    &::after {
      position: absolute;
      content: '';
      left: 0px;
      bottom: -5px;
      width: 100%;
      height: 373px;
      background: url(../../../../assets/img/gradient.svg);
      background-repeat: no-repeat;
      background-size: cover;
    }

    @media (max-width: 1440px) {
      height: 725px;
    }

    @media (min-width: 1600px) and (max-width: 1650px) and (max-height: 1085px) {
      height: 100vh;
    }

    @media (max-width: 768px) {
      height: 386.13px;
    }

    @media (max-width: 420px) {
      height: 730px;
      margin-top: -10%;
    }

    // iPhone 13 Pro Max, iPhone 12 Pro Max
    @media (max-width: 428px) and (max-height: 926px) {
      height: 926px;
    }

    // iPhone 11 Pro Max, iPhone XS Max iPhone 11, iPhone XR
    @media (max-width: 414px) and (max-height: 896px) {
      height: 896px;
    }

    // iPhone 13 Pro, iPhone 13, iPhone 12 Pro,  iPhone 12
    @media (max-width: 390px) and (max-height: 844px) {
      height: 844px;
    }

    // iPhone 11 Pro,  iPhone XS,  iPhone X iPhone 13 mini, iPhone 12 mini
    @media (max-width: 375px) and (max-height: 812px) {
      height: 812px;
    }

    // iPhone 8 Plus, iPhone 7 Plus, iPhone 6S Plus
    @media (max-width: 414px) and (max-height: 736px) {
      height: 736px;
    }

    // iPhone SE (2-го поколения), iPhone 8,  iPhone 7,  iPhone 6S
    @media (max-width: 375px) and (max-height: 667px) {
      height: 667px;
    }

    // iPhone SE (1-го поколения),  iPod Touch (7-го поколения)
    @media (max-width: 320px) and (max-height: 568px) {
      height: 568px;
    }

    .offer__block {
      position: relative;
      width: 50%;
      margin-left: 7%;
      z-index: 111;

      @media (max-width: 768px) {
        height: 386.13px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      @media (max-width: 420px) {
        position: relative;
        height: auto;
        width: 100%;
        align-items: center;
        margin-left: 0;
        margin-top: auto;
        margin-bottom: 155px;
      }

      .interText {
        #interText__information_text {
          margin-top: 0;
          background: transparent;
          color: #fff;
          -webkit-background-clip: text;
          -webkit-text-fill-color: #fff;
          background-clip: text;
          text-fill-color: #fff;
        }

        #console-offerFirst {
          background: #ffffff;
        }
      }

      .offer__title {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 450;
        font-size: 58px;
        line-height: 120%;
        color: #ffffff;
        width: 428px;
        margin-top: -25px;
        margin-bottom: 15px;
        height: 194px;
        opacity: 0;

        @media (max-width: 1440px) {
          font-weight: 450;
          font-size: 48px;
          line-height: 126%;
          width: 375px;
          margin-bottom: -10px;
          margin-top: -15px;
          height: 190px;
        }

        @media (max-width: 768px) {
          font-style: normal;
          font-weight: 450;
          font-size: 28px;
          line-height: 117%;
          text-transform: none;
          color: #ffffff;
          margin-top: 18px;
          margin-bottom: 5px;
          width: 205px;
          height: 95px;
        }

        @media (max-width: 420px) {
          font-weight: 597;
          font-size: 37px;
          line-height: 114%;
          text-align: center;
          width: 290px;
          height: 140px;
          margin-top: 0px;
        }
      }

      .offer__title_mark {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 450;
        font-size: 56px;
        line-height: 116%;
        text-transform: uppercase;
        background: radial-gradient(
            34.62% 34.62% at 23.42% 85.58%,
            #f79238 0%,
            rgba(247, 146, 56, 0) 100%
          ),
          linear-gradient(274.2deg, #9b93ff 7.31%, #8875fb 22.15%, #fc64fc 61.25%, #fe9040 110.3%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        display: block;
        margin-bottom: 8px;

        @media (max-width: 1440px) {
          font-weight: 450;
          font-size: 48px;
          line-height: 126%;
          width: 495px;
          display: block;
          margin-top: 7px;
          margin-bottom: -12px;
        }

        @media (max-width: 768px) {
          font-weight: 450;
          font-size: 28px;
          line-height: 116%;
          margin-bottom: 0px;
        }

        @media (max-width: 420px) {
          font-weight: 597;
          font-size: 35px;
          line-height: 116%;
          text-align: center;
          width: 100%;
          margin-bottom: 1px;
          margin-top: -14px;
        }
      }

      .offer__button {
        opacity: 0;
      }

      .offer__button_active {
        opacity: 1;
      }

      .offer__button_arrow {
        @media (max-width: 421px) {
          position: absolute;
          content: '';
          left: 50%;
          transform: translateX(-50%);
          bottom: -68px;
          width: 25px;
          height: 30px;
          background: url(../../../../assets//img/arrow-scroll.svg);
          background-repeat: no-repeat;
          background-size: cover;
          animation: animate-home 2s infinite;
          opacity: 0;

          @keyframes animate-home {
            0% {
              bottom: -68px;
            }

            50% {
              bottom: -48px;
            }

            100% {
              bottom: -68px;
            }
          }
        }
      }
    }
  }
}
