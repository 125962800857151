.statistics {
  background: url(../../../../assets/img/bg_3.webp);
  background-size: cover;
  background-repeat: no-repeat;
  height: 925px;
  padding-top: 50px;

  @media (max-width: 1440px) {
    height: 790px;
    padding-top: 60px;
  }

  @media (max-width: 768px) {
    height: auto;
    padding-top: 60px;
    padding-bottom: 50px;
  }

  @media (max-width: 420px) {
    padding-top: 21px;
    background-position: center;
  }

  .statistics__union {
    display: flex;
    width: 1470px;
    margin: 0 auto;
    height: 705px;
    align-items: center;

    @media (max-width: 1480px) {
      width: 100%;
      margin: 0px 110px;
      height: auto;
    }

    @media (max-width: 768px) {
      margin: 0 60px;
    }

    @media (max-width: 420px) {
      justify-content: center;
      flex-direction: column;
      margin: 0 auto;
    }

    .statistics__block {
      @media (max-width: 1440px) {
        width: 503px;
      }

      @media (max-width: 768px) {
        width: 300px;
      }

      @media (max-width: 420px) {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: -10px;
      }

      .statistics__name_box {
        @media (max-width: 1440px) {
          height: 25px;
        }

        @media (max-width: 768px) {
          padding-left: 30px;
        }

        @media (max-width: 420px) {
          text-align: center;
          margin: 0 auto;
          display: block;
          padding-left: 30px;
          margin-bottom: 10px;
        }

        .statistics__name {
          opacity: 0;

          @media (max-width: 768px) {
            &::after {
              left: -26px;
            }
          }
        }
      }

      .statistics__title {
        background: linear-gradient(98.71deg, #8875fb -0.58%, #fc64fc 44.41%, #fe9040 91.3%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        text-shadow: 0px 0px 8.15px rgba(136, 117, 251, 0.34);
        width: 444px;
        height: 116px;

        @media (max-width: 1440px) {
          height: 104px;
        }

        @media (max-width: 768px) {
          width: 225px;
          height: 60px;
        }

        @media (max-width: 420px) {
          width: 301px;
          text-align: center;
          height: 76px;
        }
      }

      .statistics__description_box {
        height: 84px;
        width: 503px;
        margin-top: 15px;

        @media (max-width: 420px) {
          margin: 20px auto;
          margin-bottom: 30px;
        }

        .statistics__description {
          font-family: 'Space Grotesk';
          font-style: normal;
          font-weight: 300;
          font-size: 15px;
          line-height: 188%;
          color: #ffffff;
          opacity: 0;
          transform: translate3d(0px, -30px, 0px);
          will-change: transform;

          @media (max-width: 768px) {
            width: 263px;
          }

          @media (max-width: 420px) {
            margin-right: 0;
            text-align: center;
            width: 301px;
          }
        }
      }

      .statistics__button_box {
        margin-top: 15px;
        height: 69px;
        opacity: 0;

        @media (max-width: 768px) {
          margin-top: 65px;
        }

        @media (max-width: 420px) {
          margin-top: 65px;
        }

        .statistics__button {
        }
      }
    }

    .statistics__slider {
      width: 640px;
      height: 570px;
      margin-left: auto;
      margin-right: 10px;

      @media (max-width: 1440px) {
        margin-right: 115px;
      }

      @media (max-width: 768px) {
        width: 340px;
        height: 370px;
        margin-left: auto;
        margin-right: 70px;
        margin-top: -68px;
      }

      @media (max-width: 420px) {
        margin-right: 0;
        margin-top: -55px;
        margin-left: 30px;
        order: 2;
        width: 340px;
      }

      .slider__photos {
        position: relative;
        opacity: 0;

        .slider__photo {
          height: 425px;
          width: 367px;

          &::after {
            position: absolute;
            content: '';
            left: 50%;
            transform: translateX(-50%);
            top: 0px;
            background: url(../../../../assets/img/about-border-main.svg);
            background-size: cover;
            background-repeat: no-repeat;
            height: 425px;
            width: 367px;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }

        .slider__photo_left {
          position: absolute;
          top: 164px;
          left: 121px;
          opacity: 0.2;
          width: 245.65px;
          height: 287.74px;

          @media (max-width: 768px) {
            top: 57px;
            left: 121px;
            width: 135.65px;
          }

          &::after {
            position: absolute;
            content: '';
            left: 50%;
            transform: translateX(-50%);
            top: 0px;
            background: url(../../../../assets/img/about-border-main.svg);
            background-size: cover;
            background-repeat: no-repeat;
            width: 245.65px;
            height: 287.74px;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;

            @media (max-width: 768px) {
              width: 120.88px;
              height: 141.59px;
              top: 74px;
            }
          }

          img {
            position: absolute;
            left: 51%;
            transform: translate(-50%, -50%);
            top: 50%;
            width: 229px;
            height: 270px;
            z-index: 1;
            border-top-left-radius: 10px;
            border-top-right-radius: 25px;
            border-bottom-right-radius: 33px;
            border-bottom-left-radius: 10px;

            @media (max-width: 768px) {
              width: 113.88px;
              height: 132.59px;
              border-top-left-radius: 4px;
              border-top-right-radius: 13px;
              border-bottom-right-radius: 12px;
              border-bottom-left-radius: 5px;
            }
          }
        }

        .slider__photo_left_active {
          transition: 2s;
          transform: translate3d(80.6482px, 17.0755px, 0px) rotate(22deg)
            skew(-0.000286479deg, 0deg);
          left: 190px;

          @media (max-width: 768px) {
            left: 98px;
          }
        }

        .slider__photo_main {
          position: absolute;
          top: 70px;
          left: 70px;
          width: 337.5px;
          height: 395.57px;
          animation-duration: 3s;

          @media (max-width: 768px) {
            width: 185.5px;
            top: 0;
          }

          &::after {
            position: absolute;
            content: '';
            left: 50%;
            transform: translateX(-50%);
            top: 0px;
            background: url(../../../../assets/img/about-border-main.svg);
            background-size: cover;
            background-repeat: no-repeat;
            width: 337.5px;
            height: 395.57px;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;

            @media (max-width: 768px) {
              width: 165.46px;
              height: 193.91px;
              top: 101px;
            }

            @media (max-width: 420px) {
              width: 165.46px;
              height: 190.91px;
              top: 95px;
            }
          }

          .slider__photo_main_mask {
            position: relative;
            -webkit-mask-image: url(../../../../assets/img/slider-photo-main-mask.svg);
            mask-image: url(../../../../assets/img/slider-photo-main-mask.svg);
            -webkit-mask-repeat: round;
            mask-repeat: round;
            width: 315.5px;
            height: 397.57px;
            margin-left: 14px;
            z-index: 1;

            @media (max-width: 768px) {
              width: 153.46px;
              height: 193.91px;
              margin-top: 102px;
              margin-left: 17px;
            }

            @media (max-width: 420px) {
              height: 178.91px;
              margin-top: 100px;
            }

            img {
              width: 100%;
              height: 100%;
              z-index: 1;
              object-fit: cover;

              @media (max-width: 420px) {
                z-index: 1;
                object-fit: contain;
              }
            }
          }
        }

        .slider__photo_right {
          position: absolute;
          top: 153px;
          right: 117px;
          opacity: 0.17;
          width: 255.28px;
          height: 299.01px;
          animation-duration: 3s;

          @media (max-width: 768px) {
            top: 70px;
            right: 91px;
            opacity: 0.17;
            width: 145.28px;
          }

          &::after {
            position: absolute;
            content: '';
            left: 50%;
            transform: translateX(-50%);
            top: 0px;
            background: url(../../../../assets/img/about-border-main.svg);
            background-size: cover;
            background-repeat: no-repeat;
            width: 255.28px;
            height: 299.01px;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;

            @media (max-width: 768px) {
              width: 125.15px;
              height: 146.67px;
              top: 78px;
            }
          }

          img {
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            top: 50%;
            width: 240px;
            height: 277px;
            z-index: 1;
            border-top-left-radius: 7px;
            border-top-right-radius: 18px;
            border-bottom-right-radius: 26px;
            border-bottom-left-radius: 2px;

            @media (max-width: 768px) {
              width: 117.15px;
              height: 137.67px;
              border-top-left-radius: 10px;
              border-top-right-radius: 25px;
              border-bottom-right-radius: 11px;
              border-bottom-left-radius: 10px;
            }
          }
        }

        .slider__photo_right_active {
          transition: 2s;
          transform: translate3d(80.6482px, 17.0755px, 0px) rotate(22deg)
            skew(-0.000286479deg, 0deg);
          right: 25px;
          top: 153px;

          @media (max-width: 768px) {
            top: 45px;
            right: 75px;
          }
        }

        .slider__photo_right_box {
          transform: scaleX(-1);
        }
      }
    }
  }

  .statistics__counters {
    padding: 30px;
    margin-top: 50px;

    @media (min-width: 1600px) and (max-width: 1650px) and (max-height: 1085px) {
      margin-top: 0px;
    }

    @media (max-width: 420px) {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: -15px;
      height: 368px;
    }

    .counters {
      display: flex;
      justify-content: space-between;
      width: 720px;
      margin: 0 auto;

      @media (max-width: 420px) {
        flex-direction: column;
        width: 100%;
      }

      .counters__block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        opacity: 0;

        @media (max-width: 420px) {
          margin-bottom: 30px;
        }

        .counters__number {
          font-family: 'Space Grotesk';
          font-style: normal;
          font-weight: 450;
          font-size: 45px;
          line-height: 116%;
          text-align: center;
          background: linear-gradient(288.95deg, #fc64fc -50.27%, #8875fb 77.67%),
            linear-gradient(253.71deg, #ffffff 16.39%, #f79238 59.3%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;

          @media (max-width: 768px) {
            font-size: 32px;
            line-height: 116%;
          }

          @media (max-width: 420px) {
            font-weight: 450;
            font-size: 45px;
            line-height: 116%;
          }
        }

        .counters__text {
          width: 144px;
          font-style: normal;
          font-weight: 300;
          font-size: 15px;
          line-height: 126%;
          text-align: center;
          margin-top: 7px;
          min-height: 35px;
          font-family: 'Space Grotesk';
          font-style: normal;
          font-weight: 300;
          font-size: 15px;
          line-height: 126%;
          text-align: center;
          color: #ffffff;

          @media (max-width: 768px) {
            font-weight: 300;
            font-size: 15px;
            line-height: 126%;
          }

          @media (max-width: 420px) {
            font-weight: 300;
            font-size: 15px;
            line-height: 126%;
          }
        }
      }
    }
  }
}
