.buttonMainFaq {
    position: relative;
    transition: 0.5s;
    cursor: pointer;
    color: #0D0E12;
    opacity: 0.9;
    filter: drop-shadow(0px 0px 14px rgba(137, 30, 188, 0.64));

    .buttonMainFaq__mask {
        position: relative;
        -webkit-mask-image: url(../../../assets/img/buttonMainFaq-mask.svg);
        mask-image: url(../../../assets/img/buttonMainFaq-mask.svg);
        -webkit-mask-repeat: round;
        mask-repeat: round;
        width: 177px;
        height: 41px;

        .color {
            background: url(../../../assets/img/buttonMainFaq-mask.svg);
            width: 100%;
            height: 100%;

            .buttonMainFaq__text {
                font-family: "Space Grotesk";
                font-weight: 500;
                font-size: 12px;
                display: inline-flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                width: 177px;
                height: 41px;
                color: #0D0E12;
                justify-content: center;
            }
        }
    }

    &:hover {
        opacity: 1;
    }
}