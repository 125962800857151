.footer {
  margin-top: 170px;

  @media (max-width: 768px) {
    margin-top: 100px;
  }

  .footer__tuch {
    display: flex;
    justify-content: center;
    align-items: center;

    .tuch {
      position: relative;
      height: 650px;

      @media (max-width: 768px) {
        height: 300px;
      }

      @media (max-width: 420px) {
        height: 270px;
      }

      .tuch__photo {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 1646px;
        height: 591px;

        .tuch__photo_mask {
          position: relative;
          -webkit-mask-image: url(../../assets//img/tuch-back.svg);
          mask-image: url(../../assets//img/tuch-back.svg);
          -webkit-mask-repeat: round;
          mask-repeat: round;
          width: 1646px;
          height: 655.51px;
          z-index: 1;

          @media (min-width: 1600px) and (max-width: 1650px) and (max-height: 1085px) {
            width: 1585px;
          }

          .tuch__photo_decor {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgb(0 0 0 / 51%);
          }

          @media (max-width: 1440px) {
            width: 1396px;
            height: 655.51px;
          }

          @media (max-width: 420px) {
            width: 376px;
            height: 216.56px;
          }

          video {
            width: 100%;
            height: 100%;
            z-index: 1;
            object-fit: cover;
          }
        }

        @media (max-width: 768px) {
          width: 645px;
          height: 232px;
        }

        @media (max-width: 420px) {
          // background: url(../../assets//img/tuch-back-mobile.svg);
          background-size: cover;
          width: 376px;
          height: 197.09px;
        }

        .tuch__logo {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 32%;
          z-index: 11;

          @media (max-width: 1440px) {
            top: 34%;
          }

          @media (max-width: 420px) {
            top: 28.8%;
          }
        }

        .tuch__title {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          font-family: 'Space Grotesk';
          font-style: normal;
          font-weight: 450;
          font-size: 50px;
          line-height: 136%;
          text-align: center;
          letter-spacing: 0.06em;
          text-transform: uppercase;
          color: #ffffff;
          text-shadow: 0px 0px 11px rgba(252, 100, 252, 0.4);
          width: 670px;
          margin-top: 8px;
          z-index: 111;

          @media (max-width: 1440px) {
            width: 600px;
            margin-top: 8px;
          }

          @media (max-width: 768px) {
            font-weight: 450 !important;
            font-size: 30px !important;
            line-height: 126% !important;
            width: 390px;
            margin-top: 58px;
          }

          @media (max-width: 420px) {
            font-weight: 450 !important;
            font-size: 20px !important;
            line-height: 144% !important;
            width: 310px;
            margin-top: 31px;
          }
        }

        .tuch__button {
          position: absolute;
          left: 50%;
          bottom: -54px;
          transform: translateX(-50%);
          cursor: pointer;
          transition: 0.5s;
          opacity: 0.9;
          filter: drop-shadow(0px 0px 14px rgba(137, 30, 188, 0.64));
          z-index: 111;

          @media (max-width: 1440px) {
            bottom: -21px;
          }

          @media (max-width: 420px) {
            bottom: 4px;
          }

          .tuch__button_mask {
            position: relative;
            -webkit-mask-image: url(../../assets/img/tuch-button-mask.svg);
            mask-image: url(../../assets/img/tuch-button-mask.svg);
            -webkit-mask-repeat: round;
            mask-repeat: round;
            width: 1166px;
            height: 88px;

            @media (max-width: 768px) {
              width: 550px;
              height: 55px;
              opacity: 1;
            }

            @media (max-width: 420px) {
              -webkit-mask-image: url(../../assets/img/tuch-button-mask-mobile.svg);
              mask-image: url(../../assets/img/tuch-button-mask-mobile.svg);
              -webkit-mask-repeat: round;
              mask-repeat: round;
              width: 327px;
              height: 38.56px;
            }

            .color {
              background: url(../../assets/img/tuch-button-mask.svg);
              width: 100%;
              height: 100%;

              @media (max-width: 420px) {
                background: url(../../assets/img/tuch-button-mask-mobile.svg);
              }

              .tuch__button_text {
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 500;
                font-size: 25px;
                line-height: 32px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: #0d0e12;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 1166px;
                height: 82px;

                @media (max-width: 768px) {
                  width: 550px;
                  height: 51px;
                  font-size: 15px;
                  line-height: 20px;
                }

                @media (max-width: 420px) {
                  width: 327px;
                  height: 36.56px;
                  font-size: 13px;
                  line-height: 17px;
                }
              }
            }
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  .footer__union {
    margin-top: 165px;
    width: 100%;
    display: flex;
    min-height: 712px;

    @media (max-width: 1440px) {
      margin-top: 110px;
    }

    @media (max-width: 768px) {
      flex-direction: column;
    }

    @media (max-width: 420px) {
      margin-top: 0px;
    }

    .footer__block {
      position: relative;
      padding-bottom: 40px;
      width: 50%;
      border-right: 1px solid #2c2c37;
      @media (max-width: 768px) {
        width: 100%;
        padding-bottom: 20px;
        border-right: 0px solid #2c2c37;
      }

      @media (max-width: 420px) {
        border-right: 0px solid #2c2c37;
      }

      .footer__line {
        position: absolute;
        top: -10px;
        left: 0;
        width: 99%;
        height: 1px;
        background: #2c2c37;

        @media (max-width: 768px) {
          left: 0;
          width: 100%;
        }

        @media (max-width: 420px) {
          display: none;
        }
      }

      .footer__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 105px;

        @media (max-width: 768px) {
          margin-top: 60px;
        }

        @media (max-width: 420px) {
          margin-top: 30px;
        }

        svg {
          width: 300px;
          height: 34.75px;
        }
      }

      .footer__description {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 168%;
        text-align: center;
        color: #ffffff;
        width: 503px;
        margin: 0 auto;
        margin-top: 24px;

        @media (max-width: 1440px) {
          width: 590px;
        }

        @media (max-width: 768px) {
          width: 503px;
        }

        @media (max-width: 510px) {
          width: 100%;
        }

        @media (max-width: 420px) {
          width: 267px;
        }
      }

      .footer__forms {
        margin-top: 240px;
        display: flex;
        justify-content: center;

        @media (max-width: 768px) {
          display: none;
        }

        .forms {
          position: relative;
          width: 499px;
          height: 103px;

          @media (max-width: 1440px) {
            width: 565px;
          }

          .forms__box {
            display: flex;
            align-items: center;

            .forms__button {
              position: relative;
              font-family: 'Space Grotesk';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 138%;
              letter-spacing: 0.1em;
              text-transform: uppercase;
              color: rgba(255, 255, 255, 0.22);
              min-width: 103px;
              cursor: pointer;

              &:first-child {
                margin-right: 25px;
              }
            }

            .forms__button_active {
              position: relative;
              font-family: 'Space Grotesk';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 138%;
              letter-spacing: 0.1em;
              text-transform: uppercase;
              color: #8875fb;

              &::after {
                position: absolute;
                content: '';
                left: 35%;
                bottom: -15px;
                width: 19.5px;
                height: 19.5px;
                background: url(../../assets/img/forms-check-item.svg);
                background-repeat: no-repeat;
                background-size: cover;
                filter: drop-shadow(0px 0px 5px rgba(136, 117, 251, 0.75));
              }
            }

            .forms__block {
              position: absolute;
              left: 0;
              bottom: 10px;

              input {
                background: transparent;
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 116%;
                color: #ffffff;
                border: 0px;
                border-bottom: 1px solid #2c2c37;
                width: 368px;
                transition: 0.5s;
                outline: none;
                padding-bottom: 8px;

                &:focus-visible {
                  border: 0px;
                  border-bottom: 1px solid #9a9a9a;
                  outline: none;
                }

                &:focus {
                  border: 0px;
                  border-bottom: 1px solid #9a9a9a;
                  outline: none;
                }

                &:hover {
                  border: 0px;
                  border-bottom: 1px solid #9a9a9a;
                  outline: none;
                }
              }

              .forms__button {
                position: absolute;
                top: -11px;
                right: -123px;
              }
            }
          }
        }
      }

      .footer__copyright {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 168%;
        color: rgba(255, 255, 255, 0.49);
        margin-top: 112px;
        margin-left: 48px;

        @media (max-width: 1440px) {
          margin-left: 70px;
        }

        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    .footer__box {
      position: relative;
      width: 50%;
      padding-bottom: 40px;

      @media (max-width: 768px) {
        width: 100%;
      }

      .footer__line {
        position: absolute;
        right: 0;
        top: -10px;
        width: 99%;
        height: 1px;
        background: #2c2c37;

        @media (max-width: 768px) {
          width: 100%;
          left: 0;
          height: 0;
        }

        @media (max-width: 420px) {
          display: none;
        }
      }

      .box__items {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;

        @media (max-width: 768px) {
          margin-left: 0;
          padding-left: 0;
        }

        .box__item {
          position: relative;
          list-style: none;
          width: 580px;
          height: 73px;
          border-bottom: 1px solid #2c2c37;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: 0.5s;
          background-color: #10101d;

          @media (max-width: 590px) {
            width: 100%;
          }

          &::before {
            position: absolute;
            content: '';
            left: 0px;
            top: 0px;
            width: 580px;
            height: 73px;
            background: linear-gradient(347.02deg, rgb(0, 0, 0) -49.64%, transparent 37.89%),
              linear-gradient(182.69deg, rgb(0, 0, 0) -18.49%, transparent 29.6%),
              linear-gradient(181deg, rgb(18, 26, 34) 24.8%, rgb(207, 95, 189) 99.85%);
            border-bottom: 1px solid rgb(207, 95, 189);
            mix-blend-mode: color-dodge;
            opacity: 0;
          }

          a {
            mix-blend-mode: color-dodge;
            position: relative;
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 138%;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: #ffffff;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 73px;

            &::before {
              position: absolute;
              content: '';
              left: -50px;
              top: 29px;
              width: 9px;
              height: 14px;
              background: url(../../assets/img/arrow-item-footer.svg);
              background-repeat: no-repeat;
              background-size: cover;
              opacity: 0;
              transition: 0.5s;
              filter: drop-shadow(0px 0px 8px rgba(136, 117, 251, 0.5));
            }

            &::after {
              position: absolute;
              content: '';
              right: -50px;
              top: 29px;
              width: 9px;
              height: 14px;
              background: url(../../assets/img/arrow-item-footer.svg);
              background-repeat: no-repeat;
              background-size: cover;
              transform: rotate(180deg);
              opacity: 0;
              transition: 0.5s;
              filter: drop-shadow(0px 0px 8px rgba(136, 117, 251, 0.5));
            }
          }

          &:hover {
            &::before {
              opacity: 1;
            }

            a {
              &::before {
                left: -30px;
                opacity: 1;
              }
            }

            a {
              &::after {
                right: -30px;
                opacity: 1;
              }
            }
          }
        }
      }

      .box__socials {
        margin-top: 40px;

        .socials {
          @media (max-width: 420px) {
            width: 300px;
          }
        }
      }

      .box__information {
        margin-top: 70px;

        @media (max-width: 1440px) {
          margin-top: 50px;
        }

        @media (max-width: 420px) {
          margin-top: 130px;
        }

        .information {
          display: flex;
          align-items: center;

          @media (max-width: 420px) {
            flex-direction: column;
          }

          .information__items {
            display: flex;
            align-items: center;

            @media (max-width: 768px) {
              margin-left: auto;
            }

            @media (max-width: 420px) {
              flex-direction: column;
              margin-left: 0;
            }

            .information__item {
              list-style: none;

              @media (max-width: 420px) {
                margin-bottom: 15px;
              }

              a {
                display: block;
                transition: 0.5s;
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 300;
                font-size: 13px;
                line-height: 168%;
                color: rgba(255, 255, 255, 0.49);
                margin-left: 70px;

                @media (max-width: 768px) {
                  margin-left: 35px;
                }

                @media (max-width: 420px) {
                  margin-left: 0;
                }

                &:hover {
                  span {
                    text-decoration: underline;
                  }
                }
              }
            }
          }

          .information__creater {
            margin-left: auto;

            @media (max-width: 768px) {
              margin-left: 41px;
            }

            @media (max-width: 420px) {
              margin-left: 0;
            }

            a {
              font-family: 'Space Grotesk';
              font-style: normal;
              font-weight: 300;
              font-size: 13px;
              line-height: 168%;
              color: rgba(255, 255, 255, 0.49);
              display: flex;
              margin-right: 48px;
              cursor: pointer;

              @media (max-width: 420px) {
                margin-right: 0;
                margin-bottom: 15px;
              }

              span {
                font-weight: 600;
              }

              &:hover {
                span {
                  transition: 0.5s;
                  color: #ff00ff;
                }
              }

              .creater {
                margin-left: 5px;
              }
            }
          }
        }
      }

      .box__forms {
        margin-top: 140px;
        margin-bottom: 80px;
        display: flex;
        justify-content: center;

        @media (min-width: 769px) {
          display: none;
        }

        @media (max-width: 420px) {
          margin-top: 100px;
        }

        .forms {
          position: relative;
          width: 499px;
          height: 103px;

          @media (max-width: 1440px) {
            width: 565px;
          }

          @media (max-width: 768px) {
            width: 499px;
          }

          @media (max-width: 570px) {
            width: 100%;
          }

          .forms__box {
            display: flex;
            align-items: center;

            @media (max-width: 420px) {
              justify-content: center;
            }

            .forms__button {
              position: relative;
              font-family: 'Space Grotesk';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 138%;
              letter-spacing: 0.1em;
              text-transform: uppercase;
              color: rgba(255, 255, 255, 0.22);
              min-width: 103px;
              cursor: pointer;

              &:first-child {
                margin-right: 25px;
              }
            }

            .forms__button_active {
              position: relative;
              font-family: 'Space Grotesk';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 138%;
              letter-spacing: 0.1em;
              text-transform: uppercase;
              color: #8875fb;

              &::after {
                position: absolute;
                content: '';
                left: 35%;
                bottom: -15px;
                width: 19.5px;
                height: 19.5px;
                background: url(../../assets/img/forms-check-item.svg);
                background-repeat: no-repeat;
                background-size: cover;
              }
            }

            .forms__block {
              position: absolute;
              left: 0;
              bottom: 10px;

              @media (max-width: 420px) {
                left: 32px;
                bottom: 0;
                width: 100%;
              }

              input {
                background: transparent;
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 116%;
                color: #ffffff;
                border: 0px;
                border-bottom: 1px solid #2c2c37;
                width: 368px;
                transition: 0.5s;
                outline: none;
                padding-bottom: 8px;

                @media (max-width: 420px) {
                  width: 322px;
                }

                @media (max-width: 375px) {
                  width: 310px;
                }

                @media (max-width: 420px) and (min-width: 414px) {
                  width: 343px;
                }

                &:focus-visible {
                  border: 0px;
                  border-bottom: 1px solid #9a9a9a;
                  outline: none;
                }

                &:focus {
                  border: 0px;
                  border-bottom: 1px solid #9a9a9a;
                  outline: none;
                }

                &:hover {
                  border: 0px;
                  border-bottom: 1px solid #9a9a9a;
                  outline: none;
                }
              }

              .forms__button {
                position: absolute;
                top: -8px;
                right: -151px;

                @media (max-width: 768px) {
                  top: -10px;
                  right: -126px;
                }

                @media (max-width: 420px) {
                  top: 40px;
                  right: auto;
                  left: 0;
                  width: 100%;
                }

                .buttonMainSubscribe {
                  @media (max-width: 420px) {
                    width: 84%;
                  }
                }
              }
            }
          }
        }
      }

      .box__copyright {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 168%;
        color: rgba(255, 255, 255, 0.49);
        margin-left: 48px;

        @media (max-width: 420px) {
          margin-left: 0;
          margin-bottom: 15px;
        }

        @media (min-width: 769px) {
          display: none;
        }
      }
    }
  }
}
