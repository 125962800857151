.buttonMainBlog {
    position: relative;
    transition: 0.5s;
    cursor: pointer;
    margin-left: -8px;
    filter: drop-shadow(0px 0px 14px rgba(137, 30, 188, 0.64));
    display: block;
    opacity: 0.9;

    @media (max-width: 420px) {
        opacity: 1;
    }

    .buttonMainBlog__mask {
        position: relative;
        -webkit-mask-image: url(../../../assets/img/buttonMainBlog-mask.svg);
        mask-image: url(../../../assets/img/buttonMainBlog-mask.svg);
        -webkit-mask-repeat: round;
        mask-repeat: round;
        width: 213.4px;
        height: 48px;

        .color {
            background: url(../../../assets/img/buttonMainBlog-mask.svg);
            width: 100%;
            height: 100%;

            .buttonMainBlog__text {
                font-family: "Space Grotesk";
                font-weight: 500;
                font-size: 13px;
                display: inline-flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                justify-content: center;
                color: #0D0E12;
                width: 212.4px;
                height: 48px;
            }
        }
    }

    &:hover {
        opacity: 1;
    }
}