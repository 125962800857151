.header {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1111;
    width: 100%;
    background: rgba(13, 14, 18, 0.42);
    height: 71px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // transform: translate(0px, -80px);
    will-change: transform;
    opacity: 0;

    @media (max-width: 768px) {
        background: rgb(13 14 18 / 0%);
    }

    .menu-element {
        opacity: 0;
    }

    .header__box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

    }

    @media (max-width: 768px) {
        height: 56px;
    }

    &::after {
        position: absolute;
        content: "";
        left: 0px;
        bottom: 0px;
        width: 100%;
        height: 1px;
        background: rgba(45, 39, 81, 0.167);
        background-size: cover;
    }

    .header__menu {

        @media (max-width: 768px) {
            left: 25px;
        }

        @media (max-width: 420px) {
            left: 15px;
            top: 22px;
            width: 38px;
        }

        .menu {

            @media (max-width: 420px) {
                width: 100%;
            }

            .menu__button {
                cursor: pointer;
                transition: 0.5s;
                position: absolute;
                left: 30px;
                top: 30px;
                opacity: 0;

                @media (max-width: 768px) {
                    top: 22px;
                }

                @media (max-width: 420px) {
                    top: 22px;
                    left: 19px;
                }

                &::after {
                    position: absolute;
                    content: "";
                    left: -16px;
                    top: -16px;
                    width: 81px;
                    height: 35px;

                    @media (max-width: 1440px) {
                        top: -8px;
                    }

                    @media (max-width: 768px) {
                        height: 30px;
                    }
                }

                .menu__line_first {
                    display: block;
                    background: #fff;
                    width: 48px;
                    height: 1px;
                    margin-bottom: 7px;

                    @media (max-width: 768px) {
                        width: 40px;
                    }
                }

                .menu__line_second {
                    display: block;
                    background: #fff;
                    width: 29px;
                    height: 1px;
                    transition: 0.5s;

                    @media (max-width: 768px) {
                        width: 23px;
                    }
                }

                &:hover {
                    .menu__line_second {
                        width: 48px;

                        @media (max-width: 768px) {
                            width: 40px;
                        }
                    }
                }
            }

            ul {
                position: absolute;
                top: 30px;
                left: 105px;
                display: flex;
                margin: 0;
                padding: 0;
                margin-top: -4px;

                @media (max-width: 768px) {
                    display: none;
                }

                li {
                    position: relative;
                    font-family: 'Space Grotesk';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    text-transform: uppercase;
                    line-height: 138%;
                    letter-spacing: 0.1em;
                    color: #FFFFFF;
                    list-style: none;
                    margin-right: 48px;
                    margin-left: 15px;
                    transition: all 1s ease 0s;
                    cursor: pointer;

                    &::before {
                        content: "";
                        background: url(../../assets/img/header-item-active-bg.svg) center center / 100% 100% no-repeat;
                        position: absolute;
                        width: 180px;
                        height: 60px;
                        bottom: -23px;
                        left: 48%;
                        opacity: 0;
                        filter: blur(7px);
                        transform: translateX(-50%);
                        transition: all 1s ease 0s;
                        z-index: -1;
                    }

                    &::after {
                        content: "";
                        background: url(../../assets/img/header-item-active-border.svg)center center / 100% 100% no-repeat;
                        width: 359px;
                        height: 71px;
                        position: absolute;
                        top: -30px;
                        left: 43%;
                        transform: translateX(-50%);
                        transition: all 1s ease 0s;
                        z-index: -1;
                        opacity: 0;
                    }

                    &:hover {
                        &::before {
                            opacity: 1;
                        }

                        &::after {
                            opacity: 1;
                        }
                    }

                    a {
                        color: #FFFFFF;
                    }
                }
            }
        }
    }

    .header__menu_box {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 11;
        background: #10101B;
        height: auto;
        width: 100%;
        display: none;
        opacity: 0;

        @media (max-width: 768px) {
            height: 100vh;
        }

        .menu_box {
            .menu {
                .menu__header {
                    position: relative;
                    height: 80px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    @media (max-width: 768px) {
                        height: 56px;
                    }

                    .menu__close {
                        position: absolute;
                        left: 30px;
                        top: 26px;
                        display: flex;
                        align-items: center;
                        font-family: 'Space Grotesk';
                        font-style: normal;
                        text-transform: uppercase;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 138%;
                        color: #FFFFFF;
                        width: 230px;
                        cursor: pointer;
                        opacity: 0.8;
                        transition: 0.5s;

                        @media (max-width: 768px) {
                            left: 25px;
                            top: 19px;
                        }

                        @media (max-width: 420px) {
                            left: 15px;
                            top: 16px;
                        }

                        &::after {
                            position: absolute;
                            content: '';
                            left: -20px;
                            top: -24px;
                            width: 110px;
                            height: 80px;

                            @media (max-width: 768px) {
                                height: 30px;
                            }
                        }

                        &:hover {
                            opacity: 1;
                        }

                        svg {
                            width: 20px;
                            height: 20px;
                            margin-right: 10px;
                        }
                    }

                    .menu__logo {
                        position: absolute;
                        top: 19px;
                        left: 50%;
                        transform: translateX(-50%);

                        @media (max-width: 420px) {
                            top: 20px;
                        }

                        img {
                            margin-top: 10px;

                            @media (max-width: 768px) {
                                margin-top: 5px;
                                width: 120px;
                                height: 14px;
                                display: block;
                            }

                            @media (max-width: 420px) {
                                margin-top: 0px;
                                display: block;
                                width: 135px;
                                height: 16px;
                            }
                        }
                    }

                    .menu__buttons {
                        position: absolute;
                        right: 48px;
                        display: flex;
                        justify-content: center;

                        @media (max-width: 768px) {
                            right: 25px;
                        }

                        @media (max-width: 420px) {
                            right: 3px;
                        }

                        .menu__button {
                            &:last-child {

                                @media (max-width: 420px) {
                                    display: none;
                                }
                            }

                            .buttonDefaultHeader {
                                top: -6px;
                                right: 30px;

                                @media (max-width: 768px) {
                                    top: -2px;
                                    right: 17px;
                                }

                                @media (max-width: 420px) {
                                    top: -1px;
                                    right: 8px;
                                }
                            }

                            .buttonMainHeader {
                                top: -6px;

                                @media (max-width: 768px) {
                                    top: -2px;
                                    right: -13px;
                                }
                            }
                        }
                    }
                }

                .menu__body {
                    @media (max-width: 1440px) {
                        overflow-y: scroll;
                        height: 665px;
                        padding-bottom: 50px;
                        margin-bottom: 50px;

                        &::-webkit-scrollbar {
                            width: 3px;
                            background: transparent;
                            height: 0px;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: #6742ca;
                        }
                    }

                    @media (max-width: 768px) {
                        height: 100%;
                    }

                    @media (max-width: 420px) {
                        overflow-y: scroll;
                        height: 600px;

                        &::-webkit-scrollbar {
                            width: 0px;
                            background: transparent;
                            height: 0px;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: #EEEFF5;
                        }
                    }

                    .menu__links {
                        padding-left: 0;

                        @media (max-width: 420px) {
                            margin-top: 0;
                        }

                        .menu__link {
                            position: relative;
                            font-family: 'Space Grotesk';
                            font-style: normal;
                            font-weight: 450;
                            font-size: 26px;
                            line-height: 116%;
                            letter-spacing: 0.08em;
                            text-transform: uppercase;
                            color: #FFFFFF;
                            height: 98px;
                            list-style: none;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-bottom: 1px solid #2C2C37;
                            transition: 0.5s;
                            cursor: pointer;

                            @media (max-width: 768px) {
                                font-weight: 450;
                                font-size: 20px;
                                line-height: 116%;
                                height: 75px;
                            }

                            .menu__marquee-container {
                                display: flex;
                                align-items: center;
                                overflow: hidden;
                                align-items: center;
                            }

                            .menu__marquee {
                                color: #fff;
                                white-space: nowrap;
                                animation: marquee 60.5s infinite linear;
                                display: flex;
                                align-items: center;

                                &:after {
                                    content: "";
                                }

                                span {
                                    margin: 0 25px;
                                }
                            }

                            @keyframes marquee {
                                0% {
                                    transform: translateX(0)
                                }

                                100% {
                                    transform: translateX(-50%)
                                }
                            }

                            a {
                                color: #FFFFFF;

                                .menu__link_active {
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    height: 98px;
                                    width: 100%;
                                    background: url(../../assets/img/back-item.png);
                                    background-size: cover;
                                    display: none;
                                    transition: 0.5s;

                                    @media (max-width: 768px) {
                                        height: 75px;
                                    }

                                    @media (max-width: 420px) {
                                        display: none;
                                    }

                                    .menu__link_box {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        // overflow: hidden;

                                        .box {
                                            display: flex;
                                            align-items: center;

                                            .box__title {
                                                position: relative;
                                                font-family: "Space Grotesk";
                                                font-style: normal;
                                                font-weight: 450;
                                                font-size: 26px;
                                                line-height: 116%;
                                                letter-spacing: 0.08em;
                                                text-transform: uppercase;
                                                color: #FFFFFF;
                                                height: 98px;
                                                transition: 0.5s;
                                                cursor: pointer;
                                                margin: 0 0px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;

                                                @media (max-width: 768px) {
                                                    font-weight: 450;
                                                    font-size: 20px;
                                                    line-height: 116%;
                                                    height: 75px;
                                                }
                                            }

                                            .box__img {
                                                margin: 0 10px;


                                                img {
                                                    border-radius: 60px;
                                                    width: 1px;
                                                    height: 64px;

                                                    @media (max-width: 768px) {
                                                        height: 54px;
                                                    }
                                                }
                                            }

                                            .box__title_full {
                                                width: 190px;
                                            }

                                            .box__title_full-second {
                                                width: 165px;
                                            }
                                        }
                                    }

                                    @keyframes move {
                                        0% {
                                            margin-left: 0;
                                        }

                                        100% {
                                            margin-left: -41100px;
                                        }
                                    }
                                }
                            }

                            &:hover {
                                .menu__link_active {
                                    display: block;

                                    @media (max-width: 420px) {
                                        display: none;
                                    }
                                }

                                .menu__link_box {
                                    // animation: move 3s linear alternate infinite;
                                    height: 98px;
                                    margin-left: -1500px;

                                    @media (max-width: 768px) {
                                        height: 78px;
                                    }
                                }


                                .menu__link_default {
                                    display: none;

                                    @media (max-width: 420px) {
                                        display: block;
                                    }
                                }
                            }
                        }

                        @keyframes move {
                            0% {
                                margin-left: 0;
                            }

                            100% {
                                margin-left: -500px;
                            }
                        }

                        .menu__link_1 {
                            .box__title_full {
                                width: 260px !important;

                                @media (max-width: 768px) {
                                    width: 190px !important;
                                }
                            }

                            .box__img {
                                img {
                                    width: 220px !important;

                                    @media (max-width: 768px) {
                                        width: 130px !important;
                                    }
                                }
                            }
                        }
                    }

                    .menu__socials {
                        margin-top: 40px;

                        .socials {
                            @media (max-width: 420px) {
                                width: 300px;
                            }
                        }
                    }

                    .menu__popularlinks {
                        margin: 0 48px;
                        margin-top: 60px;
                        padding-bottom: 50px;

                        .links {
                            display: flex;
                            align-items: center;

                            @media (max-width: 420px) {
                                flex-direction: column;
                            }

                            li {
                                list-style: none;

                                &:nth-child(2) {
                                    margin-left: auto;

                                    @media (max-width: 420px) {
                                        margin-left: 0;
                                        margin-bottom: 15px;
                                    }

                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }

                                &:nth-child(3) {
                                    margin-left: 90px;
                                    margin-right: 250px;

                                    @media (max-width: 768px) {
                                        margin-left: 50px;
                                        margin-right: 50px;
                                    }

                                    @media (max-width: 420px) {
                                        margin-left: 0;
                                        margin-right: 0;
                                        margin-bottom: 15px;
                                    }

                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }

                                &:last-child {
                                    margin-right: 0;
                                }
                            }

                            .link {
                                font-family: 'Space Grotesk';
                                font-style: normal;
                                font-weight: 300;
                                font-size: 13px;
                                line-height: 168%;
                                color: rgba(255, 255, 255, 0.49);
                                cursor: pointer;
                                transition: 0.5s;

                                &:hover {
                                    span {
                                        text-decoration: underline;
                                    }
                                }

                                @media (max-width: 420px) {
                                    margin-bottom: 15px;
                                }

                                a {
                                    color: rgba(255, 255, 255, 0.49);

                                    &:hover {
                                        transition: 0.5s;

                                        span {
                                            transition: 0.5s;
                                            color: #ff00ff;
                                        }
                                    }
                                }

                                &:last-child {
                                    @media (max-width: 420px) {
                                        margin-bottom: 50px;
                                    }
                                }
                            }

                            .designed {

                                a {
                                    display: flex;
                                    align-items: center;
                                }

                                &:hover {
                                    span {
                                        text-decoration: none;
                                    }
                                }

                                .company {
                                    margin-left: 3px;
                                }
                            }

                            .copyright {
                                &:hover {
                                    span {
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .header__logo {
        position: absolute;
        top: 28px;
        left: 50%;
        transform: translateX(-50%);

        @media (max-width: 420px) {
            top: 25px;
        }

        img {
            margin-top: 0px;

            @media (max-width: 768px) {
                margin-top: -4px;
                width: 120px;
                height: 14px;
                display: block;
            }

            @media (max-width: 420px) {
                margin-top: -5px;
                width: 135px;
                height: 16px;
            }
        }
    }

    .header__buttons {
        position: absolute;
        right: 48px;
        display: flex;
        align-items: center;

        @media (max-width: 768px) {
            right: 10px;
        }

        @media (max-width: 420px) {
            right: 1px;
        }

        .header__button:last-child {
            margin-left: 20px;

            @media (max-width: 420px) {
                display: none;
            }
        }

        .buttonDefaultHeader {
            margin-right: 10px;
            margin-top: -3px;
        }

        .buttonMainHeader {
            top: -2px;
        }
    }
}