.faq {
    position: relative;
    min-height: 680px;
    margin-bottom: 140px;

    @media (max-width: 1440px) {
        margin-bottom: 100px;
        min-height: 620px;
    }

    @media (max-width: 768px) {
        margin-bottom: 90px;
    }

    &::before {
        position: absolute;
        content: '';
        left: 0px;
        top: 0px;
        width: 100%;
        height: 1080px;
        background: url(../../assets/img/bg_3.webp);
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -1;
    }

    .faq__block {
        position: relative;
        width: 976px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 0 auto;
        padding-top: 100px;
        z-index: 11;

        @media (max-width: 768px) {
            width: 700px;
            padding-top: 50px;
        }

        @media (max-width: 420px) {
            width: 100%;
        }

        .faq__title {
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 450;
            font-size: 45px;
            line-height: 116%;
            text-align: center;

            @media (max-width: 768px) {
                font-weight: 450;
                font-size: 32px;
                line-height: 116%;
            }
        }

        .faq__description {
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 300;
            font-size: 15px;
            line-height: 168%;
            text-align: center;
            color: #FFFFFF;
            width: 623px;
            margin: 0 auto;
            margin-top: 18px;
            opacity: 0;

            @media (max-width: 768px) {
                font-weight: 300;
                font-size: 14px;
                line-height: 168%;
                width: 543px;
            }

            @media (max-width: 420px) {
                width: 90%;
            }
        }

        .faq__items {
            margin: 0 auto;
            margin-top: 50px;

            @media (max-width: 768px) {
                width: 700px;
            }

            @media (max-width: 420px) {
                width: 100%;
            }
        }
    }
}

.faq-page {
    .accordion__item .accordion__content {

        @media (max-width: 768px) {
            width: 610px;
        }

        @media (max-width: 420px) {
            width: 336px;
        }

        @media (max-width: 390px) {
            width: 294px;
        }

        @media (max-width: 375px) {
            width: 294px;
            margin-left: 4px;
        }
    }

    .accordion__item .accordion__content::after {

        @media (max-width: 768px) {
            width: 612px;
        }

        @media (max-width: 420px) {
            width: 353px;
        }

        @media (max-width: 390px) {
            width: 337px;
        }

        @media (max-width: 375px) {
            width: 315px;
        }
    }

    .accordion__item {
        z-index: 1;
    }

    @media (max-width: 768px) and (min-width: 760px) {
        .accordion__item .accordion__title .accordion__title_back {
            width: 632px;
            height: 71px;
        }

        .accordion__item .accordion__title .accordion__title_text {
            margin-top: 4px;
            width: 536px;
        }

        .accordion__item .accordion__title .accordion__title_back::after {
            width: 642px;
            left: 0px;
        }

        .accordion__item .accordion__content_all .accordion__bottom-decor {
            bottom: -25px;
            width: 611px;
        }
    }
}