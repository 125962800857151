html {
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 3px;
    background: rgb(25 18 31 / 52%);
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #936bfa;
  }
}

// .htmlHide {
//   overflow: hidden;
// }

body {
  margin: 0;
  font-family: 'Space Grotesk', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #10101c;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

code {
  font-family: 'Space Grotesk', sans-serif;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;

}

h2 {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 450;
  font-size: 50px;
  line-height: 116%;

  @media (max-width: 1440px) {
    font-weight: 450 !important;
    font-size: 45px !important;
    line-height: 116% !important;
  }

  @media (max-width: 768px) {
    font-weight: 450 !important;
    font-size: 28px !important;
    line-height: 116% !important;
  }

  @media (max-width: 420px) {
    font-weight: 450 !important;
    font-size: 32px !important;
    line-height: 120% !important;
  }
}

.styleText {
  background: linear-gradient(98.71deg, #8875FB -0.58%, #FC64FC 44.41%, #FE9040 91.3%);
  background: rgb(214 111 252);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 0px 0px 8.15px rgba(136, 117, 251, 0.34);

  span {
    background: linear-gradient(98.71deg, #8875FB -0.58%, #FC64FC 44.41%, #FE9040 91.3%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-shadow: 0px 0px 8.15px rgba(136, 117, 251, 0.34);
  }
}

.text {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 188%;
  color: #FFFFFF;

  @media (max-width: 1440px) {
    font-weight: 300;
    font-size: 15px;
    line-height: 188%;
  }

  @media (max-width: 768px) {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 188%;
  }

  @media (max-width: 420px) {
    font-weight: 300;
    font-size: 15px;
    line-height: 168%;
  }

  span {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 188%;
    color: #FFFFFF;

    @media (max-width: 1440px) {
      font-weight: 300;
      font-size: 15px;
      line-height: 188%;
    }

    @media (max-width: 768px) {
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 188%;
    }

    @media (max-width: 420px) {
      font-weight: 300;
      font-size: 15px;
      line-height: 168%;
    }
  }
}

.section-name {
  position: relative;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 450;
  font-size: 13px;
  line-height: 126%;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  color: #FC64FC;
  padding-left: 30px;
  margin-bottom: 15px;
  display: inline-block;

  @media (max-width: 768px) {
    font-weight: 450;
    font-size: 10px;
    line-height: 126%;
    padding-left: 0;
  }

  @media (max-width: 420px) {
    font-weight: 450;
    font-size: 10px;
    line-height: 126%;
  }

  &::after {
    position: absolute;
    content: "";
    left: 2px;
    top: 6px;
    width: 19.5px;
    height: 1px;
    background: #FC64FC;

    @media (max-width: 420px) {
      &::after {
        top: 5px;
      }
    }
  }
}

.read-more {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  background: linear-gradient(177deg, #8875FB -0.58%, #FC64FC 44.41%, #FE9040 91.3%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 0px 0px 11px rgb(136 117 251 / 54%);

  span {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    background: linear-gradient(177deg, #8875FB -0.58%, #FC64FC 44.41%, #FE9040 91.3%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-shadow: 0px 0px 11px rgb(136 117 251 / 54%);
  }
}


// The style for animated texts

.interText {

  #interText__information {
    margin-top: 100px;
    background: linear-gradient(98.71deg, #8875FB -0.58%, #FC64FC 44.41%, #cc529e 91.3%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-shadow: 0px 0px 8.15px rgba(136, 117, 251, 0.34);
    font-weight: 450 !important;
    font-size: 45px !important;
    line-height: 116% !important;

    @media (max-width: 1440px) {
      font-weight: 450 !important;
      font-size: 45px !important;
      line-height: 116% !important;
    }

    @media (max-width: 768px) {
      font-weight: 450 !important;
      font-size: 28px !important;
      line-height: 116% !important;
    }

    @media (max-width: 420px) {
      font-weight: 450 !important;
      font-size: 32px !important;
      line-height: 120% !important;
    }
  }

  .interText__line {
    display: inline-block;
    position: relative;
    bottom: 0;
    left: 7px;
    background: #fd5fe3;
    height: 4px;
    width: 20px;

    @media (max-width: 768px) {
      height: 2px;
      width: 17px;
    }
  }

  .hidden {
    opacity: 0;
  }
}

.hideBlock {
  opacity: 0;
}

.show {
  display: block;
}

.animate__animated {
  --animate-duration: 1.8s;
}

.animate__fadeInDown {
  --animate-duration: 1s;
}

.animate__flipInX {
  --animate-duration: 1s;
}