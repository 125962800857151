.buttonMainSend {
    position: relative;
    font-family: "Space Grotesk";
    font-weight: 500;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    background: transparent;
    border: 0;
    text-transform: uppercase;
    transition: 0.5s;
    cursor: pointer;
    color: #0D0E12;
    opacity: 0.9;
    filter: drop-shadow(0px 0px 14px rgba(137, 30, 188, 0.64));

    @media (max-width: 420px) {
        opacity: 1;
    }

    .buttonMainSend__mask {
        position: relative;
        -webkit-mask-image: url(../../../assets/img/buttonMainSend-mask.svg);
        mask-image: url(../../../assets/img/buttonMainSend-mask.svg);
        -webkit-mask-repeat: round;
        mask-repeat: round;
        width: 154.5px;
        height: 40px;

        @media (max-width: 420px) {
            -webkit-mask-image: url(../../../assets/img/buttonMainSend-mask-mobile.svg);
            mask-image: url(../../../assets/img/buttonMainSend-mask-mobile.svg);
            -webkit-mask-repeat: round;
            mask-repeat: round;
            width: 327px;
            height: 48px;
        }

        .color {
            background: url(../../../assets/img/buttonMainSend-mask.svg);
            width: 100%;
            height: 100%;

            @media (max-width: 420px) {
                background: url(../../../assets/img/buttonMainSend-mask-mobile.svg);
            }

            .buttonMainSend__text {
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                width: 154.5px;
                height: 40px;
                color: #0D0E12;
                justify-content: center;

                @media (max-width: 420px) {
                    width: 327px;
                    height: 48px;
                }
            }
        }
    }

    &:hover {
        opacity: 1;
    }
}