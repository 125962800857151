.contacts {
    position: relative;
    min-height: 1000px;

    &::before {
        position: absolute;
        content: '';
        left: 0px;
        top: 0px;
        width: 100%;
        height: 1000px;
        background: url(../../assets/img/bg_3.webp);
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -1;
    }

    .contacts__union {
        display: flex;
        justify-content: space-between;
        width: 1280px;
        margin: 0 auto;
        padding-top: 270px;

        @media (max-width: 1440px) {
            width: 1100px;
        }

        @media (max-width: 768px) {
            flex-direction: column;
            width: 90%;
            align-content: center;
            padding-top: 140px;
        }

        .contacts__network {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @media (max-width: 768px) {
                margin-bottom: 90px;
            }

            @media (max-width: 420px) {
                width: 100%;
            }
        }

        .contacts__form {
            width: 580px;

            @media (max-width: 768px) {
                width: 100%;
            }
        }
    }
}