.page {
    position: relative;
    width: 720px;
    margin: 0 auto;
    margin-top: 180px;

    .page__title {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 450;
        font-size: 45px;
        line-height: 116%;
        color: #FFFFFF;
    }

    img {
        width: 720px;
        object-fit: cover;
    }

    p {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 216%;
        color: #FFFFFF;
        margin-bottom: 25px;
    }

    h2 {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 450;
        font-size: 28px;
        line-height: 116%;
        color: #FFFFFF;
        margin-top: 30px;
    }

    h3 {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 450;
        font-size: 25px;
        line-height: 116%;
        color: #FFFFFF;
        margin-top: 30px;
    }

    h4 {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 450;
        font-size: 22px;
        line-height: 116%;
        color: #FFFFFF;
        margin-top: 30px;
    }

    h5 {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 450;
        font-size: 20px;
        line-height: 116%;
        color: #FFFFFF;
        margin-top: 30px;
    }

    h6 {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 450;
        font-size: 18px;
        line-height: 116%;
        color: #FFFFFF;
        margin-top: 30px;
    }

    a {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 216%;
        text-decoration-line: underline;
        color: #8875FB;

        &:hover {
            text-decoration: none;
        }
    }

    ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 23px;
        margin-bottom: 25px;

        li {
            position: relative;
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 176%;
            color: #FFFFFF;
            list-style: none;
            margin: 10px 0px;
            display: inline-block;
            margin-left: 12px;
            padding-bottom: 12px;

            &::before {
                position: absolute;
                content: "";
                left: -33px;
                top: 5px;
                width: 21px;
                height: 21px;
                background: url(../../../assets//img/item-article.svg);
                background-repeat: no-repeat;
                background-size: cover;
            }

            &::after {
                position: absolute;
                content: '';
                left: 0px;
                bottom: 0px;
                width: 100%;
                height: 1px;
                background: linear-gradient(90.03deg, rgba(136, 117, 251, 0.5) -30.09%, rgba(136, 117, 251, 0) 133.19%);
            }
        }
    }

    ol {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 23px;
        margin-bottom: 25px;
        counter-reset: item;

        li {
            position: relative;
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 176%;
            color: #FFFFFF;
            list-style: none;
            margin: 10px 0px;
            display: inline-block;
            margin-left: 12px;
            padding-bottom: 12px;

            &::before {
                position: absolute;
                left: -32px;
                top: 5px;
                font-size: 16px;
                content: '0' counter(item)'.';
                counter-increment: item;
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 176%;
                background: linear-gradient(90deg, #FC64FC 35%, #FE9040 110%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                text-shadow: 0px 0px 8px #FC64FC;
            }

            &::after {
                position: absolute;
                content: '';
                left: 0px;
                bottom: 0px;
                width: 100%;
                height: 1px;
                background: linear-gradient(90.03deg, rgba(136, 117, 251, 0.5) -30.09%, rgba(136, 117, 251, 0) 133.19%);
            }
        }
    }

    img {
        width: 724px;
        height: 387px;
    }

    blockquote {
        position: relative;
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 166%;
        background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(97.95deg, #8875FB -2.11%, #FC64FC 55.98%, #FE9040 108.32%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        textFillColor: transparent;
        padding-left: 41px;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-left: 0;
        padding-right: 0;
        margin-right: 0;
        margin-top: 35px;
        margin-bottom: 35px;

        svg {
            position: absolute;
            content: "";
            left: -1px;
            top: 30px;
        }

        &::after {
            position: absolute;
            content: '';
            left: 0px;
            top: 0px;
            width: 100%;
            height: 1px;
            background: url(../../../assets/img/blockquote-line.svg);
            background-repeat: no-repeat;
            background-size: cover;
        }

        &::before {
            position: absolute;
            content: '';
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 1px;
            background: url(../../../assets/img/blockquote-line.svg);
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
}