.faq {
    margin: 90px 0px;

    @media (max-width: 420px) {
        margin-top: 80px;
    }

    .faq__title {
        text-align: center;
    }

    .faq__union {
        width: 976px;
        min-height: 400px;
        margin: 0 auto;
        margin-top: 42px;

        @media (max-width: 768px) {
            width: 100%;
        }

        .faq__button {
            display: flex;
            justify-content: center;
            margin: 0 auto;
            margin-top: 58px;
            opacity: 0;
        }
    }
}