.howitworks {
    position: relative;
    background: url(../../../../assets/img/howitworks-back.png);
    height: 850px;
    margin-top: 50px;
    margin-bottom: 60px;
    background-position: center;
    overflow: hidden;

    @media (max-width: 768px) {
        height: 700px;
        margin-top: 150px;
        margin-bottom: 80px;
    }

    @media (max-width: 420px) {
        margin-top: 0;
                height: 710px;
        width: 100%;
        background-position: center;
    }

    .howitworks__process {

        @media (max-width: 768px) {
            height: 500px;
        }

        @media (max-width: 420px) {
            margin-top: 110px;
        }

        .process__title {
            margin-bottom: 40px;

            @media (max-width: 768px) {
                position: relative;
                text-align: center;
                right: auto;
                display: none;
            }
        }

        .process__container {
            @media (max-width: 768px) {
                display: none;
            }
        }

        .process__union_block {
            display: flex;
            justify-content: center;
            align-items: center;
            scroll-snap-align: start;
            position: relative;
        }

        .process__union {
            position: relative;
            border-top-right-radius: 50px;
            border-top-left-radius: 50px;
            display: flex;
            align-items: flex-start;
            justify-content: center;

            @media (max-width: 768px) {
                display: none;
            }

            .process__slider {
                width: 540px;
                margin-right: 120px;
                margin-left: -50px;

                .slider__box {
                    position: relative;

                    .slider__first-photo {
                        position: absolute;
                        left: 65px;
                        top: 45px;
                        width: 255px;
                        height: 300.81px;
                        opacity: 0.2;
                        transform: rotate(-24deg);

                        .slider__photo-border {
                            position: absolute;
                            left: 0px;
                            top: 0px;
                            background: url(../../../../assets/img/item-photo-border.png);
                            background-size: cover;
                            overflow: hidden;
                            width: 255px;
                            height: 300.81px;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-top-right-radius: 27px;
                        }
                    }

                    .slider__second-photo {
                        position: absolute;
                        left: 140px;
                        top: 170px;
                        width: 334px;
                        height: 395px;
                        z-index: 1;

                        .slider__photo-border {
                            position: absolute;
                            left: 0px;
                            top: 0px;
                            background: url(../../../../assets/img/item-photo-border.png);
                            background-size: cover;
                            overflow: hidden;
                            width: 334px;
                            height: 395px;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-top-right-radius: 27px;
                            border-bottom-left-radius: 67px;
                        }
                    }

                    .slider__third-photo {
                        position: absolute;
                        left: 355px;
                        top: 345px;
                        width: 255px;
                        height: 300.81px;
                        opacity: 0.2;
                        transform: rotate(30deg);

                        .slider__photo-border {
                            position: absolute;
                            left: 0px;
                            top: 0px;
                            background: url(../../../../assets/img/item-photo-border.png);
                            background-size: cover;
                            overflow: hidden;
                            width: 255px;
                            height: 300.81px;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .slider__photo_number {
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: 0px;
                        transform: translateX(-50%);
                        background: url(../../../../assets/img/block-photo-box.svg);
                        width: 45.65px;
                        height: 38.83px;
                        background-size: cover;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 142%;
                        text-align: center;
                        color: #f955ff;
                    }

                    .process__box {
                        margin-top: 210px;

                        .howitworks__number {
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            bottom: 0px;
                            transform: translateX(-50%);
                            background: url(../../../../assets/img/block-photo-box.svg);
                            width: 89px;
                            height: 76.9px;
                            background-size: cover;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 126%;
                            text-align: center;
                            color: #f955ff;
                        }

                        .cart_container {
                            opacity: 0;
                            margin: 20px 0;
                            position: relative;
                        }

                        .cart {
                            height: 435px;
                            width: 350px;
                            position: absolute;
                            top: 0;
                            left: calc(50% - 150px);
                            overflow: hidden;
                            cursor: pointer;
                            border-radius: 10px;
                            padding: 10px;
                            z-index: 100;

                            .howitworks__box_mask {
                                position: relative;
                                -webkit-mask-image: url(../../../../assets/img/howitworks-box-mask.svg);
                                mask-image: url(../../../../assets/img/howitworks-box-mask.svg);
                                -webkit-mask-repeat: round;
                                mask-repeat: round;
                                height: 420px;
                                width: 350px;

                                &::after {
                                    position: absolute;
                                    content: "";
                                    left: -14px;
                                    top: -10px;
                                    height: 446px;
                                    width: 378px;
                                    background: url(../../../../assets/img/howitworks-box-border.svg);
                                    background-repeat: no-repeat;
                                    background-size: contain;
                                    background-position: top;
                                    z-index: 11111;
                                }

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }

                        .cart__0 {
                            transition: 0.5s;
                            filter: brightness(0%);
                        }

                        .cart__10 {
                            transition: 0.5s;
                            filter: brightness(10%);
                        }

                        .cart__30 {
                            transition: 0.5s;
                            filter: brightness(30%);
                        }

                        .cart__50 {
                            transition: 0.5s;
                            filter: brightness(50%);
                        }

                        .cart__100 {
                            transition: 0.5s;
                            filter: brightness(100%);
                        }

                        .cart:nth-child(1) {
                            transform: rotate(-3deg);
                            position: relative;
                            // filter: brightness(10%);
                        }

                        .cart:nth-child(2) {
                            transform: rotate(4deg);
                            // filter: brightness(30%);
                        }

                        .cart:nth-child(3) {
                            transform: rotate(10deg);
                            // filter: brightness(50%);
                        }

                        .cart:nth-child(4) {
                            transform: rotate(-6deg);
                            // filter: brightness(100%);
                        }

                        .cart img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }

                        .bottom {
                            z-index: 50;
                            animation: move ease-in-out 1s;
                        }

                        .scaleCart {
                            animation: scaleCart ease-in-out 1s;
                        }

                        .bottom1 {
                            filter: brightness(100%) !important;
                        }

                        .bottom_last {
                            z-index: 30;
                            animation: move_last ease-in-out 1s;
                        }

                        @keyframes move {
                            0% {
                                left: calc(50% - 150px);
                                z-index: 150;
                            }

                            50% {
                                left: calc(50% - 525px);
                            }

                            100% {
                                left: calc(50% - 150px);
                                z-index: 50;
                            }
                        }

                        @keyframes scaleCart {
                            0% {
                                transform: scale(1);
                            }

                            100% {
                                transform: scale(1.4);
                            }
                        }

                        @keyframes move_last {
                            0% {
                                left: calc(50% - 150px);
                                z-index: 150;
                            }

                            50% {
                                left: calc(50% - 525px);
                                z-index: 50;
                            }

                            100% {
                                left: calc(50% - 150px);
                                z-index: 30;
                            }
                        }
                    }
                }
            }

            .howitworks__exit {
                width: 100%;
                height: 100px;
                background: #ccc;
                position: absolute;
                left: 0;
                bottom: 0;
            }

            .process__block {
                width: 492px;
                margin-top: 85px;

                .process__items {

                    .process__item {
                        margin-bottom: 40px;
                        cursor: pointer;
                        opacity: 0;

                        .process__name {
                            position: relative;
                            font-family: 'Space Grotesk';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 17px;
                            line-height: 126%;
                            color: #FFFFFF;

                            .process__default {
                                font-family: 'Space Grotesk';
                                font-style: normal;
                                font-weight: 500;
                                font-size: 17px;
                                line-height: 126%;
                                text-align: center;
                                color: #B8B8BB;
                                margin-right: 5px;
                            }

                            .process__ico_default {
                                background: url(../../../../assets/img/item-default.svg);
                                background-position: center;
                                background-size: cover;
                                width: 65px;
                                height: 40.24px;
                                position: absolute;
                                left: -61px;
                                top: -9px;

                                &::after {
                                    position: absolute;
                                    content: "";
                                    left: 31px;
                                    top: 45px;
                                    width: 1px;
                                    height: 94px;
                                    background: url(../../../../assets//img/process__ico_scale-default.svg);
                                    background-repeat: no-repeat;
                                    background-size: cover;
                                }
                            }
                        }

                        .process__description {
                            margin-top: 3px;
                        }

                        &:last-child {
                            .process__name {
                                .process__ico_active {
                                    &::after {
                                        display: none;
                                    }
                                }

                                .process__ico_default {
                                    &::after {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                    .process__item_active {

                        .process__active {
                            font-family: 'Space Grotesk';
                            font-style: normal;
                            font-weight: 500 !important;
                            font-size: 17px !important;
                            line-height: 126% !important;
                            text-align: center !important;
                            background: linear-gradient(180deg, #8875FB 0%, #FC64FC 88.54%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text !important;
                            text-fill-color: transparent !important;
                            text-shadow: 0px 0px 11px rgba(252, 100, 252, 0.4) !important;
                            margin-right: 5px !important;
                        }

                        .process__ico_active {
                            background: url(../../../../assets/img/item-active.svg) !important;
                            background-position: center;
                            background-size: cover !important;
                            width: 65px !important;
                            height: 51.24px !important;
                            position: absolute;
                            left: -61px !important;
                            top: -19px !important;

                            &::after {
                                position: absolute;
                                content: "";
                                left: 31px !important;
                                top: 45px !important;
                                width: 1px !important;
                                height: 94px !important;
                                background: url(../../../../assets//img/process__ico_scale-active.svg) !important;
                                background-repeat: no-repeat !important;
                                background-size: cover !important;
                            }

                            &:last-child {
                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        .process__union_mob {

            .slick-slider {
                opacity: 0;
            }

            @media (min-width: 769px) {
                display: none;
                overflow: hidden;
            }

            .process__title {
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;

                #interText__information {
                    margin-top: 0;
                }

                .interText__line {
                    bottom: -10px;
                }

                // @media (max-width: 768px) {
                //     margin-top: 0px;
                //     color: rgb(214, 111, 252) !important;
                //     -webkit-background-clip: text;
                //     -webkit-text-fill-color: currentcolor;
                //     background-clip: text;
                // }
            }

            .carouselBlock {

                .block {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    margin: 0 30px;

                    .block__photo {
                        position: relative;
                        width: 255px;
                        height: 300.81px;

                        .block__photo_mask {
                            position: relative;
                            -webkit-mask-image: url(../../../../assets/img/howitworks-box-mask.svg);
                            mask-image: url(../../../../assets/img/howitworks-box-mask.svg);
                            -webkit-mask-repeat: round;
                            mask-repeat: round;
                            width: 255px;
                            height: 300.81px;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }

                            &::after {
                                position: absolute;
                                content: "";
                                left: -10px;
                                top: -10px;
                                width: 275px;
                                height: 318.81px;
                                background: url(../../../../assets/img/howitworks-box-border.svg);
                                background-repeat: no-repeat;
                                background-size: cover;
                                background-position: top;
                                z-index: 11111;
                            }
                        }
                    }


                    .block__photo_box {
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: -14px;
                        transform: translateX(-50%);
                        background: url(../../../../assets/img/block-photo-box.svg);
                        width: 50.65px;
                        height: 43.83px;
                        background-size: cover;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 131%;
                        text-align: center;
                        color: #f955ff;
                    }
                }

                .block__title {
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 126%;
                    text-align: center;
                    background: linear-gradient(180deg, #8875FB 0%, #FC64FC 88.54%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                    text-shadow: 0px 0px 11px rgba(252, 100, 252, 0.4);
                    margin-top: 40px;
                    width: 180px;
                    overflow-y: scroll;
                    white-space: nowrap;

                    &::-webkit-scrollbar {
                        width: 0px;
                        background: transparent;
                        height: 0px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #EEEFF5;
                    }

                    span {
                        font-family: 'Space Grotesk';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 17px;
                        line-height: 126%;
                        color: #FFFFFF;
                        background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
                        -webkit-background-clip: text;
                        margin-left: 5px;
                    }
                }

                .block__description {
                    font-weight: 300;
                    font-size: 15px;
                    line-height: 148%;
                    text-align: center;
                    color: rgba(255, 255, 255, 0.7);
                    margin-top: 8px;
                }
            }
        }

        @media (max-width: 768px) {

            .slick-dots {
                bottom: -70px;

                @media (max-width: 420px) {
                    bottom: -50px;
                }
            }

            @media (max-width: 420px) {
                .slick-slide {
                    transition: 0.5s;
                    opacity: 0.2;
                }

                .slick-active {
                    transition: 0.5s;
                    opacity: 1;
                }
            }

            .slick-dots li {
                margin: 0px 12px;
            }

            .slick-dots button {
                box-sizing: border-box;
                padding: 0;
                -webkit-transition: all 250ms ease;
                transition: all 250ms ease;
                border: none;
                background-color: transparent;
                content: "";
                box-shadow: none;
                border-radius: 0%;
                font-size: 0;
                outline: none;
                width: 31px;
                height: 2px;
                background: rgba(255, 255, 255, 0.12);

                &::after {
                    display: none;
                }

                &::before {
                    display: none;
                }
            }

            .slick-active button {
                box-sizing: border-box;
                padding: 0;
                -webkit-transition: all 250ms ease;
                transition: all 250ms ease;
                border: none;
                margin-bottom: 0;
                content: "";
                height: 2px;
                width: 31px;
                border-radius: 0%;
                outline: none;
                background: linear-gradient(90.03deg, #8875FB -30.77%, #FC64FC 46.72%, #FE9040 121.05%);
            }
        }
    }
}