.progress {
  position: relative;
  background: url(../../../../assets/img/progress-back.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  background-position: center;
  height: 727px;
  padding-top: 14px;
  width: 1920px;

  @media (min-width: 1600px) and (max-width: 1650px) and (max-height: 1085px) {
    width: auto;
    height: 650px;
  }

  @media (max-width: 1440px) {
    width: 1395px;
    margin: 0 auto;
    height: 691px;
    background: url(../../../../assets/img/progress-back-1440.webp);
    background-repeat: no-repeat;
    background-position: center;
  }

  @media (max-width: 768px) {
    width: 100%;
    background: url(../../../../assets/img/progress-back-768.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 468px;
  }

  @media (max-width: 420px) {
    background: url(../../../../assets/img/progress-back-mobile.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 553px;
    width: 100%;
    margin-top: 95px;
  }

  @media (max-width: 420px) and (min-width: 414px) {
    height: 590px;
  }

  .progress__name {
    position: relative;
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 126%;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #fc64fc;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 232px;
    height: 63px;
    padding-top: 25px;

    @media (max-width: 768px) {
      padding-top: 10px;
    }

    .progress__name_mark {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 232px;
      height: 63px;
      background: url(../../../../assets/img/road-map-back.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
      opacity: 0.3;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .progress__title {
    width: 553px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 80px;
    height: 116px;

    @media (min-width: 1600px) and (max-width: 1650px) and (max-height: 1085px) {
      height: 45px;
    }

    @media (max-width: 1440px) {
      height: 104px;
    }

    @media (max-width: 768px) {
      margin-bottom: 25px;
      height: auto;
    }

    @media (max-width: 420px) {
      width: 100%;
      font-weight: 450;
      font-size: 28px;
      line-height: 116%;
      margin-bottom: 35px;
    }

    #interText__information {
      @media (max-width: 768px) {
        color: rgb(214, 111, 252) !important;
        -webkit-background-clip: text;
        -webkit-text-fill-color: currentcolor;
        background-clip: text;
      }
    }
  }

  .progress__carousel-box {
    position: relative;
    $n: 8;
    $item-width: 400px;
    $item-separation: 80px;
    $viewer-distance: 500px;
    $theta: 2 * 3.141592653589793 / $n;
    $apothem: 482.842712474619px;
    opacity: 0;
    z-index: 11;

    .carousel {
      perspective: $viewer-distance;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;

      > * {
        flex: 0 0 auto;
      }

      figure {
        margin: 0;

        width: $item-width;
        transform-style: preserve-3d;
        transition: transform 0.5s;
        transform-origin: 50% 50% (-$apothem);

        .progress__block {
          width: 100%;
          box-sizing: border-box;
          padding: 0 $item-separation / 2;

          &:not(:first-of-type) {
            position: absolute;
            left: 0;
            top: 0;
            transform-origin: 50% 50% (-$apothem);
          }

          @for $i from 2 through $n {
            &:nth-child(#{$i}) {
              transform: rotateY(#{($i - 1) * $theta}rad);
            }
          }
        }
      }

      .control {
        position: absolute;
        left: 66%;
        transform: translateX(-50%);
        bottom: 8%;
        display: flex;
        justify-content: center;
        background: rgba(252, 100, 252, 0.01);
        border: 1px solid rgba(252, 100, 252, 0.2);
        backdrop-filter: blur(7px);
        width: 96px;
        height: 96px;
        border-radius: 50%;
        z-index: 1111;

        @media (max-width: 768px) {
          left: 77%;
          bottom: 11%;
        }

        @media (max-width: 420px) {
          left: 80%;
          bottom: 1%;
        }

        &::after {
          position: absolute;
          content: '';
          left: 50%;
          top: 46%;
          transform: translate(-50%, 50%);
          width: 4px;
          height: 4px;
          background: url(../../../../assets/img/control-point.svg);
          background-repeat: no-repeat;
          background-size: cover;
        }

        .control__prev {
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translateY(-50%);
          background: url(../../../../assets/img/control-left.svg);
          background-repeat: no-repeat;
          background-size: contain;
          width: 18px;
          height: 12px;
          cursor: pointer;
          transition: 0.5s;
          opacity: 0.7;
          filter: drop-shadow(0px 0px 8px rgba(136, 117, 251, 0.5));

          &:hover {
            opacity: 1;
          }
        }

        .control__next {
          position: absolute;
          top: 50%;
          right: 0px;
          transform: translateY(-50%);
          background: url(../../../../assets/img/control-right.svg);
          background-repeat: no-repeat;
          background-size: contain;
          width: 18px;
          height: 12px;
          cursor: pointer;
          opacity: 0.7;
          transition: 0.5s;
          filter: drop-shadow(0px 0px 8px rgba(136, 117, 251, 0.5));

          &:hover {
            opacity: 1;
          }
        }

        button {
          background: transparent;
          border: 0px;
        }
      }
    }

    .addOpacity {
      transition: 1s;
      opacity: 0.1 !important;
      will-change: transform;
    }

    .progress__block {
      display: flex;
      justify-content: center;

      .block {
        position: relative;
        background: url(../../../../assets/img/progress-block-back.svg);
        background-size: cover;
        background-position: top;
        width: 267.5px;
        height: 313.5px;
        background-position: 0px 0px;
        margin-top: 3px;

        @media (max-width: 768px) {
          width: 225px;
          height: 264px;
        }

        .block__data {
          padding-left: 30px;
          font-family: 'Space Grotesk';
          font-style: normal;
          font-weight: 450;
          font-size: 40px;
          line-height: 116%;
          color: #8875fb;
          margin-top: 18px;

          @media (max-width: 768px) {
            font-weight: 450;
            font-size: 32px;
            line-height: 116%;
            padding-left: 23px;
          }

          span {
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 500;
            font-size: 40px;
            line-height: 126%;
            text-align: center;
            color: #ffffff;
            text-shadow: 0px 0px 11px rgba(136, 117, 251, 0.49);
            margin-left: 1px;

            @media (max-width: 768px) {
              font-weight: 450;
              font-size: 32px;
              line-height: 116%;
            }
          }
        }

        .block__steps {
          padding-left: 30px;
          position: relative;
          margin-top: 17px;

          @media (max-width: 768px) {
            padding-left: 23px;
          }

          .block__steps-line {
            position: absolute;
            left: 32px;
            top: 0;
            width: 1px;
            height: 182.5px;
            background: #0d0e12;

            @media (max-width: 768px) {
              left: 26px;
              height: 160.5px;
            }

            .line-point-default-1 {
              position: absolute;
              left: -2.7px;
              top: 16px;
              background: #0d0e12;
              width: 6px;
              height: 6px;
              border-radius: 50%;
            }

            .line-point-default-2 {
              position: absolute;
              left: -2.7px;
              top: 64px;
              background: #0d0e12;
              width: 6px;
              height: 6px;
              border-radius: 50%;

              @media (max-width: 768px) {
                top: 54px;
              }
            }

            .line-point-default-3 {
              position: absolute;
              left: -2.7px;
              top: 115px;
              background: #0d0e12;
              width: 6px;
              height: 6px;
              border-radius: 50%;

              @media (max-width: 768px) {
                top: 95px;
              }
            }

            .line-point-default-4 {
              position: absolute;
              left: -2.7px;
              top: 165px;
              background: #0d0e12;
              width: 6px;
              height: 6px;
              border-radius: 50%;

              @media (max-width: 768px) {
                top: 136px;
              }
            }

            .line-active-1 {
              position: absolute;
              left: 0;
              top: 0;
              width: 1px;
              height: 21px;
              background: #8875fb;

              .line-point-active-1 {
                position: absolute;
                left: -2.7px;
                top: 16px;
                background: #8875fb;
                width: 6px;
                height: 6px;
                border-radius: 50%;
              }
            }

            .line-active-2 {
              position: absolute;
              left: 0;
              top: 0;
              width: 1px;
              height: 65px;
              background: #8875fb;

              @media (max-width: 768px) {
                height: 59px;
              }

              .line-point-active-1 {
                position: absolute;
                left: -2.7px;
                top: 16px;
                background: #8875fb;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                z-index: 1;
              }

              .line-point-active-2 {
                position: absolute;
                left: -2.7px;
                top: 64px;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #8875fb;
                z-index: 1;

                @media (max-width: 768px) {
                  top: 54px;
                }
              }
            }

            .line-active-3 {
              position: absolute;
              left: 0;
              top: 0;
              width: 1px;
              height: 116px;
              background: #8875fb;

              @media (max-width: 768px) {
                height: 161px;
              }

              .line-point-active-1 {
                position: absolute;
                left: -2.7px;
                top: 16px;
                background: #8875fb;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                z-index: 1;
              }

              .line-point-active-2 {
                position: absolute;
                left: -2.7px;
                top: 64px;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #8875fb;
                z-index: 1;

                @media (max-width: 768px) {
                  top: 54px;
                }
              }

              .line-point-active-3 {
                position: absolute;
                left: -2.7px;
                top: 115px;
                background: #8875fb;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                z-index: 1;

                @media (max-width: 768px) {
                  top: 135px;
                }
              }
            }

            .line-active-4 {
              position: absolute;
              left: 0;
              top: 0;
              width: 1px;
              height: 185px;
              background: #8875fb;

              @media (max-width: 768px) {
                height: 162px;
              }

              .line-point-active-1 {
                position: absolute;
                left: -2.7px;
                top: 16px;
                background: #8875fb;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                z-index: 1;
              }

              .line-point-active-2 {
                position: absolute;
                left: -2.7px;
                top: 64px;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #8875fb;
                z-index: 1;

                @media (max-width: 768px) {
                  top: 54px;
                }
              }

              .line-point-active-3 {
                position: absolute;
                left: -2.7px;
                top: 115px;
                background: #8875fb;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                z-index: 1;

                @media (max-width: 768px) {
                  top: 95px;
                }
              }

              .line-point-active-4 {
                position: absolute;
                left: -2.7px;
                top: 165px;
                width: 6px;
                height: 6px;
                background: #8875fb;
                border-radius: 50%;
                z-index: 1;

                @media (max-width: 768px) {
                  top: 136px;
                }
              }
            }

            .line-point-active {
              background: #8875fb;
            }
          }

          .block__step {
            .step__name {
              font-family: 'Space Grotesk';
              font-style: normal;
              font-weight: 300;
              font-size: 13px;
              line-height: 136%;
              color: #ffffff;
              width: 180px;
              margin-left: 18px;
              margin-bottom: 15px;
              height: 36px;

              @media (max-width: 768px) {
                font-weight: 300;
                font-size: 12px;
                line-height: 136%;
                margin-bottom: 7px;
              }
            }
          }
        }
      }
    }
  }

  .progress__bar {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    opacity: 0;

    .check {
      position: absolute;
      left: 50.3%;
      transform: translateX(-50%);
      bottom: 38px;
      background: url(../../../../assets/img/check-arrow.svg);
      background-repeat: no-repeat;
      background-size: contain;
      mix-blend-mode: lighten;
      width: 39px;
      height: 28px;

      @media (max-width: 768px) {
        bottom: 15px;
      }

      @media (max-width: 420px) {
        bottom: 5px;
      }

      @media (max-width: 375px) {
        bottom: 13px;
      }

      &::after {
        position: absolute;
        content: '';
        left: -16px;
        top: -110px;
        width: 71px;
        height: 139px;
        background: url(../../../../assets/img/check-arrow-glow.svg);
        background-repeat: no-repeat;
        background-size: cover;
        mix-blend-mode: inherit;
        z-index: 1;
      }
    }

    .count {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 20px;
      display: flex;
      align-items: center;
      width: 310px;

      @media (max-width: 768px) {
        width: 185px;
        bottom: 14px;
      }

      @media (max-width: 420px) {
        width: 150px;
        bottom: 4px;
      }

      .count__box {
        width: 244px;
        height: 1px;
        background: #311f31;
        margin: 0 auto;

        .count__line_active {
          transition: 1.1s;
          background: #ee6aee;
          height: 1px;
          width: 0;
        }
      }

      .count__number {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 450;
        font-size: 16px;
        line-height: 116%;
        text-align: center;
        background: linear-gradient(100.16deg, #8875fb 8.5%, #fc64fc 87.65%, #fe9040 159.63%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-left: 15px;

        @media (max-width: 768px) {
          font-weight: 450;
          font-size: 13px;
          line-height: 116%;
        }

        @media (max-width: 420px) {
          font-size: 11px;
        }
      }

      .count__name {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 12px;
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 450;
        font-size: 12px;
        line-height: 116%;
        text-align: center;
        background: linear-gradient(100.16deg, #8875fb 8.5%, #fc64fc 87.65%, #fe9040 159.63%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }

  .progress__slider {
    position: relative;
    z-index: 1;
    opacity: 0;

    .slider {
      position: relative;
      width: 990px;
      margin: 0 auto;
      z-index: 1;

      @media (max-width: 768px) {
        width: 738px;
      }

      @media (max-width: 420px) {
        width: 100%;
        margin-top: 50px;
      }

      .slider__block {
        .progress__block {
          display: flex;
          justify-content: center;

          .block {
            position: relative;
            background: url(../../../../assets/img/progress-block-back.svg);
            background-size: cover;
            background-position: top;
            width: 267.5px;
            height: 313.5px;
            background-position: 0px 0px;
            margin-top: 3px;

            @media (max-width: 768px) {
              width: 225px;
              height: 264px;
            }

            .block__data {
              padding-left: 30px;
              font-family: 'Space Grotesk';
              font-style: normal;
              font-weight: 450;
              font-size: 40px;
              line-height: 116%;
              color: #8875fb;
              margin-top: 18px;

              @media (max-width: 768px) {
                font-weight: 450;
                font-size: 32px;
                line-height: 116%;
                padding-left: 23px;
              }

              span {
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 500;
                font-size: 40px;
                line-height: 126%;
                text-align: center;
                color: #ffffff;
                text-shadow: 0px 0px 11px rgba(136, 117, 251, 0.49);
                margin-left: 1px;

                @media (max-width: 768px) {
                  font-weight: 450;
                  font-size: 32px;
                  line-height: 116%;
                }
              }
            }

            .block__steps {
              padding-left: 30px;
              position: relative;
              margin-top: 17px;

              @media (max-width: 768px) {
                padding-left: 23px;
              }

              .block__steps-line {
                position: absolute;
                left: 32px;
                top: 0;
                width: 1px;
                height: 182.5px;
                background: #0d0e12;

                @media (max-width: 768px) {
                  left: 26px;
                  height: 160.5px;
                }

                .line-point-default-1 {
                  position: absolute;
                  left: -2.7px;
                  top: 16px;
                  background: #0d0e12;
                  width: 6px;
                  height: 6px;
                  border-radius: 50%;
                }

                .line-point-default-2 {
                  position: absolute;
                  left: -2.7px;
                  top: 64px;
                  background: #0d0e12;
                  width: 6px;
                  height: 6px;
                  border-radius: 50%;

                  @media (max-width: 768px) {
                    top: 54px;
                  }
                }

                .line-point-default-3 {
                  position: absolute;
                  left: -2.7px;
                  top: 115px;
                  background: #0d0e12;
                  width: 6px;
                  height: 6px;
                  border-radius: 50%;

                  @media (max-width: 768px) {
                    top: 95px;
                  }
                }

                .line-point-default-4 {
                  position: absolute;
                  left: -2.7px;
                  top: 165px;
                  background: #0d0e12;
                  width: 6px;
                  height: 6px;
                  border-radius: 50%;

                  @media (max-width: 768px) {
                    top: 136px;
                  }
                }

                .line-active-1 {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 1px;
                  height: 21px;
                  background: #8875fb;

                  .line-point-active-1 {
                    position: absolute;
                    left: -2.7px;
                    top: 16px;
                    background: #8875fb;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                  }
                }

                .line-active-2 {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 1px;
                  height: 65px;
                  background: #8875fb;

                  @media (max-width: 768px) {
                    height: 59px;
                  }

                  .line-point-active-1 {
                    position: absolute;
                    left: -2.7px;
                    top: 16px;
                    background: #8875fb;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    z-index: 1;
                  }

                  .line-point-active-2 {
                    position: absolute;
                    left: -2.7px;
                    top: 64px;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background: #8875fb;
                    z-index: 1;

                    @media (max-width: 768px) {
                      top: 54px;
                    }
                  }
                }

                .line-active-3 {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 1px;
                  height: 116px;
                  background: #8875fb;

                  @media (max-width: 768px) {
                    height: 161px;
                  }

                  .line-point-active-1 {
                    position: absolute;
                    left: -2.7px;
                    top: 16px;
                    background: #8875fb;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    z-index: 1;
                  }

                  .line-point-active-2 {
                    position: absolute;
                    left: -2.7px;
                    top: 64px;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background: #8875fb;
                    z-index: 1;

                    @media (max-width: 768px) {
                      top: 54px;
                    }
                  }

                  .line-point-active-3 {
                    position: absolute;
                    left: -2.7px;
                    top: 115px;
                    background: #8875fb;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    z-index: 1;

                    @media (max-width: 768px) {
                      top: 135px;
                    }
                  }
                }

                .line-active-4 {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 1px;
                  height: 185px;
                  background: #8875fb;

                  @media (max-width: 768px) {
                    height: 162px;
                  }

                  .line-point-active-1 {
                    position: absolute;
                    left: -2.7px;
                    top: 16px;
                    background: #8875fb;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    z-index: 1;
                  }

                  .line-point-active-2 {
                    position: absolute;
                    left: -2.7px;
                    top: 64px;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background: #8875fb;
                    z-index: 1;

                    @media (max-width: 768px) {
                      top: 54px;
                    }
                  }

                  .line-point-active-3 {
                    position: absolute;
                    left: -2.7px;
                    top: 115px;
                    background: #8875fb;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    z-index: 1;

                    @media (max-width: 768px) {
                      top: 95px;
                    }
                  }

                  .line-point-active-4 {
                    position: absolute;
                    left: -2.7px;
                    top: 165px;
                    width: 6px;
                    height: 6px;
                    background: #8875fb;
                    border-radius: 50%;
                    z-index: 1;

                    @media (max-width: 768px) {
                      top: 136px;
                    }
                  }
                }

                .line-point-active {
                  background: #8875fb;
                }
              }

              .block__step {
                .step__name {
                  font-family: 'Space Grotesk';
                  font-style: normal;
                  font-weight: 300;
                  font-size: 13px;
                  line-height: 136%;
                  color: #ffffff;
                  width: 180px;
                  margin-left: 18px;
                  margin-bottom: 25px;

                  @media (max-width: 768px) {
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 136%;
                    margin-bottom: 18px;
                  }
                }
              }
            }

            .step__name_second {
              margin-bottom: 12px !important;
              width: 171px !important;
            }
          }
        }
      }

      .slick-slide {
        opacity: 0.1;
        transition: 1s;
      }

      .slick-center {
        opacity: 1;
        transition: 1s;
      }

      .slick-prev:before,
      .slick-next:before {
        font-family: none;
        content: '';
        font-size: 20px;
        line-height: 1;
        opacity: 1;
        color: white;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      .slick-prev,
      .slick-next {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: 50%;
        display: block;
        width: 40px;
        height: 35px;
        padding: 0;
        transform: translate(0, -50%);
        cursor: pointer;
        color: transparent;
        border: none;
        outline: none;
        background: transparent;
        display: none !important;
      }

      .slick-prev {
        opacity: 1;
        z-index: 1;

        @media (max-width: 768px) {
          left: -5px;
        }

        &::before {
          position: absolute;
          right: 21px;
          top: 10px;
          width: 2px;
          height: 20px;
          background: linear-gradient(4.92deg, #8875fb 14.39%, #fc64fc 80.53%, #f79238 158.7%);
          transform: rotate(305deg);
        }

        &::after {
          position: absolute;
          content: '';
          right: 21px;
          top: 0px;
          width: 2px;
          height: 20px;
          background: linear-gradient(4.92deg, #8875fb 14.39%, #fc64fc 80.53%, #f79238 158.7%);
          transform: rotate(64deg);
        }

        &:hover {
          transition: 0.5s;
          opacity: 1;
        }
      }

      .slick-next {
        opacity: 1;

        @media (max-width: 768px) {
          right: -5px;
        }

        &::before {
          position: absolute;
          right: 16px;
          top: 10px;
          width: 2px;
          height: 20px;
          background: linear-gradient(4.92deg, #8875fb 14.39%, #fc64fc 80.53%, #f79238 158.7%);
          transform: rotate(61deg);
        }

        &::after {
          position: absolute;
          content: '';
          right: 16px;
          top: 0px;
          width: 2px;
          height: 20px;
          background: linear-gradient(4.92deg, #8875fb 14.39%, #fc64fc 80.53%, #f79238 158.7%);
          transform: rotate(122deg);
        }

        &:hover {
          transition: 0.5s;
          opacity: 1;
        }
      }

      .control {
        position: absolute;
        left: 72%;
        transform: translateX(-50%);
        bottom: 12%;
        display: flex;
        justify-content: center;
        background: rgba(252, 100, 252, 0.01);
        border: 1px solid rgba(252, 100, 252, 0.2);
        backdrop-filter: blur(7px);
        width: 96px;
        height: 96px;
        border-radius: 50%;
        z-index: 1111;

        @media (max-width: 768px) {
          left: 77%;
          bottom: 11%;
        }

        @media (max-width: 420px) {
          left: 85%;
          bottom: -12%;
        }

        &::after {
          position: absolute;
          content: '';
          left: 50%;
          top: 46%;
          transform: translate(-50%, 50%);
          width: 4px;
          height: 4px;
          background: #95439e;
          background-repeat: no-repeat;
          background-size: cover;
        }

        .progress__control_prev {
          position: absolute;
          top: 50%;
          left: 4px;
          transform: translateY(-50%);
          background: url(../../../../assets/img/control-left.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: left;
          width: 15px;
          height: 18px;
          cursor: pointer;
          transition: 0.5s;
          opacity: 0.7;

          @media (max-width: 420px) {
            width: 13px;
            height: 13px;
          }

          &:hover {
            opacity: 1;
          }
        }

        .progress__control_prev-default {
          position: absolute;
          top: 50%;
          left: 4px;
          transform: translateY(-50%);
          background: url(../../../../assets/img/control-left.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: left;
          width: 15px;
          height: 18px;
          cursor: pointer;
          transition: 0.5s;
          opacity: 0.7;

          @media (max-width: 420px) {
            width: 13px;
            height: 13px;
          }

          &:hover {
            opacity: 1;
          }
        }

        .progress__control_next {
          position: absolute;
          top: 50%;
          right: 4px;
          transform: translateY(-50%);
          background: url(../../../../assets/img/control-right.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: right;
          width: 15px;
          height: 17px;
          cursor: pointer;
          opacity: 0.7;
          transition: 0.5s;

          @media (max-width: 420px) {
            width: 8px;
            height: 12px;
          }

          &:hover {
            opacity: 1;
          }
        }

        button {
          background: transparent;
          border: 0px;
        }
      }
    }
  }
}
