.socials {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 354px;
    margin: 0 auto;

    @media (max-width: 420px) {
        width: 300px;
    }

    .socials__item {
        position: relative;
        background: #10101B;
        border: 1px solid #2C2C37;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 66px;
        height: 66px;
        border-radius: 50%;
        cursor: pointer;
        transition: 0.5s;

        @media (max-width: 768px) {
            width: 56px;
            height: 56px;
        }

        svg {
            width: 20px;
            height: 20px;
        }

        &::after {
            position: absolute;
            left: 50%;
            top: 50%;
            content: '';
            transform: translate(-50%, -50%);
            transition: 0.5s;
            background: url(../../assets/img/social-hover.svg);
            background-size: cover;
            background-repeat: no-repeat;
            width: 68px;
            height: 68px;
            background-position: 0px 0px;
            opacity: 0;
            z-index: -1;

            @media (max-width: 768px) {
                width: 58px;
                height: 58px;
            }
        }

        &:hover {
            border: 1px solid rgba(16, 16, 27, 0.02);
            transition: 0.5s;
            background: rgba(16, 16, 27, 0.02);

            &::after {
                opacity: 1;
            }

            svg {
                path {
                    transition: 0.5s;
                    fill: rgba(136, 117, 251, 1);
                }
            }
        }
    }
}