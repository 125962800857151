.offerAbout {
    background: url(../../../../assets//img/back-offer-about-1920.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 768.33px;

    @media (max-width: 1440px) {
        background: url(../../../../assets//img/back-offer-about-1440.png);
        background-repeat: no-repeat;
        height: 580.5px;
        z-index: 111;
    }

    @media (max-width: 768px) {
        background: url(../../../../assets//img/back-offer-about-tablet.png);
        height: 305.33px;
    }

    @media (max-width: 420px) {
        position: relative;
        background: url(../../../../assets//img/back-offer-about-mobile.png);
        background-position: center;
        height: 740px;
    }

    @media (max-width: 420px) {
        &::after {
            position: absolute;
            content: "";
            left: 50%;
            transform: translateX(-50%);
            bottom: 50px;
            width: 25px;
            height: 30px;
            background: url(../../../../assets//img/arrow-scroll.svg);
            background-repeat: no-repeat;
            background-size: cover;
            animation: animate 2s infinite;
        }

        @keyframes animate {
            0% {
                bottom: 80px;
            }

            50% {
                bottom: 60px;
            }

            100% {
                bottom: 80px;
            }
        }
    }

    .offerAbout__union {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1640px;
        height: 763.33px;
        margin: 0 auto;
        overflow: hidden;

        @media (max-width: 1440px) {
            width: 1161px;
            height: 572.5px;
        }

        @media (max-width: 768px) {
            width: 650px;
            height: 305.33px;
        }

        @media (max-width: 420px) {
            position: relative;
            width: 100%;
            height: auto;
            flex-direction: column;
        }

        .offerAbout__box {
            width: 50%;
            display: flex;
            flex-direction: column;

            @media (max-width: 420px) {
                height: auto;
                width: 100%;
                margin-top: 21px;
                order: 2;
            }

            .box {
                @media (max-width: 420px) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-top: 17px;
                }

                .box__name {
                    opacity: 0;

                    @media (max-width: 768px) {
                        padding-left: 30px;
                    }

                    @media (max-width: 420px) {
                        padding-left: 35px;
                        text-align: center;
                    }
                }

                .box__title {
                    font-family: 'Space Grotesk';
                    font-style: normal;
                    font-weight: 450;
                    font-size: 45px;
                    line-height: 116%;
                    text-transform: uppercase;
                    width: 444px;
                    margin-top: 18px;

                    @media (max-width: 1440px) {
                        margin-top: 0px;
                    }

                    @media (max-width: 768px) {
                        font-weight: 450;
                        font-size: 24px;
                        line-height: 116%;
                    }

                    @media (max-width: 420px) {
                        font-weight: 450;
                        font-size: 32px;
                        line-height: 116%;
                        width: 100%;
                        text-align: center;
                    }
                }

                .box__description {
                    margin-top: 15px;
                    width: 487px;
                    opacity: 0;

                    @media (max-width: 768px) {
                        width: 322px;
                    }

                    @media (max-width: 420px) {
                        width: 315px;
                        text-align: center;
                    }
                }
            }
        }

        .offerAbout__slider {
            position: relative;
            width: 50%;
            z-index: 1;
            height: 700px;
            overflow: hidden;
            margin-top: 67px;

            @media (max-width: 1440px) {
                height: 507px;
                margin-top: 78px;
            }

            @media (max-width: 768px) {
                height: 248px;
                margin-top: 52px;
            }

            @media (max-width: 420px) {
                margin-top: 95px;
                margin-left: 0px;
                width: 100%;
                order: 1;
                height: 275px;
            }

            &::after {
                position: absolute;
                content: "";
                left: 13%;
                top: 0px;
                width: 88%;
                height: 698px;
                background: linear-gradient(180deg, rgba(13, 14, 18, 0) 75.26%, #0d0e12 100%), linear-gradient(180deg, #0d0e12e6 5.59%, rgba(13, 14, 18, 0) 40.82%);

                @media (max-width: 1440px) {
                    height: 507px;
                    left: 15%;
                    top: 0px;
                    width: 82%;
                }

                @media (max-width: 768px) {
                    height: 250px;
                    left: 23%;
                    top: 0px;
                    width: 73%;
                }

                @media (max-width: 420px) {
                    display: none;
                }
            }

            .slider {
                display: flex;
                justify-content: flex-end;


                @media (max-width: 420px) {
                    transform: rotate(90deg);
                    z-index: 111;
                    height: 400px;
                    margin-top: -120px;
                    padding-top: 0;
                }

                .slider__second_block {
                    margin: 0px 20px;
                }

                .block {
                    margin-bottom: 26px;

                    @media (max-width: 768px) {
                        margin-bottom: 0;
                    }

                    @media (max-width: 420px) {
                        margin-top: 28px;
                        margin-right: -5px;
                    }

                    .block__photo {
                        position: relative;

                        &::after {
                            position: absolute;
                            content: "";
                            left: 0;
                            top: 0;
                            height: 261px;
                            width: 224px;
                            background: url(../../../../assets/img/block-photo-mask-border.svg);
                            background-repeat: no-repeat;
                            background-size: cover;

                            @media (max-width: 768px) {
                                height: 124px;
                                width: 105px;
                                left: 0px;
                                top: 5px;
                                background-size: contain;
                            }

                            @media (max-width: 420px) {
                                position: absolute;
                                content: "";
                                left: 0px;
                                top: 0px;
                                width: 140.56px;
                                height: 119.24px;
                                background: url(../../../../assets/img/block-photo-mask-border-mobile.svg);
                                background-repeat: no-repeat;
                                background-size: cover;
                                transform: rotate(270deg);
                            }
                        }

                        .block__photo_mask {
                            position: relative;
                            -webkit-mask-image: url(../../../../assets/img/join-mask-photo.svg);
                            mask-image: url(../../../../assets/img/join-mask-photo.svg);
                            -webkit-mask-repeat: round;
                            mask-repeat: round;
                            height: 261px;
                            width: 224px;

                            @media (max-width: 768px) {
                                height: 133px;
                                width: 104px;
                            }

                            @media (max-width: 420px) {
                                -webkit-mask-image: url(../../../../assets/img/join-mask-photo-mobile.svg);
                                mask-image: url(../../../../assets/img/join-mask-photo-mobile.svg);
                                -webkit-mask-repeat: round;
                                mask-repeat: round;
                                height: 121px;
                                width: 140px;
                                transform: rotate(270deg);
                            }

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;

                                @media (max-width: 768px) {
                                    width: 119.23px;
                                }

                                @media (max-width: 420px) {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }

                @media (max-width: 1440px) {
                    .slider__third_block {
                        display: none;
                    }
                }

                @media (min-width: 421px) {
                    .slider__fourth_block {
                        display: none;
                    }

                    .slider__fifth_block {
                        display: none;
                    }
                }

                @media (max-width: 420px) {
                    .slider__first_block {
                        display: none;
                    }

                    .slider__second_block {
                        display: none;
                    }

                    .slider__third_block {
                        display: none;
                    }
                }
            }

            .gallery-box {
                --gallery-box-gap: 32px;
                --gallery-box-logo-width: 208px;
                --gallery-box-logo-height: 163px;
            }

            @media (max-height: 756px)and (max-width: 1440px) {
                .gallery-box {
                    --gallery-box-logo-width: 174px;
                    --gallery-box-logo-height: 139px;
                }
            }

            @media (max-height: 740px)and (max-width: 420px) {
                .gallery-box {
                    --gallery-box-logo-width: 188px;
                    --gallery-box-logo-height: 153px;
                }
            }

            @media (max-height: 812px)and (max-width: 375px) {
                .gallery-box {
                    --gallery-box-logo-width: 222px;
                    --gallery-box-logo-height: 177px;
                }
            }

            .carousel-container.gallery-box__slider-first {
                animation: 425s linear 0s infinite normal none running carousel;
            }

            .carousel-container.gallery-box__slider-second {
                animation: 425s linear 0s infinite normal none running carousel-reverse;
            }

            @keyframes carousel {
                0% {
                    transform: translateY(0px);
                }

                100% {
                    transform: translateY(calc((-1) * (100% + var(--gallery-box-gap))));
                }
            }

            @keyframes carousel-reverse {
                0% {
                    transform: translateY(calc((-1) * (100% + var(--gallery-box-gap))));
                }

                100% {
                    transform: translateY(0px);
                }
            }

            .gallery-box-second {
                --gallery-box-second-gap: 32px;
                --gallery-box-second-logo-width: 208px;
                --gallery-box-second-logo-height: 163px;
            }

            @media (max-height: 756px)and (max-width: 1440px) {
                .gallery-box-second {
                    --gallery-box-second-logo-width: 174px;
                    --gallery-box-second-logo-height: 139px;
                }
            }

            @media (max-height: 740px)and (max-width: 420px) {
                .gallery-box-second {
                    --gallery-box-second-logo-width: 188px;
                    --gallery-box-second-logo-height: 153px;
                }
            }

            @media (max-height: 812px)and (max-width: 375px) {
                .gallery-box-second {
                    --gallery-box-second-logo-width: 222px;
                    --gallery-box-second-logo-height: 177px;
                }
            }

            .carousel-container.gallery-box-second__slider-first {
                animation: 425s ease-out 0s infinite normal none running carousel1;
            }

            .carousel-container.gallery-box-second__slider-second {
                animation: 425s ease-out 0s infinite normal none running carousel1-reverse;
            }

            @keyframes carousel1 {
                0% {
                    transform: translateY(calc((-1) * (70% - var(--gallery-box-second-gap))));
                }

                100% {
                    transform: translateY(0px);
                }
            }

            @keyframes carousel1-reverse {
                0% {
                    transform: translateY(calc((-1) * (70% - var(--gallery-box-second-gap))));
                }

                100% {
                    transform: translateY(0px);
                }
            }
        }
    }
}