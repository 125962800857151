.article {
    transition: 0.5s;
    cursor: pointer;

    .article__photo {
        transition: 0.5s;
        position: relative;
        height: 285.66px;
        width: 363px;
        margin-left: -5px;

        @media (max-width: 420px) {
            margin-left: 0;
        }

        &::after {
            position: absolute;
            z-index: 111;
            content: '';
            left: -7px;
            top: 0px;
            height: 302.66px;
            width: 381px;
            object-fit: cover;
            background: url(../../../assets/img/similars-photo-border.svg);
            overflow: hidden;
            z-index: 111;

            @media (max-width: 768px) {
                height: 203px;
                width: 240px;
                background: url(../../../assets/img/blog-article-photo-border-768.svg);
            }
        }

        @media (max-width: 1440px) {
            height: 265.66px;
            width: 363px;
        }

        @media (max-width: 768px) {
            height: 180px;
            width: 229px;
        }

        @media (max-width: 420px) {
            height: 188px;
            width: 100%;
            object-fit: cover;


            &::after {
                position: absolute;
                z-index: 111;
                content: '';
                left: -7px;
                top: 0px;
                height: 193px;
                width: 344px;
                background: url(../../../assets/img/blog-border-main-photo.svg);
                background-repeat: no-repeat;
            }
        }

        img {
            height: 300px;
            width: 371px;
            object-fit: cover;

            @media (max-width: 768px) {
                height: 195px;
                width: 230px;
            }

            @media (max-width: 420px) {
                height: 188px;
                width: 330px;
                object-fit: cover;
            }

            @media (max-width: 420px) and (min-width: 414px) {
                width: 330px;
            }
        }
    }

    .article__title {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 116%;
        color: #ffffff;
        margin-top: 32px;

        @media (max-width: 1440px) {
            font-weight: 700 !important;
            font-size: 26px !important;
            line-height: 116% !important;
            margin-top: 50px;
        }

        @media (max-width: 768px) {
            font-weight: 700;
            font-size: 18px !important;
            margin-top: 28px;
        }

        @media (max-width: 420px) and (min-width: 414px) {
            width: 330px;
        }
    }

    .article__description {
        margin: 10px 0px;
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 168%;
        color: #FFFFFF;

        @media (max-width: 1440px) {
            font-weight: 300 !important;
            font-size: 14px !important;
            line-height: 168% !important;
        }

        @media (max-width: 420px) and (min-width: 414px) {
            width: 330px;
        }
    }

    .article__button {
        cursor: pointer;

        @media (max-width: 1440px) {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
        }

        @media (max-width: 420px) {
            color: rgb(214, 111, 252) !important;
            -webkit-background-clip: text;
            -webkit-text-fill-color: currentcolor;
            background-clip: text;
        }
    }

    &:hover {
        transition: 0.5s;

        .article__title {
            color: #FC64FC;
            transition: 0.5s;
        }

        .article__description {
            color: #FC64FC;
            transition: 0.5s;

            @media (max-width: 420px) {
                width: 100%;
            }
        }

        .article__photo {
            transition: 0.5s;
        }
    }
}