.collection {
    position: relative;
    background: url(../../../../assets/img/collection-back.png);
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 160px;

    @media (max-width: 768px) {
        margin-bottom: 100px;
    }

    @media (max-width: 420px) {
        margin-bottom: 100px;
        margin-top: 110px;
    }

    &::after {
        position: absolute;
        content: '';
        left: 0px;
        bottom: -240px;
        width: 100%;
        height: 285px;
        background: url(../../../../assets/img/lining-сollection.svg);
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -11;
    }

    &::before {
        @media (max-width: 420px) {
            position: absolute;
            content: "";
            left: 0px;
            bottom: -75px;
            width: 100%;
            height: 500px;
            background: url(../../../../assets/img/lining-сollection-mobile.svg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: bottom;
            z-index: 1;
        }
    }

    .collection__box {
        width: 1400px;
        margin: 0 auto;

        @media (max-width: 1440px) {
            width: 1200px;
        }

        @media (max-width: 768px) {
            width: 100%;
        }

        @media (max-width: 420px) {
            position: relative;
            z-index: 11;
        }

        .collection__block {
            .collection__title {
                @media (max-width: 768px) {
                    width: 100%;
                    text-align: center;
                }
            }

            .collection__description_box {
                height: 56px;

                .collection__description {
                    width: 568px;
                    margin: 16px 0px;
                    opacity: 0;

                    @media (max-width: 768px) {
                        width: 100%;
                        text-align: center;
                        width: 475px;
                        margin: 16px auto;
                    }

                    @media (max-width: 420px) {
                        width: 301px;
                    }
                }
            }

            .collection__button_box {
                height: 68px;
                margin-top: 20px;

                @media (max-width: 768px) {
                    display: none;
                }

                .collection__button {
                    opacity: 0;
                }
            }
        }

        .collection__products_box {
            @media (max-width: 1440px) {
                height: 595px;
            }

            @media (max-width: 768px) {
                height: auto;
            }

            .collection__products {
                position: relative;
                margin-top: 45px;

                @media (max-width: 1440px) {
                    margin-top: 40px;
                }

                @media (max-width: 768px) {
                    width: 700px;
                    margin: 0 auto;
                    margin-top: 50px;
                }

                @media (max-width: 710px) {
                    width: auto;
                    margin: 0 18px;
                    margin-top: 80px;
                }
            }
        }

        .products {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            width: 1400px;
            margin-left: -15px;
            opacity: 0;

            @media (max-width: 1440px) {
                width: 1170px;
                margin-left: -5px;
            }

            @media (max-width: 990px) {
                width: 100%;
            }

            @media (max-width: 420px) {
                width: 95%;
                margin-left: 1px;
            }
        }
    }

    .collection-loyer {
        position: absolute;
        left: 0;
        bottom: -175px;
        width: 100%;
        height: 500px;
        background: url(../../../../assets/img/collection-loyer.svg);
        background-size: cover;
        background-position: bottom;
        background-repeat: no-repeat;
        z-index: -1;

        @media (max-width: 768px) {
            bottom: -95px;
        }

        @media (max-width: 420px) {
            bottom: -20px;
        }
    }

    .collection__button_mob {
        display: flex;
        justify-content: center;

        @media (min-width: 769px) {
            display: none;
        }

        @media (max-width: 420px) {
            margin-top: 20px;
        }
    }
}